import { Tag } from "@blueprintjs/core";
import React from "react";
import { HiTag } from "react-icons/hi";
import { useMutation, useQuery } from "react-query";
import {
  useParams
} from "react-router-dom";

import Header from '../../components/header';
import { GenericErrorBoundary } from "../../module/chaos/GenericErrorBoundary";
import { DocumentProvider, useDoc } from "../../module/y/hook";
import { fetcher, poster } from '../../utils';

function TagIndexMain({teamId}) {
  const { data: allTags, refetch: refetchAllTags } = useQuery(["tags-list"], async () => {
    return await fetcher(`/teams/${teamId}/tags/list?limit=999`)
  })

  const deleteTag = useMutation(async (args) => {
    return await poster(`/teams/${teamId}/tags/${args.tag}`, {}, 'DELETE')
  })
  const yTeamDoc = useDoc()
  const teamName = yTeamDoc?.getMap('META')?.get('TEAM_NAME') ?? ''

  return (
    <div className="min-h-screen" style={{
      backgroundColor: '#333'
    }}>
      <Header teamId={teamId} breads={[{title: teamName, link: `/teams/${teamId}`},{title: 'Tags'}]} />
      <div className="m-4">
        <div className="text-white text-4xl mb-4">Tags</div>
        <div className="flex flex-row gap-4">
          {(allTags || []).map((tag) => {
            return (
              <Tag
                large
                className="cursor-pointer"
                key={tag.key}
                onRemove={async (event) => {
                  event.stopPropagation();
                  await deleteTag.mutateAsync({tag: tag.key})
                  await refetchAllTags()
                }}
                onClick={()=> {
                  window.open(`/teams/${teamId}/tags/${tag.key}`, '_blank')
                }}
                icon={<HiTag />}
              >{tag.key}</Tag>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default function TagIndex() {
  const { teamId } = useParams()
  const docName = `TEAM_DATA-TEAM:${teamId}`
  const docMeta = {
    type: 'TEAM_DATA',
    teamId
  }
  return (
    <GenericErrorBoundary>
      <DocumentProvider docName={docName} docMeta={docMeta}>
        <TagIndexMain teamId={teamId} />
      </DocumentProvider>
    </GenericErrorBoundary>
  );
}