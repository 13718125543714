import { useCallback, useMemo } from 'react'
import { atom, useRecoilState } from 'recoil'
const getPersistentByKey = key => (defaultValue = []) => JSON.parse(localStorage.getItem(key) || JSON.stringify(defaultValue))
const setPersistentByKey = key => (data) => localStorage.setItem(key, JSON.stringify(data))


const sidebarSizeAtomKey = 'sidebar-split-size'
const getSbsPersistent = getPersistentByKey(sidebarSizeAtomKey)
const setSbsPersistent = setPersistentByKey(sidebarSizeAtomKey)
const SidebarSizeStore = atom({ key: sidebarSizeAtomKey, default: getSbsPersistent([]) })
export function useSidebarSize(id) {
  const [data, _setData] = useRecoilState(SidebarSizeStore)

  const setSize = useCallback(
    (size) => {
      const prevData = getSbsPersistent([])
      const nextData = [...prevData.filter(([_id]) => _id !== id), [id, size]]
      setSbsPersistent(nextData)
      _setData(nextData)
    },
    [_setData, id]
  )
  const size = useMemo(() => data.length ? (data.find(([_id]) => _id === id) ?? data[data.length - 1])[1] : [20, 80], [data, id])

  return { data, setSize, size }
}


const noteUIAtomKey = 'note-ui-setting'
const getNoteUIPersistent = getPersistentByKey(noteUIAtomKey)
const setNoteUIPersistent = setPersistentByKey(noteUIAtomKey)
const defaultNoteUISetting = { isSyncStatusShown: false, isNoteTitleWrapped: false, publishStyle: false }
const NoteUIStore = atom({ key: noteUIAtomKey, default: getNoteUIPersistent(defaultNoteUISetting) })
export function useNoteUISetting() {
  const [uiSetting, _setUISetting] = useRecoilState(NoteUIStore)
  const setUISetting = useCallback(
    (setting) => {
      setNoteUIPersistent(setting)
      _setUISetting(setting)
    },
    [_setUISetting]
  )
  return {
    uiSetting,
    setUISetting,
    publishStyle: useMemo(() => uiSetting.publishStyle, [uiSetting]),
    togglePublishStyle: useCallback(
      () => setUISetting({ ...uiSetting, publishStyle: !uiSetting.publishStyle }),
      [uiSetting, setUISetting]
    ),
    isSyncStatusShown: useMemo(() => uiSetting.isSyncStatusShown, [uiSetting]),
    toggleIsSyncStatusShown: useCallback(
      () => setUISetting({ ...uiSetting, isSyncStatusShown: !uiSetting.isSyncStatusShown }),
      [uiSetting, setUISetting]
    ),
    isNoteTitleWrapped: useMemo(() => uiSetting.isNoteTitleWrapped, [uiSetting]),
    toggleIsNoteTitleWrapped: useCallback(
      () => setUISetting({ ...uiSetting, isNoteTitleWrapped: !uiSetting.isNoteTitleWrapped }),
      [uiSetting, setUISetting]
    ),
  }
}

