import { useEffect, useState } from 'react'
import { BiWalletAlt } from 'react-icons/bi'
import { FaEthereum } from 'react-icons/fa'
import { SiweMessage } from 'siwe'
import { useAccount, useConnect, useNetwork, useSignMessage } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { fetcher, poster, restApiUrl } from '../utils'
import { Button } from '@blueprintjs/core'
import toast from 'react-hot-toast'
const SignInWithEthereum = ({
  onSuccess,
  onError,
  connectLabel = 'Continue with Wallet',
  signInLabel = (address) => `Sign in as ${address.slice(0, 6)}..${address.slice(-4)}`,
}) => {
  const { isConnected } = useAccount()
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })

  const [state, setState] = useState({})
  const { address } = useAccount()
  const { chain: activeChain } = useNetwork()
  const { signMessageAsync } = useSignMessage()

  const fetchNonce = async () => {
    try {
      const { nonce } = await fetcher('/auth/ethereum/nonce?wallet=' + address)
      setState((x) => ({ ...x, nonce }))
    } catch (error) {
      setState((x) => ({ ...x, error }))
    }
  }
  useEffect(() => {
    if (!address) return
    fetchNonce()
  }, [address])

  const signIn = async () => {
    try {
      const chainId = activeChain?.id
      if (!address || !chainId) return
      setState((x) => ({ ...x, loading: true }))
      const message = new SiweMessage({
        domain: new URL(restApiUrl).host,
        address,
        statement: 'Sign in with Ethereum to HackNote.',
        uri: new URL(restApiUrl).origin,
        version: '1',
        chainId,
        nonce: state.nonce,
      })
      const signature = await signMessageAsync({ message: message.prepareMessage() })
      const { token, refreshToken } = await poster('/auth/ethereum', { message, signature })
      setState((x) => ({ ...x, loading: false }))
      onSuccess({ address, token, refreshToken })
    } catch (error) {
      setState((x) => ({ ...x, loading: false, nonce: undefined }))
      onError(error)
      fetchNonce()
    }
  }

  return isConnected && address ? (
    <Button icon={<BiWalletAlt />} small disabled={!state.nonce || state.loading} onClick={signIn}>
      {signInLabel(address)}
    </Button>
  ) : (
    <Button icon={<FaEthereum />} small onClick={() => connect()}>
      {connectLabel}
    </Button>
  )
}

export default SignInWithEthereum
