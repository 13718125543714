import { Button } from '@blueprintjs/core'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from 'react-query'
import { poster } from '../../../utils'

export default function TeamSettingsSearch({ teamId }) {
  const updateEmbedding = useMutation(async () => {
    const updated = await poster(`/embedding/${teamId}`, {}, 'POST')
    return updated
  })
  useEffect(() => {
    if (!updateEmbedding.isSuccess) return
    if (updateEmbedding.data.updatedCount === 0) return toast.success(`Already latest`)
    return toast.success(`${updateEmbedding.data.updatedCount} team doc embedding updated`)
  }, [updateEmbedding.isSuccess, updateEmbedding.data])

  useEffect(() => {
    if (!updateEmbedding.isError) return
    return toast.error('Failed to update')
  }, [updateEmbedding.isError])

  return (
    <div className='my-6'>
      <Button loading={updateEmbedding.isLoading} intent='primary' fill onClick={() => updateEmbedding.mutateAsync({})}>
        Update team doc embedding
      </Button>
    </div>
  )
}
