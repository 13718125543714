/* eslint-disable react-hooks/exhaustive-deps */
// react hook that return the current url queries as state, and also provider update state hook for sync it with the url and persist it in localStorage

import { useState, useEffect, useMemo, useCallback } from 'react'
const PERSIST_LOCATION_SEARCH = 'PERSIST_LOCATION_SEARCH'


/**
 * URL:
 *  - update when searchParams change
 * Queries
 *  - just wrap the searchParams with parse and scope handle
 * SearchParams
 *  - from url or from localStorage if url search is empty 
 * LocalStorage Search:
 *  - update when searchParams change
 * 
 *  */

export default function useURLQueries(scope) {
  const [searchParams, setSearchParams] = useState()
  useEffect(() => {
    const _searchParams = new URLSearchParams(window.location.search || (localStorage.getItem(PERSIST_LOCATION_SEARCH) ?? undefined))
    setSearchParams(_searchParams)
  }, [])
  useEffect(() => {
    if (searchParams == null) return
    const search = searchParams.toString()
    window.history.replaceState(null, '', search ? `?${search}` : window.location.pathname)
    localStorage.setItem(PERSIST_LOCATION_SEARCH, search)
  }, [searchParams])
  
  const queries = useMemo(() => {
    if (!searchParams) return {}
    const all = Object.fromEntries(searchParams)
    const queries = Object.keys(all).reduce((acc, key) => {
      if (key.startsWith(`${scope}-`)) {
        acc[key.replace(`${scope}-`, '')] = all[key]
      }
      return acc
    }, {})
    return queries
  }, [searchParams, scope])
  const setQueries = useCallback((queries) => {
    const searchParams = new URLSearchParams()
    Object.entries(queries).forEach(([key, value]) => {
      if (value == null || value === '') return
      searchParams.set(`${scope}-${key}`, value)
    })
    setSearchParams(searchParams)
  }, [scope])


  return [queries, setQueries]
}