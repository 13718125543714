import Dexie from 'dexie';
/**
 * 
History
  key id
  value {
    team
    project
    note 
    content
    privateContent
    time
  }
*/
export class NoteHistoryDB extends Dexie {
  histories
  constructor() {
    super('LocalNoteHistoryDB');
    this.version(1).stores({ histories: '++id, note' });
  }
  deleteNoteHistories(note) {
    return this.transaction('rw', this.histories, () => {
      this.histories.where({ note }).delete();
    });
  }
}

export const db = new NoteHistoryDB();