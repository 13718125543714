import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { register } from '../serviceWorkerRegistration'

const ServiceWorkerContext = createContext({
  isUpdateAvailable: false,
  updateAssets: () => {} 
})

export const ServiceWorkerProvider = ({ children }) => {
  const [waitingServiceWorker, setWaitingServiceWorker] = useState(null)
  const [isUpdateAvailable, setUpdateAvailable] = useState(false)


  useEffect(() => {
    register({
      onUpdate: (registration) => {
        console.log('sw onUpdate')
        setWaitingServiceWorker(registration.waiting)
        setUpdateAvailable(true)
      },
      onWaiting: (waiting) => {
        console.log('sw onWaiting')
        setWaitingServiceWorker(waiting)
        setUpdateAvailable(true)
      },
    })
  }, [])

  useEffect(() => {
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload()
        }
      })
    }
  }, [waitingServiceWorker])

  const value = useMemo(
    () => ({
      isUpdateAvailable,
      updateAssets: () => {
        if (waitingServiceWorker) {
          waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
        }
      },
    }),
    [isUpdateAvailable, waitingServiceWorker]
  )

  return <ServiceWorkerContext.Provider value={value}>{children}</ServiceWorkerContext.Provider>
}

export const useServiceWorker = () => {
  return useContext(ServiceWorkerContext)
}
