import {useCallback} from "react";
import {useSWRConfig} from 'swr'

export function useSWRCacheReset(key) {
    const { cache, mutate } = useSWRConfig();
    return useCallback(() => {
      // TODO use SWR's API to get cache key
      cache.delete('$swr$' + key);
      cache.delete('$err$' + key);
      cache.delete('$req$' + key);
      mutate(key);
    }, [cache, mutate, key]);
}
