const userColors = ['#be5600', '#7a25f4', '#00089b', '#0c624c', '#ee6352', '#701567', '#688903', '#3e57d6']
const getColor = () => userColors[Math.floor(Math.random() * userColors.length)]
export default class CursorWidget {
  _domNode = document.createElement('div')
  _scrollListener = null
  _editor = null
  _id = null
  _hideTimer = null
  _disposed = null
  _tooltipNode = document.createElement('div')
  _offset = null
  _position = null
  _tooltipDuration = 1000
  color = getColor()
  label = 'Guest'

  constructor(editor, id, color, label, tooltipEnabled = true) {
    this.color = color
    this.label = label
    this._editor = editor
    this._id = `cursor-id-${id}`
    this._domNode.className = 'monaco-remote-cursor'
    this._tooltipNode.className = 'monaco-remote-cursor-tooltip'
    this._tooltipNode.style.background = color
    this._tooltipNode.innerHTML = label
    this._domNode.style.background = color
    this._domNode.style.height = `14px`
    if (tooltipEnabled) {
      this._tooltipNode = document.createElement('div')
      this._tooltipNode.className = 'monaco-remote-cursor-tooltip'
      this._tooltipNode.style.background = color
      this._tooltipNode.innerHTML = label
      this._domNode.appendChild(this._tooltipNode)
      this._scrollListener = this._editor.onDidScrollChange(() => {
        this._updateTooltipPosition()
      })
    } else {
      this._tooltipNode = null
      this._scrollListener = null
    }

    this._hideTimer = null
    this.dispose()
    this._editor.addContentWidget(this)

    this._offset = -1

    this._disposed = false
  }

  renew(label, color) {
    if (this.label !== label) {
      this._tooltipNode.innerHTML = label
    }
    if (this.color !== color) {
      this._domNode.style.background = color
      this._tooltipNode.style.background = color
    }
  }

  hide() {
    this._domNode.style.display = 'none'
  }

  show() {
    this._domNode.style.display = 'inherit'
  }

  setOffset(offset) {
    const position = this._editor.getModel().getPositionAt(offset)
    this.setPosition(position)
  }

  setPosition(position) {
    this._updatePosition(position)

    if (this._tooltipNode !== null) {
      setTimeout(() => this._showTooltip(), 0)
    }
  }

  isDisposed() {
    return this._disposed
  }

  dispose() {
    if (this._disposed) {
      return
    }
    this._editor.removeContentWidget(this)
    if (this._scrollListener !== null) {
      this._scrollListener.dispose()
    }
    this._disposed = true
  }

  getId() {
    return this._id
  }

  getDomNode() {
    return this._domNode
  }

  getPosition() {
    return this._position
  }

  _updatePosition(position) {
    this._position = {
      position: { ...position },
      preference: [0],
    }

    this._offset = this._editor.getModel().getOffsetAt(position)
    this._editor.layoutContentWidget(this)
  }

  _showTooltip() {
    this._updateTooltipPosition()
    if (this._hideTimer !== null) {
      clearTimeout(this._hideTimer)
    } else {
      this._setTooltipVisible(true)
    }

    this._hideTimer = setTimeout(() => {
      this._setTooltipVisible(false)
      this._hideTimer = null
    }, this._tooltipDuration)
  }

  _updateTooltipPosition() {
    const distanceFromTop = this._domNode.offsetTop - this._editor.getScrollTop()
    if (distanceFromTop - this._tooltipNode.offsetHeight < 5) {
      this._tooltipNode.style.top = `${this._tooltipNode.offsetHeight + 2}px`
    } else {
      this._tooltipNode.style.top = `-${this._tooltipNode.offsetHeight}px`
    }

    this._tooltipNode.style.left = '0'
  }

  _setTooltipVisible(visible) {
    if (visible) {
      this._tooltipNode.style.opacity = '1.0'
    } else {
      this._tooltipNode.style.opacity = '0'
    }
  }
}
