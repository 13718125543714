import React, { useState, useEffect, useContext } from 'react'
import toast from 'react-hot-toast'
import { restApiUrl } from '../utils'

const PING_RESOURCE = restApiUrl + '/status'
const TIMEOUT_TIME_MS = Math.max(localStorage.ONLINE_STATUS_REQUEST_TIMEOUT ?? 5000, 5000)
const onlinePollingInterval = Math.max(localStorage.ONLINE_STATUS_POLLING_INTERVAL ?? 20000, 20000)

const timeout = (time, promise) => {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error('Request timed out.'))
    }, time)
    promise.then(resolve, reject)
  })
}

const checkOnlineStatus = async () => {
  const controller = new AbortController()
  const { signal } = controller

  // If the browser has no network connection return offline
  if (!navigator.onLine) return navigator.onLine

  //
  try {
    await timeout(
      TIMEOUT_TIME_MS,
      fetch(PING_RESOURCE, {
        method: 'GET',
        signal,
      })
    )
    return true
  } catch (error) {
    // Error Log
    console.error(error)

    // This can be because of request timed out
    // so we abort the request for any case
    controller.abort()
  }
  return false
}

const OnlineStatusContext = React.createContext(true)
localStorage.setItem('onlineStatus', true)
export const OnlineStatusProvider = ({ children }) => {
  const [onlineStatus, _setOnlineStatus] = useState(true)
  const setOnlineStatus = (isOnline) => {
    _setOnlineStatus(onlineStatus => {
      setTimeout(() => {
        if (isOnline === onlineStatus) return
        isOnline ? toast.success('You are now online') : toast.error('You are offline')
        localStorage.setItem('onlineStatus', isOnline)
      }, 0)
      return isOnline
    })
  }

  const checkStatus = async () => {
    const online = await checkOnlineStatus()
    setOnlineStatus(online)
  }

  useEffect(() => {
    window.addEventListener('offline', () => {
      setOnlineStatus(false)
    })

    // Add polling incase of slow connection
    const id = setInterval(() => {
      checkStatus()
    }, onlinePollingInterval)

    return () => {
      window.removeEventListener('offline', () => {
        setOnlineStatus(false)
      })

      clearInterval(id)
    }
  }, [])

  return <OnlineStatusContext.Provider value={onlineStatus}>{children}</OnlineStatusContext.Provider>
}

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext)
  return store
}

export default useOnlineStatus
