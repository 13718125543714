import { differenceInSeconds, formatDistance } from 'date-fns'
import { useCallback, useEffect, useRef, useState } from 'react'

export function getDifference(date) {
  return differenceInSeconds(new Date(), date)
}

export function formatRelative(date) {
  const diff = getDifference(date)

  if (diff < 30) {
    return 'now'
  } else {
    return `${formatDistance(date, new Date())} ago`
  }
}

export function getInterval(date) {
  const diff = getDifference(date)

  if (diff < 3600) {
    return 60000
  } else if (diff >= 3600 && diff <= 86400) {
    return 3600000
  } else {
    return 0
  }
}
export default function TimeAgo({ date }) {
  if (!date) return null
  return <TimeAgoCmp date={date} />
}
function TimeAgoCmp({ date }) {
  const [relative, setRelative] = useState(formatRelative(date))
  const timeout = useRef(null)

  const updateTime = useCallback(() => {
    clearTimeout(timeout.current)

    const interval = getInterval(date)
    setRelative(formatRelative(date))
    if (interval > 0) {
      timeout.current = setTimeout(() => {
        setRelative(formatRelative(date))
      }, interval)
    }
  }, [date])

  useEffect(() => {
    updateTime()

    const cur = timeout.current
    return () => clearTimeout(cur)
  })
  return <span>{relative}</span>
}
