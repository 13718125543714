import { FormGroup, InputGroup, Menu, MenuItem, Spinner, Tag } from '@blueprintjs/core'
import { ContextMenu2 } from '@blueprintjs/popover2'
import { Formik } from 'formik'
import React, { Fragment } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQuery } from 'react-query'
import { useDebounce, useMap as useHookMap } from 'react-use'
import { Avatar } from '../../../components/Collaborators'
import { useProfile } from '../../../hooks/useProfile'
import { useTeamPermission, useTeams } from '../../../hooks/useTeams'
import { fetcher, poster } from '../../../utils'
export const TeamMemberRole = {
  Member: 0,
  Manager: 1,
  Owner: 2,
}
export default function TeamSettingsMembersMain({ teamId }) {
  const {
    data: members,
    isLoading: isMembersLoading,
    error: membersError,
    refetch: refetchMembers,
  } = useQuery(
    ['TeamMember', teamId],
    async () => {
      return await fetcher(`/teams/${teamId}/members`)
    },
    {
      enabled: !!teamId,
      staleTime: 1000 * 60 * 10,
    }
  )
  const { profile } = useProfile()
  const {refetch} = useTeams()

  const addMember = useMutation(async (args) => {
    const requestPromise = poster(`/teams/${teamId}/members`, args, 'POST')
    toast.promise(requestPromise, {
      loading: `Add team member...`,
      success: `Team member added!`,
      error: `Error when updating team member`,
    })
    await requestPromise
    await refetchMembers()
    return
  })

  const updateMemberRole = useMutation(async ({ role, userId }) => {
    const isTransferOwner = role === TeamMemberRole.Owner
    const requestPromise = poster(
      isTransferOwner ? `/teams/${teamId}/owner/${userId}` : `/teams/${teamId}/members/${userId}/role/${role}`,
      {},
      'POST'
    )
    toast.promise(requestPromise, {
      loading: isTransferOwner ? `Transferring team ownership...` : `Update team member role...`,
      success: isTransferOwner ? `Team ownership transferred` : `Team member role updated!`,
      error: `Error when ${isTransferOwner ? 'Transferring team ownership' : "updating team member's role"}`,
    })
    await requestPromise
    await refetchMembers()
    await refetch()
    return
  })

  const removeMember = useMutation(async (args) => {
    const requestPromise = poster(`/teams/${teamId}/members`, args, 'DELETE')
    toast.promise(requestPromise, {
      loading: `Remove team member...`,
      success: `Team member removed!`,
      error: `Error when removing team member`,
    })
    await requestPromise
    await refetchMembers()
    return
  })

  const [input, setInput] = React.useState('')
  const [userExist, { set: setUserExist }] = useHookMap({})
  const checkUserExist = useMutation(async (args) => {
    if (!args.identity) return null
    if (userExist[args.identity]) return { exist: true }
    return fetcher(`/users/exist?identity=${args.identity}`)
  })
  useDebounce(
    () => {
      checkUserExist.mutateAsync({ identity: input }).then((data) => {
        if (!data) return
        setUserExist(input, data.exist)
      })
    },
    500,
    [input]
  )
  const operatorRole = useTeamPermission(teamId)

  return (
    <div>
      {isMembersLoading && (
        <div className='flex items-center justify-center' style={{ minHeight: 240 }}>
          <Spinner size={25} />
        </div>
      )}
      {!isMembersLoading && membersError && (
        <div className='flex items-center justify-center' style={{ minHeight: 240 }}>
          {membersError}
        </div>
      )}
      {!isMembersLoading && members && (
        <Formik initialValues={{}} onSubmit={async (values) => {}} enableReinitialize>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className='flex flex-col'>
                  <FormGroup>
                    {isMembersLoading && <Spinner />}
                    <div className='flex flex-col gap-2 mt-2'>
                      {members &&
                        members.map((member) => {
                          const tag = (
                            <Tag fill minimal large>
                              <div className='flex flex-row items-center gap-1'>
                                <Avatar
                                  className='mr-1'
                                  title={`${member.username} - ${
                                    member.role === TeamMemberRole.Manager
                                      ? 'MANAGER'
                                      : member.role === TeamMemberRole.Owner
                                      ? 'OWNER'
                                      : 'MEMBER'
                                  }`}
                                  avatar={member.avatar}
                                  size={24}
                                  color={member.color}
                                  username={member.username}
                                />
                                <div className='mr-1 text-gray-100'>
                                  {member.username} {member.id === profile.id ? '(You)' : ''}
                                </div>
                                {member.role === TeamMemberRole.Manager ? (
                                  <span className='px-1.5 py-0.5 origin-left text-xs font-bold text-gray-300 scale-75 bg-blue-900 transform-gpu -mr-3'>
                                    MANAGER
                                  </span>
                                ) : null}
                                {member.role === TeamMemberRole.Owner ? (
                                  <span className='px-1.5 py-0.5 origin-left text-xs font-bold text-gray-300 scale-75 bg-green-900 transform-gpu -mr-3'>
                                    OWNER
                                  </span>
                                ) : null}

                                <div className='text-xs text-gray-400'>{member.id}</div>
                              </div>
                            </Tag>
                          )
                          return (
                            <Fragment key={member.id}>
                              {operatorRole === TeamMemberRole.Owner && member.role !== TeamMemberRole.Owner ? (
                                <ContextMenu2
                                  content={
                                    <Menu>
                                      {member.role === TeamMemberRole.Manager ? (
                                        <MenuItem
                                          text='Downgrade to Member'
                                          onClick={() => updateMemberRole.mutate({ role: TeamMemberRole.Member, userId: member.id })}
                                        />
                                      ) : member.role === TeamMemberRole.Member ? (
                                        <MenuItem
                                          text='Upgrade to Manager'
                                          onClick={() => updateMemberRole.mutate({ role: TeamMemberRole.Manager, userId: member.id })}
                                        />
                                      ) : null}
                                      {operatorRole > member.role ? (
                                        <MenuItem
                                          text='Remove Member'
                                          intent='danger'
                                          onClick={() => {
                                            if (!window.confirm(`Remove ${member.username} from team?`)) return
                                            removeMember.mutate({ identity: member.id })
                                          }}
                                        />
                                      ) : null}
                                      <MenuItem
                                        text='Transfer Team Ownership'
                                        intent='danger'
                                        onClick={() => {
                                          if (!window.confirm(`Transfer team ownership to ${member.username}?`)) return
                                          updateMemberRole.mutate({ role: TeamMemberRole.Owner, userId: member.id })
                                        }}
                                      />
                                    </Menu>
                                  }
                                >
                                  {tag}
                                </ContextMenu2>
                              ) : (
                                tag
                              )}
                            </Fragment>
                          )
                        })}
                    </div>
                    <div className='mt-4'></div>
                    <InputGroup
                      disabled={operatorRole < 1}
                      hidden={operatorRole < 1}
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      placeholder={`Input Github ID or wallet address to add new member...`}
                      intent={input && !checkUserExist.isLoading ? (userExist[input] ? 'success' : 'danger') : undefined}
                      rightElement={
                        checkUserExist.isLoading ? (
                          <Tag minimal className='bg-transparent'>
                            <Spinner size={15} />
                          </Tag>
                        ) : undefined
                      }
                      onKeyUp={(e) => {
                        if (e.code === 'Enter') {
                          if (input && userExist[input]) {
                            addMember.mutate({ identity: input })
                            setInput('')
                          }
                        }
                      }}
                    />
                  </FormGroup>
                </div>
              </form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}
