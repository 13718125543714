import { debounce } from 'lodash'
import { useCallback, useEffect } from 'react'
import toast from 'react-hot-toast'
import Split from 'react-split'
import Collaborators from '../components/Collaborators'
import { useProfile } from '../hooks/useProfile'
import { GenericErrorBoundary } from '../module/chaos/GenericErrorBoundary'
import { db as localNoteHistoryDB } from '../module/localNoteHistory/db'
import { useProvider } from '../module/y/hook'
import { poster } from '../utils'
import YEditor from './yeditor'
import YEditorReadonly from './yeditorReadonly'

export default function NoteEditor({ note, onDbNoteUpdate, showCollaborator }) {
  const key = note.get('key')
  const provider = useProvider()
  useEffect(() => {
    if (!key) return
    setTimeout(() => {
      provider.awareness.setLocalStateField('editingNoteKey', key)
    }, 500)
  }, [key])
  const { profile } = useProfile()
  const team = note.get('team')
  const project = note.get('project')
  const yTextContent = note.get('content')
  const yTextPrivateNote = note.get('privateNote')
  const saveLocally = debounce((field, value)=> {
    localNoteHistoryDB.histories.add({
      project,
      team,
      note: key,
      content: note.get('content').toJSON(),
      privateNote: note.get('privateNote').toJSON(),
      [field]: value,
      time: Date.now(),
    })
  }, 5000)
  const saveContentLocally = useCallback(v =>  saveLocally('content', v), [key])
  const savePrivateNoteLocally = useCallback(v =>  saveLocally('privateNote', v), [key])
  const saveNoteOnServer = useCallback(
    debounce((field, value) => {
      const updatedAt = Date.now()
      poster(`/teams/${team}/projects/${project}/notes/${key}`, { [field]: value, updatedAt }, 'PUT', true)
        .then((r) => {
          if (!r) throw new Error('no network')
          return r
        })
        .then((updatedNote) => {
          if (!updatedNote) return
          note.set('updatedAt', updatedAt)
          if (onDbNoteUpdate) {
            onDbNoteUpdate(updatedNote)
          }
        })
        .catch((err) => {
          toast.error('Fail to update editing note!')
        })
    }, 5000),
    [key]
  )
  const saveContent = useCallback(
    (content) => {
      saveNoteOnServer('content', content)
      const contributors = note.get('contributors') ?? []
      if (profile && profile.id && !contributors.includes(profile.id)) {
        note.set('contributors', [...contributors, profile.id])
      }
    },
    [key]
  )
  const savePrivateNote = useCallback((privateNote) => saveNoteOnServer('privateNote', privateNote), [key])

  if (!note) return null
  return (
    <div className='relative h-full'>
      {showCollaborator ? (
        <div className='absolute z-10 right-4'>
          <Collaborators avatarProps={{className:'p-2'}}  myId={profile.id} noteKey={key} />
        </div>
      ) : null}
      <Split className='flex flex-col h-full split' sizes={[70, 30]} direction='vertical'>
        <div className='w-full overflow-hidden'>
          <GenericErrorBoundary>
            {note.get('locked') ? (
              <YEditorReadonly path={`${key}/content`} content={yTextContent.toString()} />
            ) : (
              <YEditor path={`${key}/content`} yText={yTextContent} onChange={saveContent} onEditorChange={saveContentLocally} />
            )}
          </GenericErrorBoundary>
        </div>
        <div className='w-full overflow-hidden'>
          <GenericErrorBoundary>
            <YEditor path={`${key}/privateNote`} yText={yTextPrivateNote} onChange={savePrivateNote} onEditorChange={savePrivateNoteLocally} />
          </GenericErrorBoundary>
        </div>
      </Split>
    </div>
  )
}
