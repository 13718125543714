import { KBarContext } from "kbar";
import { useStore } from "kbar/lib/useStore";
import { differenceBy, uniqBy } from "lodash";
import * as React from "react";
import { InternalEvents } from "./KBarInternalEvents";

export const KBarXContext = React.createContext({});

export const KBarXProvider = (props) => {
  const contextValue = useStore(props);

  const [_disableCloseOnClickAway, _setDisableCloseOnClickAway] = React.useState(false)

  const [_actions, _setActions] = React.useState([]);
  const registerActions = React.useCallback((actions) => {
    _setActions((oldActions) => {
      return uniqBy([
        ...oldActions,
        ...actions,
      ], (i) => i.key)
    })
  }, [])
  const unregisterActions = React.useCallback((actions) => {
    _setActions((oldActions) => {
      return differenceBy(oldActions, actions, (i) => i.key)
    })
  }, [])

  const [_searches, _setSearches] = React.useState([]);
  const registerSearches = React.useCallback((searches) => {
    _setSearches((oldSearches) => {
      return uniqBy([
        ...oldSearches,
        ...searches,
      ], (i) => i.key)
    })
  }, [])
  const unregisterSearches = React.useCallback((searches) => {
    _setSearches((oldSearches) => {
      return differenceBy(oldSearches, searches, (i) => i.key)
    })
  }, [])

  const _contextValue = React.useMemo(() => {
    return {
      actions: [
        ...(props.actions || []),
        ..._actions,
      ],
      searches: [
        ...(props.searches || []),
        ..._searches,
      ],
      disableCloseOnClickAway: _disableCloseOnClickAway || props.disableCloseOnClickAway,
      setDisableCloseOnClickAway: _setDisableCloseOnClickAway,
      registerActions, unregisterActions,
      registerSearches, unregisterSearches,
    };
  }, [
    props.actions, props.searches,
    _actions, _searches,
    props.disableCloseOnClickAway,
    _disableCloseOnClickAway, _setDisableCloseOnClickAway,
    registerActions, unregisterActions,
    registerSearches, unregisterSearches,
  ]);

  return (
    <KBarXContext.Provider value={_contextValue}>
      <KBarContext.Provider value={contextValue}>
        <InternalEvents />
        {props.children}
      </KBarContext.Provider>
    </KBarXContext.Provider>
  );
};

export function useRegisterActions(actions, dependencies = []) {
  const context = React.useContext(KBarXContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cachedActions = React.useMemo(() => actions, dependencies);

  React.useEffect(() => {
    if (!context) return;
    context.registerActions(cachedActions);
    return () => {
      context.unregisterActions(cachedActions);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedActions])
}

export function useRegisterSearches(searches, dependencies = []) {
  const context = React.useContext(KBarXContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cachedSearches = React.useMemo(() => searches, dependencies);

  React.useEffect(() => {
    if (!context) return;
    context.registerSearches(cachedSearches);
    return () => {
      context.unregisterSearches(cachedSearches);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedSearches])
}