import { debounce } from 'lodash'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { WebsocketProvider } from 'y-websocket'
import * as Y from 'yjs'
import { CURRENT_SERVER_YDB_VERSION, waitForTokenRefresh, websocketUrl } from '../../utils'
import { generateKeyBetween } from '../fractional-indexing'
import { IndexeddbPersistence } from './y-indexeddb'

export const connectedWsProviders = new Map()
const connectedDbProviders = new Map()
export const docMap = new Map()
const formatDocName = (docName) => `${CURRENT_SERVER_YDB_VERSION}-${(process.env.NODE_ENV ?? 'UNKNOWN').toUpperCase()}-${docName}`
const heartBeat = new Map()
export const getDoc = (docName, docMeta, onDbProviderSynced, onServerSynced, onWsStatusChange) => {
  let doc = docMap.get(docName) ?? new Y.Doc()
  let wsProvider = connectedWsProviders.get(docName)
  if (!wsProvider) {
    console.info('connecting ws provider', docName)
    wsProvider = new WebsocketProvider(websocketUrl, formatDocName(docName), doc, {
      params: () => ({
        token: `Bearer ${localStorage.token}`,
        version: CURRENT_SERVER_YDB_VERSION,
        ...docMeta,
      }),
    })
    connectedWsProviders.set(docName, wsProvider)
    docMap.set(docName, doc)
    
    wsProvider.on('status', ({ status }) => {
      onWsStatusChange && onWsStatusChange(status)
      console.info('ws status changed', status)
      if (status === 'disconnected') {
        console.info('ws provider was disconnected')
        if (heartBeat.get(docName) != null) clearInterval(heartBeat.get(docName))
      }
    })
    wsProvider.on('connection-error', async (e, p) => {
      console.info('connection error')
    })
    wsProvider.on('connection-close', async (e, p) => {
      console.info('connection close')
      const WEBSOCKET_AUTH_EXPIRED = 4001
      if (e.code === WEBSOCKET_AUTH_EXPIRED) {
        await waitForTokenRefresh()
        p.refreshUrl()
      }
    })
  }
  if (wsProvider.synced) {
    if (onServerSynced) onServerSynced(doc)
  } else {
    wsProvider.on('synced', () => {
      console.info(`ws synced for doc: ${docName}`)
      try {
        const profile = JSON.parse(localStorage.profile)
        wsProvider.awareness.setLocalState(profile)
        const keepLiveId = setInterval(() => {
          wsProvider.awareness.setLocalStateField('lastUpdatedAt', Date.now())
        }, 5000)
        heartBeat.set(docName, keepLiveId)
      } catch (e) {}
      if (onServerSynced) onServerSynced(doc)
    })
  }
  let dbProvider = connectedDbProviders.get(docName)
  if (!dbProvider) {
    dbProvider = new IndexeddbPersistence(
      formatDocName(docName),
      doc,
      docMeta.type === 'TEAM_DATA'
        ? {
            trimSize: 30,
          }
        : undefined
    )
    connectedDbProviders.set(docName, dbProvider)
  }
  if (dbProvider.synced) {
    if (onDbProviderSynced) onDbProviderSynced(doc)
  } else {
    dbProvider.on('synced', () => {
      console.info(`local db synced for doc: ${docName}`)
      if (onDbProviderSynced) onDbProviderSynced(doc)
    })
  }

  return {
    doc,
    wsProvider,
    dbProvider,
  }
}
export const useY = (docName, docMeta) => {
  const [localSynced, setLocalSynced] = useState(false)
  const [wsSynced, setWsSynced] = useState(false)
  const [wsStatus, setWsStatus] = useState('disconnected')
  const { doc, wsProvider: provider } = useMemo(
    () =>
      getDoc(
        docName,
        docMeta,
        () => setLocalSynced(true),
        () => setWsSynced(true),
        setWsStatus
      ),
    [docName]
  )
  return {
    doc,
    wsStatus,
    provider,
    localSynced,
    wsSynced,
  }
}

export const useDoc = () => {
  const { doc } = useContext(DocumentContext)
  if (doc == null) throw new Error('not in document context')
  return doc
}

export const useProvider = () => {
  const { provider } = useContext(DocumentContext)
  if (provider == null) throw new Error('not in document context')
  return provider
}

export const useWsSynced = () => {
  const { wsSynced } = useContext(DocumentContext)
  return wsSynced
}

export const DocumentContext = createContext({
  doc: null,
  provider: null,
  localSynced: false,
  wsSynced: false,
})

export const DocumentProvider = ({ children, docName, docMeta }) => {
  const { provider, doc, localSynced, wsSynced } = useY(docName, docMeta)
  return (
    <DocumentContext.Provider
      value={{
        doc,
        provider,
        localSynced,
        wsSynced,
      }}
    >
      {children}
    </DocumentContext.Provider>
  )
}

const useSharedType = (name, constructor) => {
  const doc = useDoc()
  return doc.get(name, constructor)
}

export const useMap = (nameOrMap) => {
  const doc = useDoc()

  const map = typeof nameOrMap === 'string' ? doc.getMap(nameOrMap) : nameOrMap
  const forceUpdate = useForceUpdate()
  const effect = () => forceUpdate()
  useEffect(() => {
    if (!map) return
    map.observe(effect)
    return () => {
      map.unobserve(effect)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  return {
    size: map?.size ?? 0,
    state: map ? map.toJSON() : null,
    get: useCallback((key) => (map ? map.get(key) : null), [map]),
    set: useCallback((key, value) => (map ? map.set(key, value) : null), [map]),
  }
}

export const useForceUpdate = (debounceTime = 0) => {
  const [, _dispatch] = useState(Object.create(null))
  const dispatch = debounceTime > 0 ? debounce(_dispatch, debounceTime) : _dispatch
  return useCallback(() => dispatch(Object.create(null)), [dispatch])
}

export const useArray = (name, observeDeepChange = true, debounceTime = 0) => {
  const array = useSharedType(name, Y.Array)
  const forceUpdate = useForceUpdate(debounceTime)
  useEffect(() => {
    const effect = () => forceUpdate()
    if (observeDeepChange) {
      array.observeDeep(effect)
    } else {
      array.observe(effect)
    }
    return () => {
      if (observeDeepChange) {
        array.unobserveDeep(effect)
      } else {
        array.unobserve(effect)
      }
    }
  }, [array, forceUpdate])

  return {
    forceUpdate,
    doc: array.doc,
    state: array.toJSON(),
    toArray: array.toArray.bind(array),
    map: array.map.bind(array),
    forEach: array.forEach.bind(array),
    get: useCallback((index) => array.get(index), [array]),
    insert: useCallback((index, content) => array.insert(index, content), [array]),
    delete: useCallback((index, length) => array.delete(index, length), [array]),
    push: useCallback((content) => array.push(content), [array]),
    unshift: useCallback((content) => array.unshift(content), [array]),
    slice: useCallback((start, end) => array.slice(start, end), [array]),
    length: array.length,
    empty: useCallback(() => array.delete(0, array.length), [array]),
    remove: useCallback(
      (content) => {
        const i = array.toJSON().indexOf(content)
        array.delete(i, 1)
      },
      [array]
    ),
    refill: useCallback(
      (content) => {
        array.delete(0, array.length)
        array.push(content)
      },
      [array]
    ),
  }
}

export const getSortedArray = (yArr, filter) => {
  return yArr
    .toArray()
    .map((n, i) => {
      // mutation
      const _toJSON = n.toJSON.bind(n)
      n.toJSON = () => ({
        ..._toJSON.bind(n)(),
        index: i,
      })
      return n
    })
    .filter((i) => (filter ? filter(i) : true))
    .sort((a, b) => (a.get('pos') < b.get('pos') ? -1 : a.get('pos') > b.get('pos') ? 1 : 0))
}
export const getSortedArray2 = (yArr, filter) => {
  return yArr
    .filter((i) => (filter ? filter(i) : true))
    .map((n, i) => {
      // mutation
      n.index = i
      return n
    })
    .sort((a, b) => (a.get('pos') < b.get('pos') ? -1 : a.get('pos') > b.get('pos') ? 1 : 0))
}
export const insertMap = (arr, map, index) => {
  moveMap(arr, map, index)
  arr.push([map])
}

export const moveMap = (arr, map, index, filter) => {
  const sortedArray = getSortedArray(arr, filter)
  const currentIndex = sortedArray.findIndex((i) => i === map)
  let left, right
  if (currentIndex >= 0 && currentIndex < index) {
    left = sortedArray[index] || null
    right = sortedArray[index + 1] || null
  } else {
    left = sortedArray[index - 1] || null
    right = sortedArray[index] || null
  }
  let leftIndex = left?.get('pos') ?? null
  let rightIndex = right?.get('pos') ?? null
  if (typeof leftIndex !== 'string') leftIndex = null
  if (typeof rightIndex !== 'string') rightIndex = null
  const pos = generateKeyBetween(leftIndex, rightIndex)
  map.set('pos', pos)
  return pos
}
