import MonacoEditor from '@monaco-editor/react'
import { loader } from "@monaco-editor/react";
import { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { convertNoteContent } from '../module/exportToGithub/convertNoteContent'
import { registerMonacoCompletionAlert } from '../module/monaco-completion/MonacoCompletionAlert'
import { registerMonacoCompletionDetails } from '../module/monaco-completion/MonacoCompletionDetails'
import { registerMonacoCompletionEmbedBlock } from '../module/monaco-completion/MonacoCompletionEmbedBlock'
import { getDoc, useProvider } from '../module/y/hook'
import { MonacoBinding } from '../module/y/y-monaco'
import { registerMonacoTeamCompletion } from '../module/monaco-completion/MonacoTeamCompletion';
import TimeAgo from './TimeAgo';
import { extraLangMeta } from '../module/chaos/markdown';
import { registerMonacoScriptAction } from '../module/monaco-completion/MonacoScriptAction';
import { registerMonacoAiAction } from '../module/monaco-completion/MonacoAiAction';

loader.config({ paths: { vs: "https://cdn.jsdelivr.net/npm/monaco-editor@0.34.1/min/vs" } });

loader.init().then(monaco => {
  registerMonacoCompletionDetails(monaco)
  registerMonacoCompletionAlert(monaco)
  registerMonacoCompletionEmbedBlock(monaco)
});

export const DID_SELECT_NON_EMPTY_TEXT_CONTEXT_KEY = "did-select-non-empty-text"

const monacoScriptActionsRegistered = {};
const monacoPromptTemplatesRegistered = {};
const monacoCompletionAutoRegistered = {};
const monacoCompletionAutoProviders = {};
const now = Date.now()

const YEditor = ({ path, yText, onChange, onEditorChange = () => { } }) => {
  const { teamId } = useParams()
  const [activeTime, setActiveTime] = useState(now)

  useEffect(() => {
    if (!teamId) return;
    if (monacoCompletionAutoRegistered[teamId]) return;
    getDoc(`TEAM_DATA-TEAM:${teamId}`, { type: 'TEAM_DATA', teamId }, undefined, (doc) => {
      loader.init().then(monaco => {
        if (monacoCompletionAutoRegistered[teamId]) return;
        let yEditorCompletionsSettings = doc.getMap('EDITOR_COMPLETIONS_SETTINGS')
        const completions = yEditorCompletionsSettings?.get('completions')
        if (completions) {
          monacoCompletionAutoRegistered[teamId] = true;
          monacoCompletionAutoProviders[teamId] = registerMonacoTeamCompletion(monaco, completions.toJSON() || [])
        }
      });
    })

    return () => {
      monacoCompletionAutoProviders[teamId]?.dispose()
      monacoCompletionAutoRegistered[teamId] = false;
    }
  }, [teamId])

  const provider = useProvider()
  const [editor, setEditor] = useState(null)
  const [monaco, setMonaco] = useState(null)

  const promptContext = useRef(null)

  useEffect(() => {
    if (!editor || !yText || !monaco) return
    const binding = new MonacoBinding(monaco, yText, editor.getModel(), new Set([editor]), provider.awareness)
    return () => binding.destroy()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, yText])

  useEffect(() => {
    if (!yText) return
    yText.observe((_, t) => {
      if (!t.local) return
      setActiveTime(Date.now())
      onChange(yText.toJSON())
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yText])

  function handleEditorDidMount(editor, monaco) {
    setEditor(editor)
    setMonaco(monaco)
    setTimeout(() => {
      editor.layout()
    }, 200)

    const didSelectNonEmptyText = editor.createContextKey(DID_SELECT_NON_EMPTY_TEXT_CONTEXT_KEY, false)
    editor.onDidChangeCursorSelection((e) => {
      const flag = !(e.selection.startColumn === e.selection.endColumn &&
        e.selection.startLineNumber === e.selection.endLineNumber);
      didSelectNonEmptyText.set(flag)
    });

    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyK, function (_) {
      window.dispatchEvent(
        new KeyboardEvent('keydown', {
          isTrusted: true,
          altKey: false,
          bubbles: true,
          cancelBubble: false,
          cancelable: true,
          charCode: 0,
          code: 'KeyK',
          composed: true,
          ctrlKey: false,
          currentTarget: null,
          defaultPrevented: true,
          detail: 0,
          eventPhase: 0,
          isComposing: false,
          key: 'k',
          keyCode: 75,
          location: 0,
          metaKey: true,
          repeat: false,
          returnValue: false,
          shiftKey: false,
          type: 'keydown',
        })
      )
    })

    editor.addAction({
      id: 'copy-for-github',
      label: 'Copy for GitHub',
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyC | monaco.KeyCode.KeyG],
      contextMenuGroupId: '9_cutcopypaste',
      contextMenuOrder: 3,
      run: async (_editor) => {
        if (!_editor?.getValue()) return
        await navigator.clipboard.writeText(convertNoteContent(_editor.getValue()))
      },
    })

    editor.addAction({
      id: 'codeblock-highlight',
      label: 'Code: Highlight',
      keybindings: [],
      contextMenuGroupId: '2c_codeblock',
      contextMenuOrder: 2,
      run: async (_editor) => {
        const content = _editor.getValue()
        if (!content) return
        let startLineNumber, endLineNumber
        const selection = _editor.getSelection()
        if (selection) {
          startLineNumber = selection.startLineNumber
          endLineNumber = selection.endLineNumber
          if (selection.endColumn === 1) endLineNumber--
          if (startLineNumber === 1) return
        } else {
          const cursorPosition = _editor.getPosition()
          if (!cursorPosition) return
          startLineNumber = endLineNumber = cursorPosition.lineNumber
        }
        updateContentByCodeSelectionAction(_editor, startLineNumber, endLineNumber, 'highlight')
      },
    })
    editor.addAction({
      id: 'codeblock-fold',
      label: 'Code: Fold',
      keybindings: [],
      contextMenuGroupId: '2c_codeblock',
      contextMenuOrder: 3,
      run: async (_editor) => {
        const content = _editor.getValue()
        if (!content) return
        const selection = _editor.getSelection()
        if (!selection) return
        let { endColumn, startLineNumber, endLineNumber } = selection
        if (endColumn === 1) endLineNumber--
        if (startLineNumber === 1 || startLineNumber === endLineNumber) return
        updateContentByCodeSelectionAction(_editor, startLineNumber, endLineNumber, 'fold')


      },
    })

  }

  useEffect(() => {
    if (!teamId || !editor) return;
    if (monacoPromptTemplatesRegistered[teamId]) return;
    getDoc(`TEAM_DATA-TEAM:${teamId}`, { type: 'TEAM_DATA', teamId }, undefined, (doc) => {
      let yPromptTemplatesSettings = doc.getMap('EDITOR_PROMPT_TEMPLATES_SETTINGS')
      const yTemplates = yPromptTemplatesSettings?.get('templates')
      const templates = yTemplates?.toArray().map((i) => i.toJSON()) || []
      templates.unshift({
        key: "ai0",
        label: "",
        prefix: "",
        suffix: "",
        insertTextRatherThanReplace: true,
      })
      monacoPromptTemplatesRegistered[teamId] = true;
      registerMonacoAiAction(monaco, editor, templates);
    })

    return () => {
      monacoPromptTemplatesRegistered[teamId] = false;
    }
  }, [teamId, editor])

  useEffect(() => {
    if (!teamId || !editor) return;
    if (monacoScriptActionsRegistered[teamId]) return;
    getDoc(`TEAM_DATA-TEAM:${teamId}`, { type: 'TEAM_DATA', teamId }, undefined, (doc) => {
      let yScriptActionsSettings = doc.getMap('EDITOR_SCRIPT_ACTIONS_SETTINGS')
      const yActions = yScriptActionsSettings?.get('actions')
      const actions = yActions?.toArray().map((i) => i.toJSON())
      if (actions) {
        monacoScriptActionsRegistered[teamId] = true;
        registerMonacoScriptAction(monaco, editor, actions);
      }
    })

    return () => {
      monacoScriptActionsRegistered[teamId] = false;
    }
  }, [teamId, editor])

  return (<div className='relative w-full h-full'>
    <MonacoEditor
      language='markdown'
      theme='vs-dark'
      height='100%'
      width='100%'
      path={path}
      onMount={handleEditorDidMount}
      onChange={onEditorChange}
      options={{
        wordWrap: 'on',
        automaticLayout: true,
        scrollBeyondLastLine: true,
        scrollBeyondLastColumn: 0,
        padding: {
          top: 18,
          bottom: 18,
        },
        minimap: {
          enabled: false,
        },
      }}
    />
    <div className='absolute text-xs text-gray-600 bottom-2 right-6'><TimeAgo date={activeTime} /></div>
  </div>
  )
}

export default YEditor

function updateContentByCodeSelectionAction(editor, startLineNumber, endLineNumber, action) {
  const content = editor.getValue()
  const lines = content.split('\n')
  const codeBlockStartLineIndexReverse = lines.slice(0, startLineNumber - 1).reverse().findIndex((line) => /^```\w+=/.test(line))
  if (codeBlockStartLineIndexReverse === -1) return
  const codeBlockStartLineIndex = startLineNumber - codeBlockStartLineIndexReverse - 2
  if (lines.slice(startLineNumber - 1, endLineNumber).some(line => line.startsWith('```'))) return
  let { lang, startLine, highlightLines = '', foldLines = '' } = extraLangMeta(lines[codeBlockStartLineIndex].slice(3))
  const offset = startLine - (codeBlockStartLineIndex + 1) - 1
  switch (action) {
    case 'fold':
      foldLines += `${foldLines ? ',' : ''}${startLineNumber + offset}-${endLineNumber + offset}`
      break
    case 'highlight':
      const append = startLineNumber !== endLineNumber ? `${startLineNumber + offset}-${endLineNumber + offset}` : `${startLineNumber + offset}`
      highlightLines += `${highlightLines ? ',' : ''}${append}`
      break
    default:
  }
  editor.executeEdits(action, [{
    identifier: { major: 1, minor: 1 }, range: {
      startLineNumber: codeBlockStartLineIndex + 1,
      startColumn: 0,
      endColumn: lines[codeBlockStartLineIndex].length + 1,
      endLineNumber: codeBlockStartLineIndex + 1,
    }, text: joinLangMeta(lang, startLine, highlightLines, foldLines), forceMoveMarkers: false
  }])
}
function joinLangMeta(lang, startLine, highlightLines, foldLines) {
  return `\`\`\`${lang}=${startLine}${highlightLines ? `{${highlightLines}}` : ''}${foldLines ? `[${foldLines}]` : ''}`
}

