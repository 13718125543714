import toast from "react-hot-toast";
import { DID_SELECT_NON_EMPTY_TEXT_CONTEXT_KEY } from "../../components/yeditor";
import { openai } from "../openai";

export function registerMonacoAiAction(monaco, editor, templates) {
  for (let index = 0; index < templates.length; index++) {
    const template = templates[index];

    editor.addAction({
      id: `ai-${template.key}`,
      label: template.label ? `AI: ${template.label}` : `AI`,
      keybindings: index < 9 ? [monaco.KeyMod.CtrlCmd | (monaco.KeyCode.F3 + index)] : null,
      contextMenuGroupId: '2a_ai',
      contextMenuOrder: index,
      precondition: `${DID_SELECT_NON_EMPTY_TEXT_CONTEXT_KEY}`,
      run: async (_editor) => {
        const model = editor.getModel()
        const selection = editor.getSelection()
        const selectedText = model.getValueInRange(selection);
        const promptText = `${template.prefix}${selectedText}${template.suffix}`
        if (promptText.length === 0) return;

        // TODO: this is not yet possible
        // const insertTextRatherThanReplace = promptContext ? promptContext.insertTextRatherThanReplace: true
        const insertTextRatherThanReplace = true

        const toastId = toast.loading('Thinking...');
        try {
          const args = (() => {
            const data = {
              messages: [{
                role: template['openai_role'] || 'user',
                content: promptText,
              }],
            }
            if (template.enableOpenaiSettings) {
              for (const key in template) {
                if (key.startsWith('openai_')) {
                  if (key === 'openai_role') continue
                  data[key.replace('openai_', '')] = template[key]
                }
              }
            }
            return data
          })();
          const completion = await openai.createChatCompletion(args);
          if (!completion.choices || completion.choices.length === 0) {
            toast(JSON.stringify(completion))
            return;
          }

          const answerText = completion.choices[0].message.content;

          if (insertTextRatherThanReplace) {
            editor.executeEdits(null, [{
              range: {
                startColumn: selection.endColumn,
                startLineNumber: selection.endLineNumber,
                endColumn: selection.endColumn,
                endLineNumber: selection.endLineNumber,
              },
              text: answerText,
              forceMoveMarkers: true,
            }])
          } else {
            editor.executeEdits(null, [{
              range: selection,
              text: answerText,
              forceMoveMarkers: true,
            }])
          }
          editor.setSelection(selection)
        } catch (err) {
          toast.error(err.message);
        } finally {
          toast.dismiss(toastId)
        }
      },
    })
  }
}
