import { MenuItem, Tag } from '@blueprintjs/core'
import { useQuery } from 'react-query'
import { fetcher } from '../../utils'
import { Suggest2 } from "@blueprintjs/select";
import {matchSorter} from 'match-sorter'
import { HiTag } from 'react-icons/hi';
import { MdAddCircle } from 'react-icons/md';
import { useMemo, useState } from 'react';

export default function TagRefBar({ teamId, selectedTags, onSelect, onRemove }) {
  const { data: allTags } = useQuery(["tags-list"], async () => {
    return await fetcher(`/teams/${teamId}/tags/list?limit=999`)
  })
  const [query, setQuery] = useState('')

  const items = useMemo(() => {
    return [
      ...(query ? [{ key: query, isNew: true }] : []),
      ...(allTags || []),
    ]
  }, [allTags, query])

  return (
    <div className='flex flex-row gap-1 p-1' style={{ color: '#c9c9c9', backgroundColor: '#2d2d2d', borderTop: '1px solid #3b3b3b' }}>
      {(selectedTags || []).map((tag) => {
        return (
          <div>
            <Tag
              className='h-full cursor-pointer'
              key={tag.key}
              // minimal
              onRemove={(event) => {
                event.stopPropagation();
                onRemove && onRemove(tag)
              }}
              onClick={()=> {
                window.open(`/teams/${teamId}/tags/${tag.key}`, '_blank')
              }}
              icon={<HiTag />}
            >
              {tag.key}
            </Tag>
          </div>
        )
      })}
      <Suggest2
        inputProps={{
          placeholder: 'Add tag',
          style: { color: '#c9c9c9', backgroundColor: '#2d2d2d', border: 'none', boxShadow: 'none', height: 24 },
        }}
        items={items}
        itemListPredicate={(query, items) => {
          if (items.length === 0) return [];
          const result = matchSorter(items, query, { keys: ['key'] })
          result.sort((a, b) => !!a.isNew - !!b.isNew)
          return result
        }}
        itemRenderer={(tag, props) => {
          if (tag.isNew) {
            return (
              <MenuItem
                icon={<MdAddCircle />}
                active={props.modifiers.active}
                disabled={props.modifiers.disabled}
                rolestructure="listoption"
                onClick={props.handleClick}
                onFocus={props.handleFocus}
                text={`Create "${tag.key}"`}
              />
            )
          }
          return (
            <MenuItem
              active={props.modifiers.active}
              disabled={props.modifiers.disabled}
              rolestructure="listoption"
              key={tag.key}
              onClick={props.handleClick}
              onFocus={props.handleFocus}
              text={tag.key}
              icon={<HiTag />}
            />
          )
        }}
        onItemSelect={(tag) => {
          onSelect && onSelect(tag)
          setQuery("")
        }}
        query={query}
        onQueryChange={(query) => {
          setQuery(query)
        }}
        inputValueRenderer={(tag) => {
          return null
        }}
    />
    </div>
  )
}
