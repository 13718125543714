import { BsEye, BsLayoutSplit } from "react-icons/bs";
import { RiEditBoxLine } from "react-icons/ri";
import { atomFamily } from "recoil";
import { localStorageEffect } from "./atomEffects";

export enum EditorView {
  EDIT,
  PREVIEW,
  SPLIT,
}

export const editorViewStateFamily = atomFamily({
  key: "EditorView",
  default: EditorView.SPLIT,
  effects_UNSTABLE: [localStorageEffect()],
});

export const EditorViewUISet = {
  [EditorView.EDIT]: {
    Icon: RiEditBoxLine,
    label: "Edit",
  },
  [EditorView.PREVIEW]: {
    Icon: BsEye,
    label: "Preview",
  },
  [EditorView.SPLIT]: {
    Icon: BsLayoutSplit,
    label: "Split",
  },
};
// export const editorViewUISetSelector = selectorFamily({
//   key: "EditorViewToggleIcon",
//   get: (id) => ({ get }) => EditorViewUISet[get(editorViewStateFamily(id))],
// });
