import { unified } from 'unified'
import remarkParse from 'remark-parse'
import remarkGfm from 'remark-gfm'
import stringWidth from 'string-width'
import remarkRehype from 'remark-rehype'
import rehypeStringify from 'rehype-stringify'
import remarkFrontmatter from 'remark-frontmatter'
import { visit } from 'unist-util-visit'
import { get } from 'lodash'
import { remarkEmbedBlock, remarkEmbedBlockHandlers } from '../remark/remark-embed-block'
import { remarkAlert, remarkAlertHandlers } from '../remark/remark-alert'
import { remarkDetails, remarkDetailsHandlers } from '../remark/remark-details'
import { remarkMark, remarkRehypeMarkHandlers } from '../remark/remark-mark'

export default async function markdownToHtml(markdown, variables) {
  const result = await unified()
    .use(remarkParse)
    .use(remarkFrontmatter, 'yaml')
    .use(codeMetaTransform)
    .use(remarkRemovePageBreak)
    .use(remarkEmbedBlock, {
      disableRender: true,
    })
    .use(remarkMark)
    .use(remarkAlert)
    .use(remarkDetails)
    .use(remarkGfm, { stringLength: stringWidth })
    .use(remarkRehype, {
      handlers: {
        ...remarkEmbedBlockHandlers,
        ...remarkAlertHandlers,
        ...remarkDetailsHandlers,
        ...remarkRehypeMarkHandlers,
      },
    })
    .use(prism)
    .use(rehypeStringify)
    .process(markdown)
  return template(result.toString(), variables)
}

export const template = (content, data = {}) => content.replace(/{{(.+)}}/g, (match, key) => data[key.trim()] ?? match)

function prism() {
  return (tree) => {
    visit(tree, { tagName: 'pre' }, (pre) => {
      const code = pre.children[0]
      if (!code) return
      const {
        data: {
          meta: { startLine, highlightLines, foldLines },
        },
      } = code
      pre.properties.className = [
        ...get(pre, ['properties', 'className'], []),
        ...(startLine ? ['line-numbers'] : []),
      ]
      // code.properties.className = [...get(code, ['properties', 'className'], []), `language-diff-${lang}`, 'diff-highlight']
      pre.properties['data-line'] = highlightLines
      pre.properties['data-start'] = startLine
      pre.properties['data-fold'] = foldLines
    })
  }
}

function codeMetaTransform() {
  return (tree) => {
    visit(tree, 'code', (node) => {
      const { lang, startLine, highlightLines, foldLines } = extraLangMeta(node.lang)
      node.lang = lang
      node.meta = {
        startLine,
        highlightLines,
        lang,
        foldLines,
      }
    })
  }
}

export function extraLangMeta (header) {
  let matched = /^(\w+)(=(\d+)?(\{([\d\s\-,]+)\})?(\[([\d\s\-,]+)\])?)?/.exec(header)
  if (!matched) matched = []
  const lang = matched[1]
  const startLine = matched[3]
  const highlightLines = matched[5]
  const foldLines = matched[7]
  return {
    lang, startLine, highlightLines, foldLines
  }
}

function remarkRemovePageBreak() {
  return (tree) => {
    visit(tree, 'text', (node, index, parent) => {
      if (node.value === "\\pagebreak" && parent && typeof index === 'number') {
        parent.children.splice(index, 1);
        return index;
      }
    })
  }
}