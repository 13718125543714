import { ErrorBoundary } from "react-error-boundary"

function ErrorFallback({error, resetErrorBoundary}) {
    return (
        <div
          className="bg-gray-50 rounded shadow-md m-2 p-2 sm:m-4 sm:p-4"
          // style={{maxWidth: '720px'}}
        >
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <pre>{error.stack}</pre>
            <button className="mt-2 h-10 px-6 font-semibold rounded-md bg-black text-white" onClick={resetErrorBoundary}>
                Try again
            </button>
        </div>
    )
}

export function GenericErrorBoundary(props) {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(error, info) => { console.log(error, info) }}
            {...props}
        >
            {props.children}
        </ErrorBoundary>
    );
}
