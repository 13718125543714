import React from 'react'
import 'prismjs/themes/prism.css'
import 'github-markdown-css/github-markdown.css'
import 'prismjs/plugins/autolinker/prism-autolinker'
import 'prismjs/plugins/line-numbers/prism-line-numbers'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import 'prismjs/plugins/autolinker/prism-autolinker'
import '../code.css'
import { useMd } from '../hooks/useNoteRender'


export default function Note({ noteContent, variables }) {
  const { ref, __html } = useMd(noteContent, variables)
  return (
    <div
      className='bg-gray-50 rounded shadow-md m-1 sm:mt-2 w-full'
      style={{
        maxWidth: '720px',
      }}
    >
      <div ref={ref} className='p-2 sm:p-4 markdown-body hacknote' dangerouslySetInnerHTML={{ __html }} />
    </div>
  )
}

export function PlainMarkdownNote({ noteContent }) {
  const { __html } = useMd(noteContent)
  return <div className='markdown-body' dangerouslySetInnerHTML={{ __html }} />
}
