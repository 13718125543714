import { chunk, range } from 'lodash'

const parse = (foldStr) =>
  foldStr
    .split(',')
    .filter(Boolean)
    .map((g) =>
      g
        .split('-')
        .filter(Boolean)
        .map((s) => s.trim())
    )
    .filter(s => s.length === 2)
function mergeFolds(folds) {
  if (folds.length <= 1) {
    return folds
  }
  let arr = folds.sort((a, b) => a[0] - b[0]);
  function unite(arr, i) {
    if (i === arr.length - 1) {
      return arr
    }
    if (arr[i][0] <= arr[i + 1][0] && arr[i + 1][0] <= arr[i][1]) {
      arr[i] = [Math.min(arr[i][0], Math.max(arr[i + 1][0])),
      Math.max(arr[i][1], Math.max(arr[i + 1][1]))
      ];
      arr.splice(i + 1, 1);
    } else {
      i++;
    }
    return unite(arr, i)
  }
  return unite(arr, 0)
}

function insertFolds(codeElement, i) {
  if (codeElement.querySelector('details')) return // skip rendered code
  if (!codeElement.querySelector('span')) return // skip inline code
  const $preTag = codeElement.parentElement
  const $highlightLines = [...$preTag.querySelectorAll('.line-highlight')]
  const offset = +($preTag.dataset.start ?? '0')
  const regexLowLines = /<span aria-hidden="true" class=\"line-numbers-rows\">.*<\/span><\/span>/
  const rowNumberLinesHTML = codeElement.innerHTML.match(regexLowLines) && codeElement.innerHTML.match(regexLowLines)[0]
  let rows = codeElement.innerHTML.replace(regexLowLines, '').split('\n')
  let lineCount = () => rows.length
  const foldLines = mergeFolds(
    parse(codeElement.parentElement.dataset.fold ?? '')
      .map(([start, end]) => {
        return [start - offset, end - offset > lineCount() - 2 ? lineCount() - 2 : end - offset]
      })
      .sort(([a], [b]) => a - b)
  )
  const ids = []
  for (let [start, end] of foldLines) {
    const id = `detail-${i}-${start}-${end}`
    ids.push(id)
    rows[start] = `<details id='${id}' data-start='${start}' data-end='${end}'><summary>@@ ${start + offset},${end + offset} @@</summary>${rows[start]}`
    rows[end] = `${rows[end] || '<span> </span>'}</details>`
  }
  codeElement.innerHTML = rows.join('\n').concat(rowNumberLinesHTML ?? '')
  const $lineNumRows = [...codeElement.querySelectorAll('.line-numbers-rows span')]
  $lineNumRows.forEach((row, i) => {
    foldLines.forEach(([start, end]) => {
      if (start <= i && i <= end - 1) {
        row.classList.add('line-folded', 'line-hide-content')
      }
    })
  })

  $highlightLines.forEach((line) => {
    const n = +line.dataset.range - offset
    foldLines.forEach(([start, end]) => {
      if (start <= n && n <= end) {
        line.classList.add('line-folded')
      }
    })
  })
  const handleToggle = (e) => {
    const start = e.target.dataset.start
    const end = e.target.dataset.end
    if (start == null || end == null) return
    const isClose = !e.target.open
    range(+start, +end).forEach((i) => {
      $lineNumRows[i].classList.toggle('line-folded', isClose)
      $lineNumRows[i].classList.toggle('line-hide-content', isClose)
    })
    $highlightLines.forEach((line) => {
      if (+line.dataset.range >= +start + offset && +line.dataset.range <= +end + offset) {
        line.classList.toggle('line-folded', isClose)
      }
    })
  }
  ids.forEach((id) => {
    document.querySelector(`#${id}`)?.addEventListener('toggle', handleToggle)
  })
}

export default function foldLines(codeElements) {
  setTimeout(() => {
    codeElements.forEach(insertFolds)
  }, 200)
}
