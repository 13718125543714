/* eslint-disable react/jsx-no-target-blank */
import { Button } from '@blueprintjs/core'
import { debounce } from 'lodash'
import { useMemo } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Split from 'react-split'
import { useTitle } from 'react-use'
import Header from '../../components/header'
import HistoryDocuments from '../../components/historyDocuments'
import Note from '../../components/note'
import { TeamKBarXSetup } from '../../components/teamKBarSetup'
import YEditor from '../../components/yeditor'
import { useProfile } from '../../hooks/useProfile'
import { GenericErrorBoundary } from '../../module/chaos/GenericErrorBoundary'
import { KBarX } from '../../module/kbar/KBarX'
import { useRegisterActions } from '../../module/kbar/KBarXProvider'
import { DocumentProvider, getDoc, getSortedArray, insertMap, moveMap, useArray, useDoc, useForceUpdate, useMap } from '../../module/y/hook'
import { contentToTitle, fetcher, generateKey, poster, reportUrl } from '../../utils'
import { AiOutlinePlus } from 'react-icons/ai'
import { generateNKeysBetween } from '../../module/fractional-indexing'
import { useQuery } from 'react-query'
import * as Y from 'yjs'
import FolderList from '../../components/folder/folderList'
import { FaFolderPlus } from 'react-icons/fa'
import { ContextMenuItem, ContextMenuSeparator } from '../../components/contextMenu'
import toast from 'react-hot-toast'
import TimeAgo from '../../components/TimeAgo'


const createDocumentOnServer = (doc) => poster(`/teams/${doc.team}/documents`, doc)
const updateDocument = debounce((doc) => poster(`/teams/${doc.teamId}/documents/${doc.documentId}`, doc, 'PUT'), 1000)
const updateDocumentGroup = (teamId, groupId, docs) => poster(`/teams/${teamId}/documentGroups/${groupId}`, docs, 'PUT')
const updateDocumentPosOnServer = (teamId, documentId, pos) => poster(`/teams/${teamId}/documents/${documentId}/pos`, { pos }, 'PUT')
const updateDocumentGroupFolderPosOnServer = (teamId, groupId, folderId, pos) => poster(`/teams/${teamId}/documentGroups/${groupId}/folders/${folderId}/pos`, { pos }, 'PUT')
const createDocumentGroupFolderOnServer = (teamId, groupId, doc) => poster(`/teams/${teamId}/documentGroups/${groupId}/folders`, doc, 'POST')
const updateDocumentGroupFolderOnServer = (teamId, groupId, folderId, doc) => poster(`/teams/${teamId}/documentGroups/${groupId}/folders/${folderId}`, doc, 'PUT')
const deleteDocumentGroupFolderOnServer = (teamId, groupId, folderId) => poster(`/teams/${teamId}/documentGroups/${groupId}/folders/${folderId}`, {}, 'DELETE')
const updateDocumentFolderOnServer = (teamId, groupId, documentId, folder) => poster(`/teams/${teamId}/documentGroups/${groupId}/documents/${documentId}/folder`, { folder }, 'PUT')

const createYDocumentFolder = (team, group) => {
  const m = new Y.Map()
  Object.entries({
    key: generateKey(),
    name: 'Untitled Folder',
    team,
    group,
  }).forEach(([key, value]) => {
    m.set(key, value)
  })
  return m
}
const createYDocument = (team, group) => {
  const m = new Y.Map()
  Object.entries({
    key: generateKey(),
    title: 'Untitled Document',
    content: new Y.Text('# Untitled Document'),
    team,
    group,
  }).forEach(([key, value]) => {
    m.set(key, value)
  })
  return m
}
function EditDocumentMain({ teamId, documentGroupId }) {
  const { profile } = useProfile()
  const userId = JSON.parse(localStorage.profile)?.id
  const [editingDocument, setEditingDocument] = useState(null)
  const yDoc = useDoc()
  const yMeta = useMap('META')
  const teamName = yMeta.state.TEAM_NAME ?? '...'
  const documentGroupName = yMeta.get('DOCUMENT_GROUP_TITLE') ?? '...'
  const yFolders = useArray('FOLDERS')
  const yFolderOpened = useMap(`FOLDER_OPENED:${profile.id}`)
  const yDocuments = useArray('DOCUMENTS')
  const folders = yFolders.toArray().map((i) => i.toJSON())
  const sortedFolders = getSortedArray(yFolders || []).map((yd) => yd.toJSON())
  const lastDocumentFolderPos = useMemo(() => sortedFolders[sortedFolders.length - 1]?.pos, [sortedFolders])

  const documents = yDocuments.toArray().map((i) => i.toJSON())
  const sortedDocuments = getSortedArray(yDocuments || []).map((yd) => yd.toJSON())
  const lastDocumentPos = useMemo(() => sortedDocuments[sortedDocuments.length - 1]?.pos, [sortedDocuments])
  const originalKeys = yDocuments.map((yn) => yn.get('key'))
  useTitle(documentGroupName ?? 'Untitled')

  const forceUpdate = useForceUpdate()

  const [drawerOpenHistoryDocumentKey, setDrawerOpenHistoryDocumentKey] = useState(false)
  const drawerOpenHistoryDocument = useMemo(() => {
    return drawerOpenHistoryDocumentKey && documents.find((i) => i.key === drawerOpenHistoryDocumentKey)
  }, [documents, drawerOpenHistoryDocumentKey])

  const { data: dbDocuments, refetch: refetchDbDocuments } = useQuery(['documents', teamId, documentGroupId], async () => {
    return await fetcher(`/teams/${teamId}/documentGroups/${documentGroupId}/documents`)
  })

  const createNewDocumentFolder = () => {
    const newFolder = createYDocumentFolder(teamId, documentGroupId)
    yDoc.transact(() => {
      newFolder.set('pos', generateNKeysBetween(lastDocumentFolderPos ?? null, null, 1))
      insertMap(yFolders, newFolder, yFolders.length)
    })
    createDocumentGroupFolderOnServer(teamId, documentGroupId, newFolder.toJSON())
  }

  const createNewDocument = (folderKey) => {
    const newDocument = createYDocument(teamId, documentGroupId)
    if (folderKey) {
      newDocument.set('folder', folderKey)
    }
    yDoc.transact(() => {
      newDocument.set('pos', generateNKeysBetween(lastDocumentPos ?? null, null, 1))
      insertMap(yDocuments, newDocument, yDocuments.length)
    })
    setEditingDocument(newDocument)
    createDocumentOnServer(newDocument.toJSON())
  }

  useRegisterActions([
    {
      id: 'newDocument',
      name: 'New Document',
      shortcut: ['n'],
      keywords: 'new create',
      perform: () => {
        createNewDocument()
      },
    },
  ])

  const onDocumentGroupNameChange = (name) => {
    yMeta.set('DOCUMENT_GROUP_TITLE', name)
    getDoc(`TEAM_DATA-TEAM:${teamId}`, { type: 'TEAM_DATA', teamId }, doc => {
      doc.getArray('DOCUMENT_GROUPS').toArray().find(d => d.get('key') === documentGroupId)?.set('name', name)
    })
    updateDocumentGroup(teamId, documentGroupId, { name })
  }
  const breads = useMemo(() => [{title: teamName, link: `/teams/${teamId}`}, {title: documentGroupName, onChange: onDocumentGroupNameChange}], [teamName, documentGroupName])

  if (!editingDocument && yDocuments.length > 0) {
    setEditingDocument(yDocuments.get(0))
  }

  return (
    <TeamKBarXSetup>
      <KBarX>
        <Header teamId={teamId} breads={breads} />
        <Split className='flex-grow overflow-auto split' sizes={[14, 43, 43]} minSize={[100, 300, 300]} expandToMin={true}>
          <div className='flex flex-col h-full overflow-hidden' style={{ width: 200 }}>
            <div className='flex flex-row items-center p-1' style={{ backgroundColor: '#2d2d2d' }}>
              <Button
                minimal
                onClick={() => {
                  createNewDocument()
                  document.activeElement.blur()
                }}
              >
                <AiOutlinePlus className='text-white' />
              </Button>
              <Button
                minimal
                onClick={() => {
                  createNewDocumentFolder()
                  document.activeElement.blur()
                }}
              >
                <FaFolderPlus className='text-white' />
              </Button>
            </div>
            <div className='flex-1 overflow-y-scroll'>
              <FolderList
                entityName={'Document'}
                activeEntityKey={editingDocument?.get('key')}
                folders={folders}
                folderOpened={yFolderOpened.state}
                entities={documents}
                onEntityKey={(entity) => {
                  return entity.key
                }}
                onEntityMenu={(document) => {
                  const docLink = `${reportUrl}/${teamId}/doc/${document.key}`
                  return (
                    <GenericErrorBoundary>
                      <ContextMenuItem
                        onClick={() => {
                          window.open(docLink, '_blank')
                        }}
                      >
                        Open View Page
                      </ContextMenuItem>
                      <ContextMenuItem
                        onClick={() => {
                          setDrawerOpenHistoryDocumentKey(document.key)
                        }}
                      >
                        Version History
                      </ContextMenuItem>
                      <ContextMenuItem
                        onClick={() => {
                          const copyPromise = navigator.clipboard.writeText(docLink)
                          toast.promise(copyPromise, {
                            success: `View link to "${document.title}" Copied!`,
                          })
                        }}
                      >
                        Copy View Link
                      </ContextMenuItem>
                      <ContextMenuSeparator />
                      <ContextMenuItem
                        onClick={() => {
                          poster(
                            `/teams/${teamId}/documents/${document.key}`,
                            { ...document, updatedAt: Date.now() },
                            'PUT'
                          )
                            .then(async (res) => {
                              toast.success(`Success to sync document to db!`)
                              refetchDbDocuments()
                            })
                            .catch((err) => toast.error('Fail to sync document to db!'))
                        }}
                      >
                        <div>
                          <div>Sync DB</div>
                          <div className='mt-1 text-xs text-gray-600'>
                            last: <TimeAgo date={dbDocuments?.find((n) => n.key === document.key)?.updatedAt} />
                          </div>
                        </div>
                      </ContextMenuItem>
                      <ContextMenuSeparator />
                        <ContextMenuItem
                          onClick={() => {
                            poster(`/teams/${teamId}/documents/${document.key}`, {}, 'DELETE')
                            const editingKey = editingDocument?.get('key')
                            yDocuments.delete(originalKeys.indexOf(document.key), 1)
                            if (editingKey === document.key) {
                              setEditingDocument(null)
                            }
                          }}
                        >
                          <span className='text-red-800'>Delete</span>
                        </ContextMenuItem>
                    </GenericErrorBoundary>
                  )
                }}
                onEntityRender={(entity) => {
                  return (
                    <div className="flex flex-row items-center w-full">
                      <div className="flex-1 truncate">{entity.title}</div>
                    </div>
                  )
                }}
                onEntityTooltipRender={(entity, index) => {
                  return (
                    <div className="p-2 bg-white rounded" style={{ maxWidth: 260 }}>
                      {entity.title}
                    </div>
                  )
                }}
                onEntityClick={(entityKey) => {
                  const yDocument = yDocuments?.toArray().find((i) => i.get('key') === entityKey)
                  if (!yDocument) return;
                  setEditingDocument(yDocument)
                }}
                onEntityCreateInFolder={(folderKey) => {
                  createNewDocument(folderKey)
                  document.activeElement.blur()
                }}
                onFolderDelete={(folderKey) => {
                  const yFolderIndex = yFolders?.toArray().findIndex((i) => i.get('key') === folderKey)
                  if (yFolderIndex === -1) return;
                  yDoc.transact(() => {
                    for (const yDocument of yDocuments.toArray()) {
                      if (yDocument.get('folder') === folderKey) {
                        yDocument.set('folder', null)
                      }
                    }
                    yFolders.delete(yFolderIndex, 1)
                  })
                  deleteDocumentGroupFolderOnServer(teamId, documentGroupId, folderKey)
                }}
                onFolderOpened={(folderKey, opened) => {
                  yFolderOpened.set(folderKey, opened)
                }}
                onFolderNameChange={(folderKey, name) => {
                  const yFolder = yFolders?.toArray().find((i) => i.get('key') === folderKey)
                  if (!yFolder) return;
                  yDoc.transact(() => {
                    yFolder.set('name', name)
                  })
                  updateDocumentGroupFolderOnServer(teamId, documentGroupId, folderKey, { name })
                }}
                onFolderPosChange={(folderKey, toIndex) => {
                  const yFolder = yFolders.toArray().find((i) => i.get('key') === folderKey)
                  if (!yFolder) return;
                  const pos = moveMap(yFolders, yFolder, toIndex)
                  updateDocumentGroupFolderPosOnServer(teamId, documentGroupId, folderKey, pos)
                  forceUpdate()
                }}
                onEntityFolderAndPosChange={(entityKey, folderKey, toIndex) => {
                  const yDocument = yDocuments?.toArray().find((i) => i.get('key') === entityKey)
                  if (!yDocument) return;
                  if (!folderKey) {
                    yDoc.transact(() => {
                      yDocument.set('folder', null)
                      const pos = moveMap(yDocuments, yDocument, toIndex, (i) => !i.get('folder'))
                      updateDocumentPosOnServer(teamId, yDocument.get('key'), pos)
                      updateDocumentFolderOnServer(teamId, documentGroupId, yDocument.get('key'), null)
                    })
                  } else {
                    yDoc.transact(() => {
                      yDocument.set('folder', folderKey)
                      const pos = moveMap(yDocuments, yDocument, toIndex, (i) => i.get('folder') === folderKey)
                      updateDocumentPosOnServer(teamId, yDocument.get('key'), pos)
                      updateDocumentFolderOnServer(teamId, documentGroupId, yDocument.get('key'), folderKey)
                    })
                  }
                }}
              />
            </div>
          </div>
          <div className='overflow-hidden'>
            <GenericErrorBoundary>
              {editingDocument ? (
                <YEditor
                  path={`/document/${editingDocument.get('key')}/content`}
                  yText={editingDocument.get('content')}
                  onChange={(content) => {
                    const title = contentToTitle(content)
                    yDoc.transact(() => {
                      editingDocument.set('title', title)
                      editingDocument.set('lastModifiedByUserId', userId)
                      editingDocument.set('updatedAt', Date.now())
                    })
                    updateDocument({ teamId, documentId: editingDocument.get('key'), content, title, updatedAt: Date.now() })
                  }}
                />
              ) : (
                <div className='flex items-center justify-center h-full'>
                  <p className='text-white opacity-50'>Command + K for Quick Actions</p>
                </div>
              )}
            </GenericErrorBoundary>
          </div>
          <div className='h-full overflow-auto'>
            <div className='flex justify-center '>
              <GenericErrorBoundary>{editingDocument ? <Note noteContent={editingDocument.toJSON().content} /> : null}</GenericErrorBoundary>
            </div>
          </div>
        </Split>

        {drawerOpenHistoryDocumentKey && drawerOpenHistoryDocument && (
          <HistoryDocuments
            isOpen={!!drawerOpenHistoryDocumentKey}
            onClose={() => {
              setDrawerOpenHistoryDocumentKey(null)
            }}
            teamId={teamId}
            documentId={drawerOpenHistoryDocumentKey}
            document={drawerOpenHistoryDocument}
            onRestored={() => {
              // TODO: restore
            }}
          />
        )}
      </KBarX>
    </TeamKBarXSetup>
  )
}

export default function EditDocument() {
  const { teamId, documentGroupId } = useParams()
  const docName = `DOCUMENT_GROUP_DATA-TEAM:${teamId}::GROUP:${documentGroupId}`
  const docMeta = {
    type: 'DOCUMENT_GROUP_DATA',
    teamId,
    documentGroupId,
  }
  return (
    <DocumentProvider docName={docName} docMeta={docMeta}>
      <EditDocumentMain teamId={teamId} documentGroupId={documentGroupId} />
    </DocumentProvider>
  )
}
