import { Drawer, Spinner, NonIdealState, Menu, MenuItem, Button, Popover, Alert } from "@blueprintjs/core";
import React, { useEffect, useState, useMemo } from 'react';
import Split from 'react-split';
import toast from 'react-hot-toast'
import useSWR from 'swr';
import { contentToTitle, fetcher, poster } from '../utils';
import { format } from 'date-fns';
import { GenericErrorBoundary } from '../module/chaos/GenericErrorBoundary'
import Note from './note'
import HistoryDocumentEditorReadonly from "./historyDocumentEditorReadonly";

export default function HistoryDocuments({isOpen, onClose, teamId, documentId, document, onRestored}) {
  const { mutate: reloadHistoryDocuments, data: historyDocuments, isValidating, error } = useSWR(isOpen ? `/teams/${teamId}/documents/${documentId}/historyDocuments` : null, fetcher)
  const [selectedHistoryDocument, setSelectedHistoryDocument] = useState(null)

  const isLoading = !historyDocuments && !error && isValidating

  const fakeCurrentHistoryDocument = useMemo(() => {
    return {
      key: "current",
      team: document.team,
      group: document.group,
      content: document.content,
      lastModifiedAt: document.updatedAt || Date.now(),
      isCurrent: true,
    }
  }, [document])

  const allHistoryDocuments = useMemo(() => {
    if (!historyDocuments || historyDocuments.length === 0) return []
    return [
      fakeCurrentHistoryDocument,
      ...historyDocuments,
    ].filter((i) => !!i.lastModifiedAt)
  }, [fakeCurrentHistoryDocument, historyDocuments])

  useEffect(() => {
    setSelectedHistoryDocument(fakeCurrentHistoryDocument)
  }, [fakeCurrentHistoryDocument])

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      className="bp4-dark"
      style={{ transition: 'none', backgroundColor: "#333" }}
      title={
        <div className="flex flex-col ">
          <span className="text-sm">{contentToTitle(document.content)}</span>
        </div>
      }
      hasBackdrop={false}
      icon={"history"}
      size={"100%"}
      usePortal={true}
    >
      {isLoading && (
        <div className="flex flex-col items-center justify-center h-full">
          <NonIdealState
            icon={<Spinner/>}
            title={"Loading History Documents"}
          />
        </div>
      )}
      {!isLoading && (
        <div className="w-full h-full">
          {(!historyDocuments || historyDocuments.length === 0) ? (
            <NonIdealState
              icon="search"
              title={"No History Documents Found"}
            />
          ) : (
            <Split className='flex-grow h-full overflow-auto split' sizes={[40, 40, 20]} minSize={[400, 400, 300]} maxSize={[Infinity, Infinity, 300]} expandToMin={true}>
              <div className='overflow-hidden'>
                <GenericErrorBoundary>
                  {selectedHistoryDocument && (
                    <HistoryDocumentEditorReadonly
                      selectedHistoryDocument={selectedHistoryDocument}
                      allHistoryDocuments={allHistoryDocuments}
                    />
                  )}
                </GenericErrorBoundary>
              </div>
              <div className='h-full overflow-auto'>
                <div className='flex justify-center'>
                  <GenericErrorBoundary>
                    {selectedHistoryDocument && selectedHistoryDocument.content ? <Note noteContent={selectedHistoryDocument ? selectedHistoryDocument.content : ''} /> : null}
                  </GenericErrorBoundary>
                </div>
              </div>
              <div className="flex flex-col" style={{ width: 300, marginRight: 5 }}>
                <div className="m-4 text-xl">Version History</div>
                <ul className="h-full border-t notes-list" style={{ borderColor: 'rgba(255, 255, 255, 0.2)' }}>
                  {(!allHistoryDocuments || allHistoryDocuments.length <= 1) && (
                    <div className="flex items-center justify-center w-full h-full text-gray-400">
                      No Data
                    </div>
                  )}
                  {(allHistoryDocuments || []).map((historyNote) => {
                    const isSelected = selectedHistoryDocument && selectedHistoryDocument.key === historyNote.key
                    return (
                      <HistoryNoteItem
                        key={historyNote.key}
                        historyNote={historyNote}
                        selectedHistoryNote={selectedHistoryDocument}
                        isSelected={isSelected}
                        onSelect={() => setSelectedHistoryDocument(historyNote)}
                        onRestored={() => {
                          reloadHistoryDocuments()
                          onRestored()
                        }}
                      />
                    )
                  })}
                </ul>
              </div>
            </Split>
          )}
        </div>
      )}
    </Drawer>
  )
}

function HistoryNoteItem({ historyNote, selectedHistoryNote, onSelect, onRestored }) {
  const [restoreConfirmOpen, setRestoreConfirmOpen] = useState(false)
  const isSelected = historyNote && historyNote.key === selectedHistoryNote?.key
  const time = format(new Date(historyNote.lastModifiedAt), 'MMM d, h:mm bb')
  return (
    <li
      key={historyNote.key}
      className={`flex flex-col hover:bg border-b cursor-pointer`}
      style={{ borderColor: 'rgba(255, 255, 255, 0.2)' }}
      onClick={onSelect}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col mx-4 my-3">
          <div className={`font-medium mb-2 ${isSelected ? 'text-blue-500' : ''}`}>{time}</div>
          {historyNote.isCurrent ? (
            <div className={`${isSelected ? 'text-blue-500' : ''}`}>Current version</div>
          ) : (
            <div className="flex flex-row items-center">
              <span className="w-2 h-2 mr-2 rounded-full" style={{ marginTop: 2, backgroundColor: historyNote.user?.color || "orange" }}></span>
              <span>{historyNote.user?.username || historyNote.userId}</span>
            </div>
          )}
        </div>
        {!historyNote.isCurrent && (
          <div className="mr-2" onClick={(event) => {
            event.stopPropagation();
          }}>
            <Popover
              style={{ backgroundColor: "#333" }}
              content={
                <Menu style={{ backgroundColor: "#333" }}>
                  <MenuItem
                    intent="danger"
                    icon="undo"
                    text="Restore this version"
                    onClick={() => {
                      setRestoreConfirmOpen(true)
                    }}
                  />
                </Menu>
              }
              placement="bottom-end"
            >
              <Button icon="more" minimal></Button>
            </Popover>

          </div>
        )}
      </div>
      <Alert
        className="bp4-dark"
        style={{ backgroundColor: "#333" }}
        cancel
        cancelButtonText="Cancel"
        confirmButtonText="Restore"
        icon="undo"
        intent="danger"
        isOpen={restoreConfirmOpen}
        onClose={() => { setRestoreConfirmOpen(false) }}
        onConfirm={async () => {
          setRestoreConfirmOpen(false)
          const requestPromise = poster(`/teams/${historyNote.team}/notes/${historyNote.note}/revert`, {
            historyNoteId: historyNote.key,
          })
          toast.promise(requestPromise, {
            loading: `Restoring note...`,
            success: `Note restored!`,
            error: `Error when restoring project title`,
          })
          await requestPromise;
          onRestored()
        }}
      >
        <p><span className="font-bold">{contentToTitle(historyNote.content)}</span></p>
        <p>
          Your current note will restore to the version from <span className="font-bold text-red-400">{time}</span>.
        </p>
      </Alert>
    </li>
  )

}