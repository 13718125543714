import { Button, FormGroup, Icon, InputGroup, Spinner } from '@blueprintjs/core'
import { Select2 } from '@blueprintjs/select'
import { Formik } from 'formik'
import { keyBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQuery } from 'react-query'
import { fetcher, poster } from '../../../utils'
const initialConfigOption = (team, project, title) => ({
  key: null,
  team,
  project,
  title,
  template: null,
  template_vars: {},
  build_in_template_vars: {
    bgURL: null,
    bgColor: '#ffffff',
    fontColor: '#000000',
  },
})

const getOgPreviewURL = ({ bgURL, bgColor, fontColor }) => {
  const issues = { h: 1, m: 2, i: 3, n: 4, g: 5 }
  const ogImgSearchParams = { ...issues, ...(bgURL ? { bgURL } : {}), ...(bgColor ? { bgColor } : {}), ...(fontColor ? { fontColor } : {}) }
  return `${process.env.REACT_APP_OG_URI ?? 'https://og.hacknote.co'}/Preview.png?${new URLSearchParams(ogImgSearchParams).toString()}`
}

export default function TeamSettingsOgConfig({ teamId }) {
  const {
    data: projects,
    isLoading: isProjectsLoading,
    error: loadProjectsError,
  } = useQuery(['Projects', teamId], () => fetcher(`/teams/${teamId}/projects`), {
    enabled: !!teamId,
    staleTime: 1000 * 60,
  })
  const {
    data: configs,
    isLoading: isConfigsLoading,
    error: loadConfigsError,
    refetch: refetchOgConfigs,
  } = useQuery(['OgConfigsUnderTeam', teamId], () => fetcher(`/teams/${teamId}/all-report-config`), {
    enabled: !!teamId,
    staleTime: 1000 * 60 * 10,
  })

  const configOptions = useMemo(() => {
    if (!configs || !projects) return []
    const teamConfigOption = { ...(configs.find((c) => c.project == null) ?? initialConfigOption(teamId, null)), title: 'Team' }
    const settledProjectConfigs = keyBy(configs, 'project')
    const projectConfigOptions = projects.map((p) =>
      settledProjectConfigs[p.key] ? { ...settledProjectConfigs[p.key], title: p.title } : initialConfigOption(teamId, p.key, p.title)
    )
    return [teamConfigOption, ...projectConfigOptions]
  }, [configs, projects])

  useEffect(() => {
    if (configOptions.length > 0 && !editingConfig) {
      const editingConfig = configOptions[0]
      setEditingConfig(editingConfig)
      setOgPreviewURL(getOgPreviewURL(editingConfig.build_in_template_vars))
    }
  }, [configOptions])

  const [editingConfig, setEditingConfig] = useState(null)
  const formValue = useMemo(() => {
    return {
      bgColor: '',
      bgURL: '',
      fontColor: '',
      ...editingConfig?.build_in_template_vars,
    }
  }, [editingConfig])

  const addOgConfig = useMutation(async (args) => {
    const { project, team, template, template_vars, build_in_template_vars } = args
    const path = `/teams/${team}${project ? `/projects/${project}` : ''}/report-config`
    console.log(path)
    const requestPromise = poster(path, { template, template_vars, build_in_template_vars }, 'POST')
    toast.promise(requestPromise, {
      loading: `Updating OG config...`,
      success: `OG config updated!`,
      error: `Error when updating OG config`,
    })
    await requestPromise
    await refetchOgConfigs()
    return
  })

  const deleteConfig = useMutation(async ({project, team}) => {
    const path = `/teams/${team}${project ? `/projects/${project}` : ''}/report-config`
    console.log('delete path: ', path)
    const requestPromise = poster(path, undefined, 'DELETE')
    toast.promise(requestPromise, {
      loading: `Deleting OG config...`,
      success: `OG config was deleted!`,
      error: `Error when deleting OG config`,
    })
    await requestPromise
    await refetchOgConfigs()
    return
  })
  const [ogPreviewURL, setOgPreviewURL] = useState()

  return (
    <div>
      {isProjectsLoading || isConfigsLoading ? (
        <div className='flex items-center justify-center' style={{ minHeight: 240 }}>
          <Spinner size={25} />
        </div>
      ) : null}
      {(!isProjectsLoading && loadProjectsError) || (!isConfigsLoading && loadConfigsError) ? (
        <div className='flex items-center justify-center' style={{ minHeight: 240 }}>
          Something went wrong when load OG Configs:
        </div>
      ) : null}
      <Select2
        className='mb-4'
        popoverProps={{ minimal: true, matchTargetWidth: true }}
        menuProps={{ style: { padding: 0 } }}
        items={configOptions}
        itemRenderer={(item, props) => {
          return (
            <div onClick={props.handleClick} className='flex justify-between px-2 py-1 cursor-pointer hover:bg-blue-200'>
              <div>
                <Icon className={`mr-1 ${item.key ? 'text-green-500' : 'text-gray-300'}`} icon='saved' />
                {item.title}
              </div>
              <div className='text-xs text-gray-400'>{item.project}</div>
            </div>
          )
        }}
        onItemSelect={(item) => {
          setEditingConfig(item)
        }}
      >
        <Button fill icon='document' text={editingConfig?.title} />
      </Select2>
      <Formik
        enableReinitialize
        initialValues={formValue}
        onSubmit={async (values) => {
          addOgConfig.mutateAsync({
            ...editingConfig,
            build_in_template_vars: values,
          })
        }}
      >
        {({ values, handleChange, dirty, isSubmitting, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className='flex flex-col'>
                <FormGroup label='Background Image URL' labelFor='bgURL'>
                  <InputGroup id='bgURL' name='bgURL' value={values.bgURL ?? ''} onChange={handleChange} />
                </FormGroup>
                <FormGroup label='Background Color' labelFor='bgColor'>
                  <InputGroup id='bgColor' name='bgColor' value={values.bgColor ?? ''} onChange={handleChange} />
                </FormGroup>
                <FormGroup label='Font Color' labelFor='fontColor'>
                  <InputGroup id='fontColor' name='fontColor' value={values.fontColor ?? ''} onChange={handleChange} />
                </FormGroup>
                <img src={ogPreviewURL} className='mb-4' />
                <Button intent='none' className='mb-4' onClick={() => {
                  setOgPreviewURL(getOgPreviewURL(values))
                }}>
                 Preview 
                </Button>
                <Button className='mb-6' intent='primary' type='submit' disabled={!dirty || isSubmitting}>
                  Update
                </Button>
                {editingConfig?.key ? <Button intent='danger' onClick={() => {
                  deleteConfig.mutateAsync({ team: editingConfig.team, project: editingConfig.project })
                }}>
                  Delete
                </Button> : null}
              </div>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}
