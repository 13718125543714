import { Button, Dialog, InputGroup, Spinner } from '@blueprintjs/core'
import { useRef } from 'react'
import useSWR from 'swr'
import { useMap } from '../module/y/hook'
import { fetcher, poster } from '../utils'

export default function ViewLinkPasswordSetting({ isOpen, onClose, teamId, projectId: project }) {
  const yMeta = useMap('META')
  const shareKey = yMeta.get('shareKey')
  const { data: share, isValidating, error } = useSWR(isOpen && shareKey ? `/teams/${teamId}/share/${shareKey}` : null, fetcher)
  const isLoading = !share && !error && isValidating
  const passwordInputRef = useRef(null)
  console.log('shareKey', shareKey, share)
  return (
    <Dialog className='bp4-dark' title={'Project View Link Password'} isOpen={isOpen} onClose={onClose} canOutsideClickClose={false}>
      <div className='p-4'>
        {isLoading && (
          <div className='flex items-center justify-center' style={{ minHeight: 240 }}>
            <Spinner size={25} />
          </div>
        )}
        {!isLoading && error && (
          <div className='flex items-center justify-center' style={{ minHeight: 240 }}>
            {error}
          </div>
        )}
        <InputGroup
          type='text'
          defaultValue={share?.password ?? ''}
          className='my-4'
          autoComplete='new-password'
          name={`project-${project}-view-link-password`}
          inputRef={passwordInputRef}
        />
        <Button
          fill
          onClick={async () => {
            const password = passwordInputRef.current.value
            const { key } = await poster(`/teams/${teamId}/share`, {
              password,
              project,
              ...(shareKey ? { key: shareKey } : {}),
            })
            if (key) yMeta.set('shareKey', key)
          }}
        >{`${shareKey ? 'Update' : 'Set'} Password`}</Button>
        {shareKey ? (
          <Button
            className='mt-4'
            intent='danger'
            fill
            onClick={async () => {
              await poster(`/teams/${teamId}/share/${shareKey}`, undefined, 'DELETE')
              passwordInputRef.current.value = ''
              yMeta.set('shareKey', null)
            }}
          >Delete Password</Button>
        ) : null}
      </div>
    </Dialog>
  )
}
