import * as Tooltip from '@radix-ui/react-tooltip'

const Title = ({ title, children }) => {
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <div className='inline-block'>{children}</div>
      </Tooltip.Trigger>
      <Tooltip.Portal style={{ zIndex: 999999 }}>
        <Tooltip.Content style={{ zIndex: 999999 }} forceMount asChild>
          <span className='bg-white rounded-sm text-xs py-0.5 px-1'>{title}</span>
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  )
}
export default Title
