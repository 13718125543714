import { poster } from "../utils";

export const DefaultOpenAiChatCompletionArgs = Object.freeze({
  model: "gpt-3.5-turbo",
  n: 1,
  max_tokens: 1024,
})

export const openai = {
  createChatCompletion: async (body) => {
    return poster(`/openai/chatCompletion`, {
      ...DefaultOpenAiChatCompletionArgs,
      ...body,
    })
  },
}