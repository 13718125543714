import { useCallback, useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'
import { fetcher } from '../utils'
const key = 'teams'
const TeamsStore = atom({
  key,
  default: JSON.parse(localStorage.getItem(key) || '[]'),
})

export function useTeams() {
  const [teams, _setTeams] = useRecoilState(TeamsStore)
  const setTeams = useCallback(
    (data) => {
      if (!data) return
      localStorage.setItem(key, JSON.stringify(data))
      _setTeams(data)
    },
    [_setTeams]
  )
  return {
    teams,
    setTeams,
    refetch: () =>
      fetcher('/teams')
        .then(({ teams }) => {
          setTeams(teams)
        })
        .catch((err) => {
          setTeams([])
        }),
  }
}

export function useTeamsInitializer() {
  const { setTeams } = useTeams()
  useEffect(() => {
    fetcher('/teams')
      .then(({ teams }) => {
        setTeams(teams)
      })
      .catch((err) => {
        setTeams([])
      })
  }, [setTeams])
}

export function useTeamPermission(teamId) {
  const { teams } = useTeams()
  return teams.find((team) => team.key === teamId)?.role ?? 0
}
