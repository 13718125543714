import { Button } from '@blueprintjs/core'
import { useEffect, useMemo } from 'react'
import toast from 'react-hot-toast'
import { FaGithubAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import SignInWithEthereum from '../components/SignInWithEthereum'
import { restApiUrl } from '../utils'

export default function Home() {
  const navigate = useNavigate()
  useEffect(() => {
    if (!localStorage.token || !localStorage.refreshToken) return
    navigate('/teams')
  }, [])
  return (
    <div
      style={{
        backgroundColor: '#2e2e2e',
      }}
      className='text-white mx-auto mt-28 py-4 px-10 rounded-sm font-mono'
    >
      <div className='font-extrabold text-2xl mb-2 text-center italic'>HackNote</div>
      <div className='font-bold text-sm mb-6 text-center'>Log in to your account</div>
      <div className='mb-4'>
        <SignInWithGithub />
      </div>
      <div className='mb-4'>
        <SignInWithEthereum
          onSuccess={({ token, refreshToken }) => {
            if (token && refreshToken) {
              localStorage.setItem('token', token)
              localStorage.setItem('refreshToken', refreshToken)
              window.location.reload()
            }
          }}
          onError={(e) => toast.error(e.message)}
        />
      </div>
    </div>
  )
}

export const SignInWithGithub = ({label='Continue with Github'}) => {
  const authUrl = useMemo(() => {
    const githubAuthApiUrlQueries = new URLSearchParams({
      d: `${window.location.origin}/teams`,
    })
    const githubAuthApiUrlQueriesString = githubAuthApiUrlQueries.toString()
    return `${restApiUrl}/auth/github?${githubAuthApiUrlQueriesString}`
  }, [])

  return (
    <a href={authUrl}>
      <Button small icon={<FaGithubAlt />}>{label}</Button>
    </a>
  )
}

