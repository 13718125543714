import { Button, Dialog, FormGroup, InputGroup, Radio, RadioGroup, Switch, Tag, TextArea } from '@blueprintjs/core'
import { Formik } from 'formik'
import { isEmpty, pick } from 'lodash'
import { useCallback, useState } from 'react'
import { AiFillCloseCircle, AiFillEdit } from 'react-icons/ai'
import { useQuery } from 'react-query'
import * as Y from 'yjs'
import * as Yup from 'yup'
import { Avatar } from '../../../components/Collaborators'
import { useProfile } from '../../../hooks/useProfile'
import { useTeamPermission } from '../../../hooks/useTeams'
import { DefaultOpenAiChatCompletionArgs } from '../../../module/openai'
import { DocumentProvider, useDoc, useMap } from '../../../module/y/hook'
import { fetcher, generateKey } from '../../../utils'
import { TeamMemberRole } from './settingsMembers'

const PromptTemplateSchema = Yup.object().shape({
  label: Yup.string().min(1).required(),
  prefix: Yup.string(),
  suffix: Yup.string(),
  insertTextRatherThanReplace: Yup.string().min(1),
  enableOpenaiSettings: Yup.boolean(),
  openai_model: Yup.string().required(),
  openai_role: Yup.mixed().oneOf(["user", "assistant", "system"]),
  openai_max_tokens: Yup.number().integer().min(1).max(4096),
  openai_temperature: Yup.number().min(0).max(2),
  openai_top_p: Yup.number().min(0).max(2),
  openai_n: Yup.number().integer().min(1).max(1000),
  openai_stream: Yup.boolean(),
  openai_presence_penalty: Yup.number().min(-2).max(2),
  openai_frequency_penalty: Yup.number().min(-2).max(2),
  openai_user: Yup.string(),
})

export default function TeamSettingsPromptTemplateMain({ teamId }) {
  const [editingPromptTemplate, setEditingPromptTemplate] = useState(null)
  const operatorRole = useTeamPermission(teamId)
  const { profile } = useProfile()

  const {
    data: members,
  } = useQuery(
    ['TeamMember', teamId],
    async () => {
      return await fetcher(`/teams/${teamId}/members`)
    },
    {
      enabled: !!teamId,
      staleTime: 1000 * 60 * 10,
    }
  )

  const yDoc = useDoc()
  const yPromptTemplatesSettings = useMap('EDITOR_PROMPT_TEMPLATES_SETTINGS')
  const yPromptTemplates = yPromptTemplatesSettings.get('templates')
  const templates = (yPromptTemplates?.toJSON() || []).sort((a, b) => {
    if (a.label < b.label) return -1
    else if (a.label > b.label) return 1
    else return 0
  })
  const createTemplate = useCallback(
    (values) => {
      if (!yPromptTemplates) return
      yDoc.transact(() => {
        yPromptTemplates.insert(0, [
          new Y.Map([
            ['key', generateKey()],
            ['label', values.label],
            ['prefix', values.prefix],
            ['suffix', values.suffix],
            ['insertTextRatherThanReplace', values.insertTextRatherThanReplace === undefined ? true : values.insertTextRatherThanReplace],
            ['createdBy', profile.id],
            ['enableOpenaiSettings', values.enableOpenaiSettings],
            ['openai_model', values.openai_model],
            ['openai_role', values.openai_role],
            ['openai_max_tokens', values.openai_max_tokens],
            ['openai_temperature', values.openai_temperature],
            ['openai_top_p', values.openai_top_p],
            ['openai_n', values.openai_n],
            ['openai_stream', values.openai_stream],
            ['openai_presence_penalty', values.openai_presence_penalty],
            ['openai_frequency_penalty', values.openai_frequency_penalty],
            ['openai_user', values.openai_user],
          ]),
        ])
        yPromptTemplatesSettings.set('version', generateKey())
      })
    },
    [yPromptTemplates, yDoc, yPromptTemplatesSettings]
  )

  const updateTemplate = useCallback(
    (key, values) => {
      if (!yPromptTemplates) return
      const index = yPromptTemplates.toJSON().findIndex((i) => i.key === key)
      if (index === -1) return
      yDoc.transact(() => {
        const yTemplate = yPromptTemplates.get(index)
        yTemplate.set('label', values.label)
        yTemplate.set('prefix', values.prefix)
        yTemplate.set('suffix', values.suffix)
        yTemplate.set(
          'insertTextRatherThanReplace',
          values.insertTextRatherThanReplace === undefined ? true : values.insertTextRatherThanReplace
        )
        yTemplate.set('enableOpenaiSettings', values.enableOpenaiSettings)
        yTemplate.set('openai_model', values.openai_model)
        yTemplate.set('openai_role', values.openai_role)
        yTemplate.set('openai_max_tokens', values.openai_max_tokens)
        yTemplate.set('openai_temperature', values.openai_temperature)
        yTemplate.set('openai_top_p', values.openai_top_p)
        yTemplate.set('openai_n', values.openai_n)
        yTemplate.set('openai_stream', values.openai_stream)
        yTemplate.set('openai_presence_penalty', values.openai_presence_penalty)
        yTemplate.set('openai_frequency_penalty', values.openai_frequency_penalty)
        yTemplate.set('openai_user', values.openai_user)
        yPromptTemplatesSettings.set('version', generateKey())
      })
    },
    [yPromptTemplates, yDoc, yPromptTemplatesSettings]
  )

  const deleteTemplate = useCallback(
    (key) => {
      if (!yPromptTemplates) return
      const index = yPromptTemplates.toJSON().findIndex((i) => i.key === key)
      if (index === -1) return
      yDoc.transact(() => {
        yPromptTemplates.delete(index)
        yPromptTemplatesSettings.set('version', generateKey())
      })
    },
    [yPromptTemplates, yDoc, yPromptTemplatesSettings]
  )

  return (
    <div>
      <Formik initialValues={{}} onSubmit={async (values) => {}} enableReinitialize>
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className='flex flex-col'>
                <FormGroup label='Prompt Templates'>
                  <Button
                    intent='primary'
                    className='w-full mb-2'
                    onClick={() => {
                      setEditingPromptTemplate({
                        readOnly: false,
                        isNew: true,
                        key: generateKey,
                      })
                    }}
                  >
                    Create New Template
                  </Button>
                  <div className='flex flex-col gap-2'>
                    {templates &&
                      templates.map((template) => {
                        const creator = members?.find((i) => i.id === template.createdBy)
                        const readOnly = !(operatorRole === TeamMemberRole.Owner || operatorRole === TeamMemberRole.Manager || template.createdBy === profile.id)
                        return (
                          <Tag key={template.key} minimal large>
                            <div className='flex flex-row items-center justify-between gap-1'>
                              <div className='flex flex-col'>
                                <div className='text-white'>{template.label}</div>
                                <div className='text-gray-400'>{template.documentation}</div>
                              </div>

                              <div className="flex flex-row itemce">
                                {creator && (
                                  <Avatar
                                    className='mr-1'
                                    title={creator.username}
                                    avatar={creator.avatar}
                                    size={18}
                                    color={creator.color}
                                    username={creator.username}
                                  />
                                )}
                                <Button
                                  minimal
                                  small
                                  onClick={() => {
                                    setEditingPromptTemplate({
                                      readOnly,
                                      isNew: false,
                                      ...template,
                                    })
                                  }}
                                >
                                  <AiFillEdit className='text-gray-300' />
                                </Button>
                                {!readOnly? (
                                  <Button
                                    minimal
                                    small
                                    onClick={() => {
                                      deleteTemplate(template.key)
                                    }}
                                  >
                                    <AiFillCloseCircle className='text-red-300' />
                                  </Button>
                                ) : null}
                              </div>
                            </div>
                          </Tag>
                        )
                      })}
                  </div>
                </FormGroup>
              </div>
            </form>
          )
        }}
      </Formik>
      {editingPromptTemplate && (
        <Dialog
          className='bp4-dark'
          title={editingPromptTemplate.isNew ? 'Create new template' : 'Edit template'}
          isOpen={true}
          onClose={() => {
            setEditingPromptTemplate(null)
          }}
          canOutsideClickClose={false}
        >
          <div className='m-4'>
            <TeamSettingsPromptTemplateItem
              readOnly={editingPromptTemplate.readOnly}
              initialValues={editingPromptTemplate}
              onSubmit={(values, isNew) => {
                if (isNew) {
                  createTemplate(values)
                } else {
                  updateTemplate(values.key, values)
                }
                setEditingPromptTemplate(null)
              }}
            />
          </div>
        </Dialog>
      )}
    </div>
  )
}

function TeamSettingsPromptTemplateItem({ readOnly, initialValues, onSubmit }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PromptTemplateSchema}
      onSubmit={(values) => {
        if (readOnly) return
        const submitValues = pick(values, ['key', 'label', 'prefix', 'suffix', 'insertTextRatherThanReplace', 'enableOpenaiSettings'])
        if (values['openai_model']) submitValues['openai_model'] = values['openai_model']
        if (values['openai_role']) submitValues['openai_role'] = values['openai_role']
        if (values['openai_max_tokens']) submitValues['openai_max_tokens'] = parseInt(values['openai_max_tokens'], 10)
        if (values['openai_temperature']) submitValues['openai_temperature'] = parseFloat(values['openai_temperature'])
        if (values['openai_top_p']) submitValues['openai_top_p'] = parseFloat(values['openai_top_p'])
        if (values['openai_n']) submitValues['openai_n'] = parseInt(values['openai_n'], 10)
        if (values['openai_stream']) submitValues['openai_stream'] = values['openai_stream']
        if (values['openai_presence_penalty']) submitValues['openai_presence_penalty'] = parseFloat(values['openai_presence_penalty'])
        if (values['openai_frequency_penalty']) submitValues['openai_frequency_penalty'] = parseFloat(values['openai_frequency_penalty'])
        if (values['openai_user']) submitValues['openai_user'] = values['openai_user']
        onSubmit(submitValues, initialValues.isNew)
      }}
      enableReinitialize
    >
      {({ values, handleChange, dirty, isSubmitting, handleSubmit, errors, setFieldValue, setValues }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col'>
              <FormGroup label='Label' labelFor='label' helperText='The label of this template item.'>
                <InputGroup
                  intent={errors['label'] ? 'danger' : undefined}
                  id='label'
                  name='label'
                  value={values.label || ''}
                  onChange={handleChange}
                />
              </FormGroup>
              {/* <FormGroup
                label="Accept Mode"
                labelFor="mode"
              >
                <Switch
                  id="insertTextRatherThanReplace"
                  label="Insert text rather than replace the selected text"
                  checked={values.insertTextRatherThanReplace === undefined ? true : values.insertTextRatherThanReplace}
                  onChange={(event) => {
                    console.log(event.target.checked)
                    setFieldValue('insertTextRatherThanReplace', event.target.checked)
                  }}
                />
              </FormGroup> */}
              <FormGroup label='Prefix' labelFor='prefix' helperText='Prepend to the selected text'>
                <TextArea
                  intent={errors['prefix'] ? 'danger' : undefined}
                  id='prefix'
                  name='prefix'
                  className='w-full'
                  value={values.prefix || ''}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup label='Suffix' labelFor='suffix' helperText='Append to the end of the selected text.'>
                <TextArea
                  intent={errors['suffix'] ? 'danger' : undefined}
                  id='suffix'
                  name='suffix'
                  className='w-full'
                  value={values.suffix || ''}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup
                labelFor='enableOpenaiSettings'
                helperText='Whether to customize OpenAI settings'
              >
                <Switch
                  id='enableOpenaiSettings'
                  label='OpenAI Settings'
                  checked={values.enableOpenaiSettings === undefined ? false : values.enableOpenaiSettings}
                  onChange={(event) => {
                    const newValues = Object.assign({}, values)
                    newValues.enableOpenaiSettings = event.target.checked
                    if (newValues.enableOpenaiSettings) {
                      for (const key in DefaultOpenAiChatCompletionArgs) {
                        const formKey = `openai_${key}`
                        if (!newValues[formKey]) {
                          newValues[formKey] = DefaultOpenAiChatCompletionArgs[key]
                        }
                      }
                      if (!newValues['openai_role']) {
                        newValues['openai_role'] = 'user'
                      }
                    }
                    setValues(newValues)
                  }}
                />
              </FormGroup>
              {values.enableOpenaiSettings && (
                <div className="ml-6">
                  <FormGroup
                    label='Model'
                    labelFor='openai_model'
                    helperText='ID of the model to use. You can see our [Model overview](https://platform.openai.com/docs/models/overview) for descriptions of them.'
                  >
                    <InputGroup
                      intent={errors['openai_model'] ? 'danger' : undefined}
                      id='openai_model'
                      name='openai_model'
                      value={values.openai_model || ''}
                      onChange={handleChange}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup
                    label='role'
                    labelFor='openai_role'
                    helperText='The role of the author of this message.'
                  >
                    <RadioGroup
                      name="openai_role"
                      selectedValue={values.openai_role || 'brush'}
                      onChange={handleChange}
                      inline
                    >
                      <Radio label="user" value="user" />
                      <Radio label="assistant" value="assistant" />
                      <Radio label="system" value="system" />
                    </RadioGroup>
                  </FormGroup>
                  <FormGroup
                    label='max_tokens'
                    labelFor='openai_max_tokens'
                    helperText='The maximum number of tokens allowed for the generated answer. By default, the number of tokens the model can return will be (4096 - prompt tokens).'
                  >
                    <InputGroup
                      intent={errors['openai_max_tokens'] ? 'danger' : undefined}
                      id='openai_max_tokens'
                      name='openai_max_tokens'
                      value={values.openai_max_tokens || '1024'}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup
                    label='temperature'
                    labelFor='openai_temperature'
                    helperText='What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.  We generally recommend altering this or `top_p` but not both.'
                  >
                    <InputGroup
                      intent={errors['openai_temperature'] ? 'danger' : undefined}
                      id='openai_temperature'
                      name='openai_temperature'
                      value={values.openai_temperature || ''}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup
                    label='top_p'
                    labelFor='openai_top_p'
                    helperText='An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered.  We generally recommend altering this or `temperature` but not both.'
                  >
                    <InputGroup
                      intent={errors['openai_top_p'] ? 'danger' : undefined}
                      id='openai_top_p'
                      name='openai_top_p'
                      value={values.openai_top_p || ''}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup
                    label='n'
                    labelFor='openai_n'
                    helperText='How many chat completion choices to generate for each input message.'
                  >
                    <InputGroup
                      intent={errors['openai_n'] ? 'danger' : undefined}
                      id='openai_n'
                      name='openai_n'
                      value={values.openai_n || ''}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup
                    labelFor='openai_stream'
                    helperText='If set, partial message deltas will be sent, like in ChatGPT. Tokens will be sent as data-only [server-sent events](https://developer.mozilla.org/en-US/docs/Web/API/Server-sent_events/Using_server-sent_events#Event_stream_format) as they become available, with the stream terminated by a `data: [DONE]` message.'
                  >
                    <Switch
                      id='openai_stream'
                      label='stream'
                      checked={values.openai_stream === undefined ? false : values.openai_stream}
                      onChange={(event) => {
                        setFieldValue('openai_stream', event.target.checked)
                      }}
                    />
                  </FormGroup>
                  <FormGroup
                    label='presence_penalty'
                    labelFor='openai_presence_penalty'
                    helperText={`Number between -2.0 and 2.0. Positive values penalize new tokens based on whether they appear in the text so far, increasing the model's likelihood to talk about new topics.`}
                  >
                    <InputGroup
                      intent={errors['openai_presence_penalty'] ? 'danger' : undefined}
                      id='openai_presence_penalty'
                      name='openai_presence_penalty'
                      value={values.openai_presence_penalty || ''}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup
                    label='frequency_penalty'
                    labelFor='openai_frequency_penalty'
                    helperText={`Number between -2.0 and 2.0. Positive values penalize new tokens based on their existing frequency in the text so far, decreasing the model\'s likelihood to repeat the same line verbatim.`}
                  >
                    <InputGroup
                      intent={errors['openai_frequency_penalty'] ? 'danger' : undefined}
                      id='openai_frequency_penalty'
                      name='openai_frequency_penalty'
                      value={values.openai_frequency_penalty || ''}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup
                    label='user'
                    labelFor='openai_user'
                    helperText='A unique identifier representing your end-user, which can help OpenAI to monitor and detect abuse.'
                  >
                    <InputGroup
                      intent={errors['openai_user'] ? 'danger' : undefined}
                      id='openai_user'
                      name='openai_user'
                      value={values.openai_user || ''}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </div>
              )}
              <Button intent='primary' type='submit' disabled={readOnly || !dirty || !isEmpty(errors) || isSubmitting}>
                {initialValues.isNew ? 'Create' : 'Update'}
              </Button>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}
