/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useRef, useState } from 'react'
import { useLongPress } from 'react-use'
import useResizeObserver from 'use-resize-observer/polyfilled'
import useURLQueries from '../hooks/useURLQueries'
import Pagination from './Pagination'

const List = ({
  loading,
  renderLoading,
  items,
  renderItem,
  renderHeader,
  renderEmpty,
  paginationProps,
  enablePagination,
  getKey,
  containerClassName,
  itemClassName,
  searchFn,
  urlSearchScope,
}) => {
  const [filters, updateFilters] = useURLQueries(urlSearchScope)
  const headerRef = useRef(null)
  const { ref: containerRef, height: containerHeight } = useResizeObserver()
  const itemSimulationRef = useRef(null)
  const itemHeight = useMemo(() => itemSimulationRef.current?.clientHeight, [itemSimulationRef.current?.clientHeight])
  const pageLimit = useMemo(() => {
    if (itemHeight == null || containerHeight == null) return null
    return Math.floor((containerHeight - (headerRef.current ? headerRef.current.clientHeight : 0)) / itemHeight)
  }, [itemHeight, containerHeight])
  const pageOffset = useMemo(() => {
    if (filters.offset != null) return filters.offset
    return 0
  }, [filters.offset])
  const showPagination = enablePagination && pageLimit && items.length > pageLimit
  const page = useMemo(() => Math.floor(pageOffset / pageLimit) , [pageOffset, pageLimit])

  // handle multiple select
  const [multiSelectMode, setMultiSelectMode] = useState(false)
  const [itemsSelected, setItemsSelected] = useState({})
  const longPressEvent = useLongPress(() => setMultiSelectMode((m) => !m), { delay: 500, isPreventDefault: true })
  const itemsToRender = useMemo(() => {
    const searched = filters.search ? items.filter(searchFn(filters.search)) : items
    return showPagination ? searched.slice(pageOffset, pageOffset + pageLimit) : searched
  }, [items, showPagination, filters.search, pageOffset, pageLimit])
  const onSearch = useCallback((keyword) => {
    updateFilters({ ...filters, offset:0, search: keyword })
  }, [])
  // Render
  if (loading) return renderLoading ? renderLoading() : null
  const empty = !loading && items.length === 0
  return (
    <ul ref={containerRef} {...longPressEvent} className={`w-full relative h-full text-lg ${containerClassName}`}>
      {/* Header */}
      {renderHeader && <li ref={headerRef}>{renderHeader({ items, setItemsSelected, onSearch, setMultiSelectMode, itemsSelected, multiSelectMode, filters })}</li>}
      {empty ? (
        renderEmpty ? (
          renderEmpty()
        ) : null
      ) : (
        <>
          {/* Item simulation for calculate item height */}
          <li ref={itemSimulationRef} className={`${itemClassName} invisible z-0 top-0 left-0 absolute`}>
            {renderItem(items[0], 0, {})}
          </li>
          {/* Items */}
          {itemsToRender.map((item, i) => (
            <li className={`truncate cursor-pointer ${itemClassName}`} key={getKey(item)}>
              {renderItem(item, i, { multiSelectMode, setItemsSelected, itemsSelected })}
            </li>
          ))}
          {/* Pagination */}
          {showPagination && <Pagination forcePage={page} limit={pageLimit} size={items.length} onChange={offset => {
            updateFilters({ ...filters, offset })
          }} {...paginationProps} />}
        </>
      )}
    </ul>
  )
}

export default List
