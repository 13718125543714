import {useState} from 'react'
const ProgressButton = ({ className, onClick, children }) => {
  const [loading, setLoading] = useState(false)
  const handleClick = async () => {
    setLoading(true)
    await onClick()
    setLoading(false)
  }
  return <div onClick={ handleClick } className={`${className} progress-btn ${loading ? 'active' : ''}`}>
    <div className="relative btn">
      {children}
    </div>
    <div className="text-gray-100 progress"></div>
  </div>
}

export default ProgressButton