import replaceAllBetween from './patched/unist-util-replace-all-between';
import { u } from 'unist-builder'
import {toHast} from 'mdast-util-to-hast'
import { visit } from 'unist-util-visit';

export function remarkDetails() {
  return (tree) => {
    visit(tree, 'paragraph', (node, index, parent) => {
      if (node.children.length < 1) return;
      if (node.children[0].type !== 'text') return;
      const child = node.children[0]

      const matched = /^:::details([ ]\{(open|close)\})?([ ](.*))?\n(.+)\n:::$/.exec(child.value)
      if (matched) {
        child.value = matched[5]
        parent.children[index] = u(
          'details',
          {
            summary: matched[4] || "",
            state: matched[2] || "close",
          },
          [child],
          ''
        )
      }
    })

    replaceAllBetween(tree, (node) => {
      if (node.type !== 'paragraph') return false
      if (node.children.length < 1) return false
      if (node.children[0].type !== 'text') return false

      const child = node.children[0]
      const matched = /^:::details([ ]\{(open|close)\})?([ ](.*))?(\n)?/.exec(child.value)
      if (matched) {
        node._state_ = matched[2] || "close"
        node._summary_ = matched[4] || ""
        return true
      }

      return false;
    }, (node) => {
      if (node.type !== 'paragraph') return false
      if (node.children.length < 1) return false
      if (node.children[0].type !== 'text') return false

      const child = node.children[0]
      const matched = /(\n)?:::$/.exec(child.value)
      if (matched) {
        return true
      }

      return false;
    }, (nodes) => {
      const firstNode = nodes[0]
      const lastNode = nodes[nodes.length-1]
      const restNodes = nodes.slice(1, nodes.length-1)

      firstNode.children[0].value = firstNode.children[0].value.replace(/^:::details([ ]\{(open|close)\})?([ ](.*))?(\n)?/, '')
      lastNode.children[0].value = lastNode.children[0].value.replace(/(\n)?:::$/, '')

      const newNodes = [
        ...(firstNode.children[0].value ? [firstNode] : []),
        ...restNodes,
        ...(lastNode.children[0].value ? [lastNode] : [])
      ]

      return [u(
        'details',
        {
          state: firstNode._state_,
          summary: firstNode._summary_,
        },
        newNodes,
        ''
      )]
    })
  }
}

export const remarkDetailsHandlers = {
  'details': remarkDetailsHandler,
}

function remarkDetailsHandler(h, node) {
  return h(node, 'details', {
    open: node.state === 'open',
  }, [
    h(node, 'summary', {}, [{ type: 'text', value: node.summary }]),
    ...node.children.map((i) => toHast(i)),
  ])
}
