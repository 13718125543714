import { Button, Drawer, HTMLTable, PanelStack2 } from "@blueprintjs/core";
import { uniq } from "lodash";
import { useMemo, useState } from "react";
import ContentEditable from 'react-contenteditable';
import { useQuery } from "react-query";
import Split from 'react-split';
import { useMap } from "react-use";
import { GenericErrorBoundary } from '../module/chaos/GenericErrorBoundary';
import { fetcher } from "../utils";
import Note from './note';
import YEditorReadonly from "./yeditorReadonly";

export default function CreateNoteFromTemplateDialog({
  isOpen, onClose, teamId, projectId,
  onCreate
}) {
  const initialPanel = {
    renderPanel: SelectNoteTemplateGroupPanel,
    title: "Select Template Group",
    props: {
      teamId,
      onCreate,
    },
  }
  const [panelStack, setPanelStack] = useState([initialPanel])

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      className="bp4-dark"
      style={{ transition: 'none', backgroundColor: "#333" }}
      title={
        <div className="flex flex-col ">
          <span className="text-sm">Create Note From Template</span>
        </div>
      }
      hasBackdrop={false}
      size={"100%"}
      usePortal={true}
    >
      <div className="w-full h-full">
        <PanelStack2
          className="h-full"
          stack={panelStack}
          onOpen={(panel) => {
            setPanelStack((data) => [...data, panel])
          }}
          onClose={() => {
            setPanelStack((data) => data.slice(0, -1))
          }}
          showPanelHeader={true}
        />
      </div>
    </Drawer>
  )
}

function SelectNoteTemplateGroupPanel({ teamId, onCreate, closePanel, openPanel }) {
  const { data: groups } = useQuery(['NoteTemplateGroups', teamId], async () => {
    return await fetcher(`/teams/${teamId}/noteTemplateGroups/list`)
  })
  return (
    <div style={{ height: '100%', overflowY: 'scroll' }}>
      {(groups || []).map((group) => {
        return (
          <li
            className='cursor-pointer truncate block w-full p-4 py-6 text-2xl font-bold'
            key={group.key}
            onClick={() => {
              openPanel({
                renderPanel: SelectNoteTemplatePanel,
                title: "Select Template",
                props: {
                  teamId,
                  noteTemplateGroupId: group.key,
                  onCreate,
                },
              })
            }}
          >
            {group.name}
          </li>
        )
      })}
    </div>
  )
}

function SelectNoteTemplatePanel({ teamId, noteTemplateGroupId, onCreate, closePanel, openPanel }) {
  const { data: noteTemplates } = useQuery(['NoteTemplate', teamId, noteTemplateGroupId], async () => {
    return await fetcher(`/teams/${teamId}/noteTemplateGroups/${noteTemplateGroupId}/noteTemplates`)
  })

  return (
    <div style={{ height: '100%', overflowY: 'scroll' }}>
      {(noteTemplates || []).map((template) => {
        return (
          <li
            className='cursor-pointer truncate block w-full p-4 py-6 text-2xl font-bold'
            key={template.key}
            onClick={() => {
              openPanel({
                renderPanel: CreateNoteFromTemplatePanel,
                title: template.title,
                props: {
                  selectedNoteTemplate: template,
                  onCreate,
                },
              })
            }}
          >
            {template.title}
          </li>
        )
      })}
    </div>
  )
}

function CreateNoteFromTemplatePanel({ selectedNoteTemplate, onCreate, closePanel, openPanel }) {
  const variables = useMemo(() => {
    if (!selectedNoteTemplate) return []
    if (!selectedNoteTemplate.content) return []
    const vars = []
    const regex = /{{(\w+)}}/g
    let match = null
    while ((match = regex.exec(selectedNoteTemplate.content)) != null) {
      vars.push(match[1])
    }
    return uniq(vars)
  }, [selectedNoteTemplate])
  const [variableValueMap, {set: setVariableValue, remove: removeVariableValue}] = useMap({});

  const varReplacedContent = useMemo(() => {
    if (!selectedNoteTemplate) return ''
    if (!selectedNoteTemplate.content) return ''
    return selectedNoteTemplate.content.replace(/{{(\w+)}}/g, (match, varName) => {
      return variableValueMap[varName] || match
    })
  }, [selectedNoteTemplate, variableValueMap])

  return (
    <div style={{ height: '100%', overflowY: 'scroll' }}>
      <div className="w-full" style={{ height: 'calc(100% - 40px)' }}>
        <Split className='split h-full flex-grow overflow-auto' sizes={[50, 50]} minSize={[100, 100]} expandToMin={true}>
          <div className='overflow-hidden'>
            <Split className='split h-full flex flex-col' sizes={[80, 20]} direction='vertical'>
              <div className='overflow-hidden w-full'>
                <GenericErrorBoundary>
                  <YEditorReadonly path={`/noteTemplate/${selectedNoteTemplate?.key}/readonly`} content={selectedNoteTemplate ? selectedNoteTemplate.content : ''} />
                </GenericErrorBoundary>
              </div>
              <div className='overflow-hidden w-full'>
                <GenericErrorBoundary>
                  <HTMLTable
                    bordered
                    condensed
                    className="w-full"
                  >
                    <thead>
                      <tr>
                        <th className="w-1/3">Variable</th>
                        <th className="w-2/3">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {variables.map((variable) => {
                        return (
                          <tr>
                            <td>{variable}</td>
                            <ContentEditable
                              tagName="td"
                              html={variableValueMap[variable] || ''}
                              onChange={(event) => {
                                if (event.target.value === '') {
                                  removeVariableValue(variable)
                                } else {
                                  setVariableValue(variable, event.target.value)
                                }
                              }}
                            />
                          </tr>
                        )
                      })}
                    </tbody>
                  </HTMLTable>
                </GenericErrorBoundary>
              </div>
            </Split>

          </div>
          <div className='overflow-auto h-full'>
            <div className='flex justify-center'>
              <GenericErrorBoundary>
                <Note noteContent={varReplacedContent || selectedNoteTemplate?.content} />
              </GenericErrorBoundary>
            </div>
          </div>
        </Split>
      </div>
      <div style={{ height: 40, padding: 5, paddingLeft: 20, borderTopWidth: 1, borderColor: '#414141' }} className="flex flex-row justify-start items-center gap-2 h-full">
        <Button onClick={() => {
          onCreate({
            content: varReplacedContent,
            template: selectedNoteTemplate.key,
          })
        }}>Create</Button>
      </div>
    </div>
  )
}