import { isArray } from 'lodash';
import { useMemo } from 'react';
import { FaUserFriends } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { fetcher } from '../utils';

export default function ContributorsBar({ teamId, contributors, onTransfer }) {
  const { data: members } = useQuery(['TeamMember', teamId], async () => {
    return await fetcher(`/teams/${teamId}/members`)
  }, {
    enabled: !!teamId,
    staleTime: 1000 * 60 * 10,
  })

  const contributorUsers = useMemo(() => {
    if (!contributors) return []
    if (!isArray(contributors)) return []
    if (!members) return []
    return contributors.map((i) => members.find((j) => i === j.id)).filter((i) => !!i)
  }, [contributors, members])

  return (
    <>
      <div title='contributors' className='flex flex-row items-center gap-1 p-1 text-white'>
        <FaUserFriends size={16} />
        <div className='flex items-center gap-1'>
          {contributorUsers && isArray(contributorUsers) && contributorUsers.length > 0 && contributorUsers.map((user) => {
            return (
              <div key={user.id}  title={user.username} className="w-4 h-4 rounded-full overflow-hidden">
                {user.avatar ? (
                  <img className="w-full h-full" src={user.avatar} alt="user avatar" />
                ) : (
                  <div className="w-full h-full bg-gray-600" />
                )}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
