import React, { useEffect, useMemo, useState } from 'react'
import { Checkbox, HTMLSelect } from '@blueprintjs/core'
import MonacoEditor, { DiffEditor } from '@monaco-editor/react'
import Split from 'react-split'
import { GenericErrorBoundary } from '../module/chaos/GenericErrorBoundary'
import { format } from 'date-fns'

export default function HistoryNoteEditorReadonly({ selectedHistoryNote, allHistoryNotes }) {
  const [diffEnabled, setDiffEnabled] = useState(true)
  const [diffNoteKey, setDiffNoteKey] = useState((allHistoryNotes && allHistoryNotes[1] && allHistoryNotes[1].key) || null)

  useEffect(() => {
    if (!selectedHistoryNote) return;
    if (!allHistoryNotes) return;
    const index = allHistoryNotes.findIndex(n => n.key === selectedHistoryNote.key)
    if (index >= 0 && index+1 < allHistoryNotes.length) {
      setDiffNoteKey(allHistoryNotes[index+1].key)
    }
  }, [selectedHistoryNote, allHistoryNotes])

  const allDiffNotes = useMemo(() => {
    return (allHistoryNotes || []).filter((i) => i.key !== selectedHistoryNote.key)
  }, [allHistoryNotes, selectedHistoryNote.key])
  const diffNote = useMemo(() => {
    if (!diffEnabled) return null;
    return allDiffNotes.find((i) => i.key === diffNoteKey) || null
  }, [diffEnabled, allDiffNotes, diffNoteKey])
  const ContentEditorComponent = useMemo(() => {
    return (diffEnabled && diffNote) ? DiffEditor : MonacoEditor
  }, [diffEnabled, diffNote])

  if (!selectedHistoryNote) return null
  return (
    <Split className='split h-full flex flex-col' sizes={[70, 30]} direction='vertical'>
      <div className='overflow-hidden w-full'>
        <GenericErrorBoundary>
          <ContentEditorComponent
            language='markdown'
            theme='vs-dark'
            height={allDiffNotes ? 'calc(100% - 40px)' : '100%'}
            width='100%'
            path={`${selectedHistoryNote.key}-content`}
            {...(diffNote ? {
              original: diffNote.content,
              modified: selectedHistoryNote.content,
            } : {
              defaultValue: selectedHistoryNote.content,
            })}
            options={{
              readOnly: true,
              wordWrap: 'on',
              automaticLayout: true,
              scrollBeyondLastLine: true,
              scrollBeyondLastColumn: 0,
              padding: {
                top: 18,
                bottom: 18,
              },
              minimap: {
                enabled: false,
              },
              renderSideBySide: false,
            }}
          />
        </GenericErrorBoundary>
        {allDiffNotes && (
          <div className="flex flex-row items-center mx-4" style={{ height: 40 }}>
            <Checkbox
              style={{ marginBottom: 0 }}
              label="Compare to"
              checked={diffEnabled}
              onChange={(e) => setDiffEnabled(e.target.checked)}
            />
            <HTMLSelect
              className="ml-2"
              disabled={!diffEnabled}
              value={diffNoteKey}
              onChange={(e) => setDiffNoteKey(e.target.value)}
            >
              <option value={null}>Choose a version.</option>
              {allDiffNotes.map((note) => {
                const time = format(new Date(note.lastModifiedAt), 'MMM d, h:mm bb')
                const label = note.isCurrent ? `${time} (Current)` : time;
                return (
                  <option value={note.key}>{label}</option>
                )
              })}
            </HTMLSelect>
          </div>
        )}
      </div>
      <div className='overflow-hidden w-full'>
        <GenericErrorBoundary>
          <MonacoEditor
            language='markdown'
            theme='vs-dark'
            height='100%'
            width='100%'
            path={`${selectedHistoryNote.key}-privateNote`}
            defaultValue={selectedHistoryNote.privateNote}
            options={{
              readOnly: true,
              wordWrap: 'on',
              automaticLayout: true,
              scrollBeyondLastLine: true,
              scrollBeyondLastColumn: 0,
              padding: {
                top: 18,
                bottom: 18,
              },
              minimap: {
                enabled: false,
              },
            }}
          />
        </GenericErrorBoundary>
      </div>
    </Split>
  )
}
