import { Button } from '@blueprintjs/core'
import React, { useCallback, useMemo, useRef } from 'react'
import toast from 'react-hot-toast';
import { HiTrash } from 'react-icons/hi';
import { pdfjs, Page, Document } from 'react-pdf';

export const FileDataUrlUploadButton = (props) => {

  const ref = useRef()
  const handleClick = useCallback(() => {
    ref.current.click()
  }, [])
  const dataType = useMemo(() => {
    if (!props.data) return null
    if (props.data.startsWith('data:application/pdf')) return 'pdf';
    if (props.data.startsWith('data:image/')) return 'image';
    return null;
  }, [props.data])

  return (
    <div>
      <div
        className="border border-gray-300 flex justify-center items-center mb-1"
        style={{
          width: props.previewWidth,
          height: props.previewHeight,
        }}
      >
        {dataType === 'pdf' && (
          <Document
            file={props.data}
          >
            <div className="my-2">
              <Page pageNumber={1} width={props.previewWidth} height={props.previewHeight} renderAnnotationLayer={false} />
            </div>
          </Document>
        )}
        {dataType === 'image' && (
          <img
            src={props.data}
            alt="upload preview"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        )}
      </div>
      <Button {...(props.buttonProps || {})} onClick={handleClick}>Upload new image</Button>
      <input
        style={{ display: 'none' }}
        ref={ref}
        type="file"
        accept={props.accept}
        onChange={async (event) => {
          if (!FileReader) return;
          if (!event.target || event.target.files.length !== 1) return;
          const file = event.target.files[0]
          if (!["image/jpeg", "image/png", "application/pdf"].includes(file.type)) {
            toast.error(`Unsupported file format: ${file.name}`)
            return;
          }
          const maxSize = props.maxSize || 1024
          if (file.size / 1024 > maxSize) {
            toast.error(`The file exceeds the maximum allowed size (${maxSize} KB): ${file.name}`)
            return;
          }
          const reader = new FileReader();
          reader.onload = () => {
            props.onUpload && props.onUpload(reader.result);
          }
          reader.readAsDataURL(file);
        }}
      />
    </div>
  )
}
