import { Button, Dialog, Spinner, Tag } from '@blueprintjs/core';
import { useMemo } from 'react';
import { useState } from 'react';
import { FaUserEdit } from 'react-icons/fa';
import { BsCheck } from 'react-icons/bs';
import { useQuery } from 'react-query';
import { fetcher } from '../utils';
import { useProfile } from '../hooks/useProfile';

export default function AuthorBar({ teamId, author, onTransfer }) {
  const [isOpen, setOpen] = useState(false)
  const { profile } = useProfile()

  const { data: members } = useQuery(['TeamMember', teamId], async () => {
    return await fetcher(`/teams/${teamId}/members`)
  }, {
    enabled: teamId && isOpen,
    staleTime: 1000 * 60 * 10,
  })

  const authorUser = useMemo(() => {
    if (!author) return null
    if (!members) return null
    return members.find((i) => i.id === author) || null
  }, [author, members])

  const canTransferAuthor = useMemo(() => {
    return !author || (profile && profile.id === author)
  }, [profile, author])

  return (
    <>
      <div title='author' className={`flex flex-row items-center gap-1 p-1 text-white ${canTransferAuthor ? 'cursor-pointer' : ''} `} onClick={() => { if (canTransferAuthor) setOpen(true) }}>
        <FaUserEdit size={15} />
        <div>
          {authorUser && (
            <div title={authorUser.username} className="mr-1 w-4 h-4 rounded-full overflow-hidden">
              {authorUser.avatar ? (
                <img className="w-full h-full" src={authorUser.avatar} alt="user avatar" />
              ) : (
                <div className="w-full h-full bg-gray-600" />
              )}
            </div>
          )}
        </div>
      </div>
      <AuthorTransferDialog
        currentAuthor={author}
        teamId={teamId}
        isOpen={isOpen}
        onClose={() => { setOpen(false) }}
        onTransfer={onTransfer}
      />
    </>
  )
}

function AuthorTransferDialog({ currentAuthor, teamId, isOpen, onClose, onTransfer }) {
  const [selectedMember, setSelectedMember] = useState(null)
  const { data: members, isLoading: isMembersLoading, error: membersError } = useQuery(['TeamMember', teamId], async () => {
    return await fetcher(`/teams/${teamId}/members`)
  }, {
    enabled: teamId && isOpen,
    staleTime: 1000 * 60 * 10,
  })

  return (
    <Dialog
      className="bp4-dark"
      title={"Transfer author to"}
      isOpen={isOpen}
      onClose={() => {
        setSelectedMember(null)
        onClose && onClose()
      }}
      canOutsideClickClose={true}
    >
      {isMembersLoading && (
        <div className="flex justify-center items-center" style={{ minHeight: 240 }}>
          <Spinner size={25} />
        </div>
      )}
      {!isMembersLoading && membersError && (
        <div className="flex justify-center items-center" style={{ minHeight: 240 }}>
          {membersError}
        </div>
      )}
      {!isMembersLoading && (
        <div className="flex flex-col" style={{ maxHeight: 200, overflow: 'scroll' }}>
          {members && members.map((member) => {
            if (member.id === currentAuthor) return null;
            const selected = selectedMember === member.id
            return (
              <div
                key={member.id}
                className={`px-4 py-2 ${selected ? 'bg-[#1a1f25]' : ''} hover:bg-gray-700 cursor-pointer flex flex-row items-center justify-between`}
                onClick={() => { setSelectedMember(member.id) }}
              >
                <div className="flex flex-row items-center gap-1">
                  <div className="mr-1 w-6 h-6 rounded-full overflow-hidden">
                    {member.avatar ? (
                      <img className="w-full h-full" src={member.avatar} alt="user avatar" />
                    ) : (
                      <div className="w-full h-full bg-gray-600" />
                    )}
                  </div>

                  <div className="flex flex-row items-baseline">
                    <div className="text-lg">{member.username}</div>
                    <div className="ml-1 text-xs text-gray-400">{member.id}</div>
                  </div>
                </div>
                <div>
                  {selected && <BsCheck />}
                </div>
              </div>
            )
          })}
        </div>
      )}
      <div class="mt-2 mx-4">
        <div class="bp4-dialog-footer-actions">
          <Button intent="primary" onClick={() => {
            onClose && onClose()
            selectedMember && onTransfer && onTransfer(selectedMember)
            setSelectedMember(null)
          }}>Transfer</Button>
        </div>
      </div>
    </Dialog>
  );
}