import { Drawer, Spinner, NonIdealState, Menu, MenuItem, Button, Popover, Alert } from "@blueprintjs/core";
import React, { useEffect, useState, useMemo } from 'react';
import Split from 'react-split';
import toast from 'react-hot-toast'
import useSWR from 'swr';
import { contentToTitle, fetcher, poster } from '../utils';
import { format } from 'date-fns';
import { GenericErrorBoundary } from '../module/chaos/GenericErrorBoundary'
import Note from './note'
import HistoryNoteEditorReadonly from './historyNoteEditorReadonly'
import { useLiveQuery } from "dexie-react-hooks";
import { db as localNoteHistoryDB } from '../module/localNoteHistory/db'

export default function HistoryNotes({isOpen, onClose, teamId, noteId, note, onRestored}) {
  const { mutate: reloadHistoryNotes, data: historyNotes, isValidating, error } = useSWR(isOpen ? `/teams/${teamId}/notes/${noteId}/historyNotes` : null, fetcher)
  const [selectedHistoryNote, setSelectedHistoryNote] = useState(null)

  const isLoading = !historyNotes && !error && isValidating
  const localHistoryNotes = (useLiveQuery(
    () => localNoteHistoryDB.histories.where({ note: noteId }).desc().toArray(),
    [noteId]
  ) ?? []).map(note => ({
      key: note.id,
      note: note.note,
      team: note.team,
      project: note.project,
      content: note.content,
      privateNote: note.privateNote,
      lastModifiedAt: +note.time,
      isCurrent: false,
      isLocal: true,
  }))
  const fakeCurrentHistoryNote = useMemo(() => {
    return {
      key: "current",
      note: note.key,
      team: note.team,
      project: note.project,
      content: note.content,
      privateNote: note.privateNote,
      lastModifiedAt: note.updatedAt || Date.now(),
      isCurrent: true,
    }
  }, [note])

  const allHistoryNotes = useMemo(() => {
    if (!historyNotes || historyNotes.length === 0) return []
    return [
      fakeCurrentHistoryNote,
      ...localHistoryNotes,
      ...historyNotes,
    ].filter((i) => !!i.lastModifiedAt)
  }, [fakeCurrentHistoryNote, historyNotes, localHistoryNotes])
  useEffect(() => {
    setSelectedHistoryNote(fakeCurrentHistoryNote)
  }, [fakeCurrentHistoryNote])

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      className="bp4-dark"
      style={{ transition: 'none', backgroundColor: "#333" }}
      title={
        <div className="flex flex-col ">
          <span className="text-sm">{contentToTitle(note.content)}</span>
        </div>
      }
      hasBackdrop={false}
      icon={"history"}
      size={"100%"}
      usePortal={true}
    >
      {isLoading && (
        <div className="flex flex-col items-center justify-center h-full">
          <NonIdealState
            icon={<Spinner/>}
            title={"Loading History Notes"}
          />
        </div>
      )}
      {!isLoading && (
        <div className="w-full h-full">
          {(!historyNotes || historyNotes.length === 0) ? (
            <NonIdealState
              icon="search"
              title={"No History Notes Found"}
            />
          ) : (
            <Split className='flex-grow h-full overflow-auto split' sizes={[40, 40, 20]} minSize={[400, 400, 300]} maxSize={[Infinity, Infinity, 300]} expandToMin={true}>
              <div className='overflow-hidden'>
                <GenericErrorBoundary>
                  {selectedHistoryNote && (
                    <HistoryNoteEditorReadonly
                      selectedHistoryNote={selectedHistoryNote}
                      allHistoryNotes={allHistoryNotes}
                    />
                  )}
                </GenericErrorBoundary>
              </div>
              <div className='h-full overflow-auto'>
                <div className='flex justify-center'>
                  <GenericErrorBoundary>
                    {selectedHistoryNote && selectedHistoryNote.content ? <Note noteContent={selectedHistoryNote ? selectedHistoryNote.content : ''} /> : null}
                  </GenericErrorBoundary>
                </div>
              </div>
              <div className="flex flex-col" style={{ width: 300, marginRight: 5 }}>
                <div className="m-4 text-xl">Version History</div>
                <ul className="h-full border-t notes-list" style={{ borderColor: 'rgba(255, 255, 255, 0.2)' }}>
                  {(!allHistoryNotes || allHistoryNotes.length <= 1) && (
                    <div className="flex items-center justify-center w-full h-full text-gray-400">
                      No Data
                    </div>
                  )}
                  {(allHistoryNotes || []).map((historyNote) => {
                    const isSelected = selectedHistoryNote && selectedHistoryNote.key === historyNote.key
                    return (
                      <HistoryNoteItem
                        key={historyNote.key}
                        historyNote={historyNote}
                        selectedHistoryNote={selectedHistoryNote}
                        isSelected={isSelected}
                        onSelect={() => setSelectedHistoryNote(historyNote)}
                        onRestored={(note) => {
                          reloadHistoryNotes()
                          onRestored(note)
                        }}
                      />
                    )
                  })}
                </ul>
              </div>
            </Split>
          )}
        </div>
      )}
    </Drawer>
  )
}

function HistoryNoteItem({ historyNote, selectedHistoryNote, onSelect, onRestored }) {
  const [restoreConfirmOpen, setRestoreConfirmOpen] = useState(false)
  const isSelected = historyNote && historyNote.key === selectedHistoryNote?.key
  const time = format(new Date(historyNote.lastModifiedAt), 'MMM d, h:mm bb')
  return (
    <li
      key={historyNote.key}
      className={`flex flex-col hover:bg border-b cursor-pointer`}
      style={{ borderColor: 'rgba(255, 255, 255, 0.2)' }}
      onClick={onSelect}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col mx-4 my-3">
          <div className={`font-medium mb-2 ${isSelected ? 'text-blue-500' : ''}`}>{time}</div>
          {historyNote.isCurrent ? (
            <div className={`${isSelected ? 'text-blue-500' : ''}`}>Current version</div>
          ) : (
            <div className="flex flex-row items-center">
              <span className="w-2 h-2 mr-2 rounded-full" style={{ marginTop: 2, backgroundColor: historyNote.user?.color || "orange" }}></span>
              <span>{historyNote.user?.username || historyNote.userId}</span>
              <span>{historyNote.isLocal ? 'Local' : ''}</span>
            </div>
          )}
        </div>
        {!historyNote.isCurrent && (
          <div className="mr-2" onClick={(event) => {
            event.stopPropagation();
          }}>
            <Popover
              style={{ backgroundColor: "#333" }}
              content={
                <Menu style={{ backgroundColor: "#333" }}>
                  <MenuItem
                    intent="danger"
                    icon="undo"
                    text="Restore this version"
                    onClick={() => {
                      setRestoreConfirmOpen(true)
                    }}
                  />
                </Menu>
              }
              placement="bottom-end"
            >
              <Button icon="more" minimal></Button>
            </Popover>

          </div>
        )}
      </div>
      <Alert
        className="bp4-dark"
        style={{ backgroundColor: "#333" }}
        cancel
        cancelButtonText="Cancel"
        confirmButtonText="Restore"
        icon="undo"
        intent="danger"
        isOpen={restoreConfirmOpen}
        onClose={() => { setRestoreConfirmOpen(false) }}
        onConfirm={async () => {
          setRestoreConfirmOpen(false)
          onRestored(historyNote.content, historyNote.privateNote)
        }}
      >
        <p><span className="font-bold">{contentToTitle(historyNote.content)}</span></p>
        <p>
          Your current note will restore to the version from <span className="font-bold text-red-400">{time}</span>.
        </p>
      </Alert>
    </li>
  )

}