import { Button, Callout, Dialog, FormGroup, InputGroup, Switch, Tag, TextArea } from '@blueprintjs/core'
import { Formik } from 'formik'
import { isEmpty, pick } from 'lodash'
import { useCallback, useState } from 'react'
import { AiFillCloseCircle, AiFillEdit } from 'react-icons/ai'
import { useQuery } from 'react-query'
import * as Y from 'yjs'
import * as Yup from 'yup'
import { Avatar } from '../../../components/Collaborators'
import { useProfile } from '../../../hooks/useProfile'
import { useTeamPermission } from '../../../hooks/useTeams'
import { useDoc, useMap } from '../../../module/y/hook'
import { fetcher, generateKey } from '../../../utils'
import { TeamMemberRole } from './settingsMembers'

const EditorCompletionSchema = Yup.object().shape({
  label: Yup.string().min(1).required(),
  detail: Yup.string(),
  documentation: Yup.string(),
  insertText: Yup.string().min(1).required(),
})

export default function TeamSettingsEditorCompletionMain({ teamId }) {
  const [editingCompletion, setEditingCompletion] = useState(null)
  const operatorRole = useTeamPermission(teamId)
  const { profile } = useProfile()

  const {
    data: members,
  } = useQuery(
    ['TeamMember', teamId],
    async () => {
      return await fetcher(`/teams/${teamId}/members`)
    },
    {
      enabled: !!teamId,
      staleTime: 1000 * 60 * 10,
    }
  )

  const yDoc = useDoc()
  const yEditorCompletionsSettings = useMap('EDITOR_COMPLETIONS_SETTINGS')
  const yCompletions = yEditorCompletionsSettings.get('completions')
  const completions = (yCompletions?.toJSON() || []).sort((a, b) => {
    if (a.label < b.label) return -1
    else if (a.label > b.label) return 1
    else return 0
  })
  const createCompletion = useCallback(
    (values) => {
      if (!yCompletions) return
      yDoc.transact(() => {
        yCompletions.insert(0, [
          new Y.Map([
            ['key', generateKey()],
            ['label', values.label],
            ['detail', values.detail || values.label],
            ['documentation', values.documentation || values.label],
            ['insertText', values.insertText],
            ['matchLabelOnlyAtStart', values.matchLabelOnlyAtStart === undefined ? true : values.matchLabelOnlyAtStart],
            ['createdBy', profile.id],
          ]),
        ])
        yEditorCompletionsSettings.set('version', generateKey())
      })
    },
    [yCompletions, yDoc, yEditorCompletionsSettings]
  )

  const updateCompletion = useCallback(
    (key, values) => {
      if (!yCompletions) return
      const index = yCompletions.toJSON().findIndex((i) => i.key === key)
      if (index === -1) return
      yDoc.transact(() => {
        const yCompletion = yCompletions.get(index)
        yCompletion.set('label', values.label)
        yCompletion.set('detail', values.detail)
        yCompletion.set('documentation', values.documentation)
        yCompletion.set('insertText', values.insertText)
        yCompletion.set('matchLabelOnlyAtStart', values.matchLabelOnlyAtStart === undefined ? true : values.matchLabelOnlyAtStart)
        yEditorCompletionsSettings.set('version', generateKey())
      })
    },
    [yCompletions, yDoc, yEditorCompletionsSettings]
  )

  const deleteCompletion = useCallback(
    (key) => {
      if (!yCompletions) return
      const index = yCompletions.toJSON().findIndex((i) => i.key === key)
      if (index === -1) return
      yDoc.transact(() => {
        yCompletions.delete(index)
        yEditorCompletionsSettings.set('version', generateKey())
      })
    },
    [yCompletions, yDoc, yEditorCompletionsSettings]
  )
  return (
    <div>
      <Formik initialValues={{}} onSubmit={async (values) => {}} enableReinitialize>
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className='flex flex-col'>
                <FormGroup label='Auto Completions'>
                  <Button
                    intent='primary'
                    className='w-full mb-2'
                    onClick={() => {
                      setEditingCompletion({
                        readOnly: false,
                        isNew: true,
                        key: generateKey,
                      })
                    }}
                  >
                    Create New Completion
                  </Button>
                  <div className='flex flex-col gap-2'>
                    {completions &&
                      completions.map((completion) => {
                        const creator = members?.find((i) => i.id === completion.createdBy)
                        const readOnly = !(operatorRole === TeamMemberRole.Owner || operatorRole === TeamMemberRole.Manager || completion.createdBy === profile.id)
                        return (
                          <Tag key={completion.key} minimal large>
                            <div className='flex flex-row items-center justify-between gap-1'>
                              <div className='flex flex-col'>
                                <div className='text-white'>{completion.label}</div>
                                <div className='text-gray-400'>{completion.documentation}</div>
                              </div>
                              <div className="flex flex-row itemce">
                                {creator && (
                                  <Avatar
                                    className='mr-1'
                                    title={creator.username}
                                    avatar={creator.avatar}
                                    size={18}
                                    color={creator.color}
                                    username={creator.username}
                                  />
                                )}
                                <Button
                                  minimal
                                  small
                                  onClick={() => {
                                    setEditingCompletion({
                                      isNew: false,
                                      readOnly,
                                      ...completion,
                                    })
                                  }}
                                >
                                  <AiFillEdit className='text-gray-300' />
                                </Button>
                                {!readOnly ? (
                                  <Button
                                    minimal
                                    small
                                    onClick={() => {
                                      deleteCompletion(completion.key)
                                    }}
                                  >
                                    <AiFillCloseCircle className='text-red-300' />
                                  </Button>
                                ) : null}
                              </div>
                            </div>
                          </Tag>
                        )
                      })}
                  </div>
                </FormGroup>
              </div>
            </form>
          )
        }}
      </Formik>
      {editingCompletion && (
        <Dialog
          className='bp4-dark'
          title={editingCompletion.isNew ? 'Create new completion' : 'Edit completion'}
          isOpen={true}
          onClose={() => {
            setEditingCompletion(null)
          }}
          canOutsideClickClose={false}
        >
          <div className='m-4'>
            <TeamSettingsEditorCompletionItem
              readOnly={editingCompletion.readOnly}
              initialValues={editingCompletion}
              onSubmit={(values, isNew) => {
                if (isNew) {
                  createCompletion(values)
                } else {
                  updateCompletion(values.key, values)
                }
                setEditingCompletion(null)
              }}
            />
          </div>
        </Dialog>
      )}
    </div>
  )
}

function TeamSettingsEditorCompletionItem({ initialValues, onSubmit, readOnly }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditorCompletionSchema}
      onSubmit={(values) => {
        if (readOnly) return
        onSubmit(pick(values, ['key', 'label', 'detail', 'documentation', 'insertText', 'matchLabelOnlyAtStart']), initialValues.isNew)
      }}
      enableReinitialize
    >
      {({ values, handleChange, dirty, isSubmitting, handleSubmit, errors, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col'>
              <FormGroup
                label='Label'
                labelFor='label'
                helperText='The label of this completion item. By default this is also the text that is inserted when selecting this completion.'
              >
                <InputGroup
                  intent={errors['label'] ? 'danger' : undefined}
                  id='label'
                  name='label'
                  value={values.label || ''}
                  onChange={handleChange}
                />
                <Switch
                  id='matchLabelOnlyAtStart'
                  label='Match label only at start of the line'
                  checked={values.matchLabelOnlyAtStart === undefined ? true : values.matchLabelOnlyAtStart}
                  onChange={(event) => {
                    setFieldValue('matchLabelOnlyAtStart', event.target.checked)
                  }}
                />
              </FormGroup>
              <FormGroup
                label='Detail'
                labelFor='detail'
                helperText='A human-readable string with additional information about this item, like type or symbol information.'
              >
                <InputGroup
                  intent={errors['detail'] ? 'danger' : undefined}
                  id='detail'
                  name='detail'
                  value={values.detail || ''}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup label='Documentation' labelFor='documentation' helperText='A human-readable string that represents a doc-comment.'>
                <TextArea
                  intent={errors['documentation'] ? 'danger' : undefined}
                  id='documentation'
                  name='documentation'
                  className='w-full'
                  value={values.documentation || ''}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup
                label='Insert Text'
                labelFor='insertText'
                helperText={
                  <Callout intent='primary' className='mt-2'>
                    <div>{'A string or snippet that should be inserted in a document when selecting this completion.'}</div>
                    <div>
                      You can use format like this <span className='font-medium'>{'${1:area_name}'}</span> to define a area to quick
                      replace. {'('}
                      <span className='font-medium'>1</span> is area no., <span className='font-medium'>area_name</span> is area name.{')'}
                    </div>
                  </Callout>
                }
              >
                <TextArea
                  intent={errors['insertText'] ? 'danger' : undefined}
                  id='insertText'
                  name='insertText'
                  className='w-full'
                  style={{ minHeight: 80 }}
                  value={values.insertText || ''}
                  onChange={handleChange}
                />
              </FormGroup>
              <Button intent='primary' type='submit' disabled={readOnly || !dirty || !isEmpty(errors) || isSubmitting}>
                {initialValues.isNew ? 'Create' : 'Update'}
              </Button>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}
