import { Menu, MenuItem, NonIdealState, Spinner } from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"
import { ContextMenuItem } from '@radix-ui/react-context-menu'
import { useCallback, useState } from "react"
import toast from "react-hot-toast"
import { BiRecycle, BiSelectMultiple } from "react-icons/bi"
import { BsPin, BsPlusLg } from "react-icons/bs"
import { TbBoxMultiple } from "react-icons/tb"
import { Link, useNavigate } from "react-router-dom"
import * as Y from 'yjs'
import { ContextMenuBox } from '../../../components/contextMenu'
import List from "../../../components/List"
import ProgressButton from "../../../components/ProgressButton"
import SearchBox from "../../../components/SearchBox"
import { generateKeyBetween, generateNKeysBetween } from "../../../module/fractional-indexing"
import { getSortedArray, useArray, useDoc, useWsSynced } from "../../../module/y/hook"
import { generateKey, poster } from "../../../utils"

export default function ProjectList({ teamId }) {
  const navigate = useNavigate()
  const yTeamDoc = useDoc()
  const yProjects = useArray('PROJECTS')
  const docWsSynced = useWsSynced()
  const archivedYProjects = yProjects.toArray().filter((p) => p.get('archived'))
  const sortedYProjects = getSortedArray(yProjects, (p) => !p.get('archived'))
  const sortedProjects = sortedYProjects.map((p, i) => ({
    ...p.toJSON(),
    sortedIndex: i,
  }))
  const pinedProjects = [...sortedProjects].sort((a, b) => (b.pin ?? 0) - (a.pin ?? 0))

  const [isArchivedMode, setIsArchivedMode] = useState(false)

  const onCreateProject = useCallback(async () => {
    const key = generateKey()
    const title = window.prompt('Enter project title')
    if (!title) return
    try {
      await poster(`/teams/${teamId}/projects`, { key, title })
      const firstPos = sortedProjects[0]?.pos ?? null
      const yp = new Y.Map()
      yTeamDoc.transact(() => {
        yp.set('key', key)
        yp.set('title', title)
        yp.set('pos', generateKeyBetween(null, firstPos))
        yProjects.insert(0, [yp])
      })
      navigate(`/teams/${teamId}/projects/${key}/edit`)
    } catch (err) {
      toast('failed to create new project')
      return
    }
  }, [sortedProjects])

  return <List
    urlSearchScope='project'
    items={isArchivedMode ? archivedYProjects.map((p) => p.toJSON()) : pinedProjects}
    containerClassName='w-full h-full text-lg group'
    itemClassName='truncate cursor-pointer'
    renderHeader={({ onSearch, setMultiSelectMode, multiSelectMode, itemsSelected, setItemsSelected, filters }) => {
      const selectedCount = itemsSelected ? Object.keys(itemsSelected).length : 0
      return (
        <div className='flex items-center justify-between text-white'>
          <ProgressButton className='pl-4 hover:text-blue-500' onClick={onCreateProject}>
            <div className='flex items-center'>
              <BsPlusLg />
              <span className='py-5 ml-3 text-xl font-bold '>New Project</span>
            </div>
          </ProgressButton>
          <div
            className={`items-center justify-end flex-1 ${isArchivedMode || multiSelectMode ? 'flex' : 'hidden group-hover:flex'
              } pr-4`}
          >
            <SearchBox keyword={filters.search} onSearch={onSearch} />
            {multiSelectMode ? (
              <Popover2
                content={
                  <Menu>
                    {selectedCount > 0 && (
                      <>
                        {!isArchivedMode && (
                          <MenuItem
                            text='Move selected'
                            onClick={() => {
                              const to = window.prompt(`Move ${selectedCount} items after`)
                              const leftIndex = sortedYProjects[to - 1].get('pos')
                              const rightIndex = sortedYProjects[to].get('pos')
                              yProjects.doc.transact(() => {
                                const newPos = generateNKeysBetween(leftIndex, rightIndex, selectedCount)
                                Object.keys(itemsSelected).forEach((key, i) => {
                                  yProjects
                                    .toArray()
                                    .find((p) => p.get('key') === key)
                                    .set('pos', newPos[i])
                                })
                              })
                              setItemsSelected({})
                              setMultiSelectMode(false)
                              yProjects.forceUpdate()
                            }}
                          />
                        )}
                        <MenuItem
                          intent={isArchivedMode ? 'danger' : 'none'}
                          text={isArchivedMode ? 'Delete selected' : 'Archive selected'}
                          onClick={() => {
                            if (isArchivedMode && !window.confirm('Are you sure to delete selected projects?')) return
                            yProjects.doc.transact(() => {
                              Object.keys(itemsSelected).forEach((key) => {
                                const projectIndex = yProjects.toArray().findIndex((p) => p.get('key') === key)
                                if (projectIndex === -1) return
                                isArchivedMode
                                  ? yProjects.delete(projectIndex, 1)
                                  : yProjects.get(projectIndex).set('archived', true)
                              })
                            })
                            setItemsSelected({})
                            setMultiSelectMode(false)
                            yProjects.forceUpdate()
                          }}
                        />
                      </>
                    )}
                    {isArchivedMode && selectedCount !== archivedYProjects?.length && (
                      <MenuItem
                        text='Select all'
                        onClick={() => {
                          const newItemsSelected = {}
                          archivedYProjects.forEach((p) => {
                            newItemsSelected[p.get('key')] = true
                          })
                          setItemsSelected(newItemsSelected)
                          yProjects.forceUpdate()
                        }}
                      />
                    )}
                    <MenuItem
                      text='Exit multi-select'
                      onClick={() => {
                        setMultiSelectMode(false)
                        setItemsSelected({})
                      }}
                    />
                  </Menu>
                }
                position='bottom-right'
                minimal
                popoverClassName='bp4-dark'
              >
                <div className={`relative ml-4 cursor-pointer text-blue-500 -top-0.5 scale-110 origin-center left-0.5`}>
                  <span className='left-[7px] top-[-0.5px] absolute font-mono text-xs font-extrabold text-blue-300 origin-center scale-75'>
                    {selectedCount}
                  </span>
                  <TbBoxMultiple />
                </div>
              </Popover2>
            ) : (
              <BiSelectMultiple
                className='ml-4 cursor-pointer hover:text-blue-500'
                onClick={() => {
                  setItemsSelected({})
                  setMultiSelectMode((m) => !m)
                }}
              />
            )}
            <BiRecycle
              onClick={() => {
                setItemsSelected({})
                setMultiSelectMode(false)
                setIsArchivedMode((a) => !a)
              }}
              className={`ml-4 cursor-pointer ${isArchivedMode ? 'text-blue-400' : 'hover:text-blue-400'}`}
            />
          </div>
        </div>
      )
    }}
    loading={!docWsSynced}
    enablePagination
    renderLoading={() => (
      <div className='flex flex-col items-center justify-center h-full'>
        <NonIdealState icon={<Spinner />} title={'Loading projects'} />
      </div>
    )}
    renderEmpty={() => <div className='mt-10 text-sm italic text-center text-gray-600 text-extralight'>Nothing Here</div>}
    paginationProps={{
      className: 'absolute bottom-2 right-2 dashboard-list-pagination group-hover:block hidden',
    }}
    getKey={(project) => project.key}
    searchFn={(keyword) => (project) => project.title.toLowerCase().includes(keyword.toLowerCase())}
    renderItem={(project, i, { multiSelectMode, setItemsSelected, itemsSelected }) => {
      const selected = itemsSelected && itemsSelected[project.key]
      return (
        <ContextMenuBox
          menu={
            <>
              <ContextMenuItem
                onClick={() => {
                  const yp = yProjects.get(project.index)
                  yp.set('pin', project.pin ? null : Date.now())
                }}
              >
                <div className='px-2 py-1 cursor-pointer'>{project.pin ? 'Un Pin' : 'Pin'}</div>
              </ContextMenuItem>
              <ContextMenuItem
                onClick={() => {
                  const yp = yProjects.get(project.index)
                  yp.set('archived', true)
                }}
              >
                <div className='px-2 py-1 cursor-pointer'>{project.archived ? 'Un Archived' : 'Archived'}</div>
              </ContextMenuItem>
            </>
          }
        >
          <Link to={`/teams/${teamId}/projects/${project.key}/edit`}>
            <label
              htmlFor={`select-${project.key}`}
              className='flex items-center justify-between w-full px-4 py-5 text-xl font-bold text-white cursor-pointer hover:text-blue-500 hover:underline'
              {...multiSelectMode ? { onClick: (e) => e.stopPropagation() } : {}}
            >
              <div className='flex flex-1'>
                {multiSelectMode ? (
                  <div className='relative flex items-center flex-1'>
                    {!isArchivedMode && (
                      <span className='absolute left-0 w-6 text-center text-gray-300 origin-top-left transform scale-50 font-extralight -top-3'>
                        {project.sortedIndex + 1}
                      </span>
                    )}
                    <input
                      id={`select-${project.key}`}
                      type='checkbox'
                      checked={selected}
                      value={selected}
                      onChange={(e) => {
                        const checked = e.target.checked
                        if (selected === checked) {
                          return
                        }
                        setItemsSelected((selected) => {
                          const { [project.key]: _, ...others } = selected
                          return checked
                            ? {
                              ...others,
                              [project.key]: true,
                            }
                            : others
                        })
                      }}
                    />
                    <span className={`ml-2 ${project.archived ? 'line-through' : ''}`}>{project.title}</span>
                  </div>
                ) : (
                  <span className={`${project.archived ? 'line-through' : ''}`}>{project.title}</span>
                )}
              </div>
              {project.pin && !project.archived ? <BsPin className='text-sm' /> : null}
            </label>
          </Link>
        </ContextMenuBox>
      )
    }}
  />

}