import React, { useEffect, useMemo, useState } from 'react'
import { Checkbox, HTMLSelect } from '@blueprintjs/core'
import MonacoEditor, { DiffEditor } from '@monaco-editor/react'
import Split from 'react-split'
import { GenericErrorBoundary } from '../module/chaos/GenericErrorBoundary'
import { format } from 'date-fns'

export default function HistoryDocumentEditorReadonly({ selectedHistoryDocument, allHistoryDocuments }) {
  const [diffEnabled, setDiffEnabled] = useState(true)
  const [diffDocumentKey, setDiffDocumentKey] = useState((allHistoryDocuments && allHistoryDocuments[1] && allHistoryDocuments[1].key) || null)

  useEffect(() => {
    if (!selectedHistoryDocument) return;
    if (!allHistoryDocuments) return;
    const index = allHistoryDocuments.findIndex(n => n.key === selectedHistoryDocument.key)
    if (index >= 0 && index+1 < allHistoryDocuments.length) {
      setDiffDocumentKey(allHistoryDocuments[index+1].key)
    }
  }, [selectedHistoryDocument, allHistoryDocuments])

  const allDiffDocuments = useMemo(() => {
    return (allHistoryDocuments || []).filter((i) => i.key !== selectedHistoryDocument.key)
  }, [allHistoryDocuments, selectedHistoryDocument.key])
  const diffDocument = useMemo(() => {
    if (!diffEnabled) return null;
    return allDiffDocuments.find((i) => i.key === diffDocumentKey) || null
  }, [diffEnabled, allDiffDocuments, diffDocumentKey])
  const ContentEditorComponent = useMemo(() => {
    return (diffEnabled && diffDocument) ? DiffEditor : MonacoEditor
  }, [diffEnabled, diffDocument])

  if (!selectedHistoryDocument) return null
  return (
    <Split className='split h-full flex flex-col' sizes={[100]} direction='vertical'>
      <div className='overflow-hidden w-full'>
        <GenericErrorBoundary>
          <ContentEditorComponent
            language='markdown'
            theme='vs-dark'
            height={allDiffDocuments ? 'calc(100% - 40px)' : '100%'}
            width='100%'
            path={`${selectedHistoryDocument.key}-content`}
            {...(diffDocument ? {
              original: diffDocument.content,
              modified: selectedHistoryDocument.content,
            } : {
              defaultValue: selectedHistoryDocument.content,
            })}
            options={{
              readOnly: true,
              wordWrap: 'on',
              automaticLayout: true,
              scrollBeyondLastLine: true,
              scrollBeyondLastColumn: 0,
              padding: {
                top: 18,
                bottom: 18,
              },
              minimap: {
                enabled: false,
              },
              renderSideBySide: false,
            }}
          />
        </GenericErrorBoundary>
        {allDiffDocuments && (
          <div className="flex flex-row items-center mx-4" style={{ height: 40 }}>
            <Checkbox
              style={{ marginBottom: 0 }}
              label="Compare to"
              checked={diffEnabled}
              onChange={(e) => setDiffEnabled(e.target.checked)}
            />
            <HTMLSelect
              className="ml-2"
              disabled={!diffEnabled}
              value={diffDocumentKey}
              onChange={(e) => setDiffDocumentKey(e.target.value)}
            >
              <option value={null}>Choose a version.</option>
              {allDiffDocuments.map((doc) => {
                const time = format(new Date(doc.lastModifiedAt), 'MMM d, h:mm bb')
                const label = doc.isCurrent ? `${time} (Current)` : time;
                return (
                  <option value={doc.key}>{label}</option>
                )
              })}
            </HTMLSelect>
          </div>
        )}
      </div>
    </Split>
  )
}
