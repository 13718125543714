import React from 'react';
import { styled } from '@stitches/react';

import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';

const StyledContent = styled(ContextMenuPrimitive.Content, {
  minWidth: 120,
  backgroundColor: 'white',
  borderRadius: 6,
  overflow: 'hidden',
  padding: '3px 0',
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
});

const itemStyles = {
  all: 'unset',
  fontSize: 13,
  lineHeight: 1,
  color: "#333",
  borderRadius: 3,
  display: 'flex',
  alignItems: 'center',
  minHeight: 25,
  position: 'relative',
  padding: '2px 12px',
  cursor: 'pointer',
  margin: '3px 5px',
  userSelect: 'none',

  '&[data-disabled]': {
    color: "#333",
    pointerEvents: 'none',
  },

  '&:focus': {
    backgroundColor: "#18A4F6",
    color: "#fff !important",
  },

  '&:focus > span': {
    color: "#fff !important",
  },
};

const StyledItem = styled(ContextMenuPrimitive.Item, { ...itemStyles });
const StyledCheckboxItem = styled(ContextMenuPrimitive.CheckboxItem, { ...itemStyles });
const StyledRadioItem = styled(ContextMenuPrimitive.RadioItem, { ...itemStyles });
const StyledTriggerItem = styled(ContextMenuPrimitive.TriggerItem, {
  '&[data-state="open"]': {
    backgroundColor: "#18A4F6",
    color: "#fff !important",
  },
  ...itemStyles,
});

const StyledLabel = styled(ContextMenuPrimitive.Label, {
  paddingLeft: 25,
  fontSize: 12,
  lineHeight: '25px',
  color: "#eee",
});

const StyledSeparator = styled(ContextMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: "#ddd",
  margin: '5px 0',
});

const StyledItemIndicator = styled(ContextMenuPrimitive.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// Exports
export const ContextMenu = ContextMenuPrimitive.Root;
export const ContextMenuTrigger = ContextMenuPrimitive.Trigger;
export const ContextMenuContent = StyledContent;
export const ContextMenuItem = StyledItem;
export const ContextMenuCheckboxItem = StyledCheckboxItem;
export const ContextMenuRadioGroup = ContextMenuPrimitive.RadioGroup;
export const ContextMenuRadioItem = StyledRadioItem;
export const ContextMenuItemIndicator = StyledItemIndicator;
export const ContextMenuTriggerItem = StyledTriggerItem;
export const ContextMenuLabel = StyledLabel;
export const ContextMenuSeparator = StyledSeparator;

// Your app...
const Box = styled('div', {});

export const RightSlot = styled('div', {
  marginLeft: 'auto',
  paddingLeft: 20,
  color: "#eee",
  ':focus > &': { color: 'white' },
  '[data-disabled] &': { color: "#ddd" },
});

export const ContextMenuBox = (props) => {
  return (
    <Box>
      <ContextMenu>
        <ContextMenuTrigger>
          {props.children}
        </ContextMenuTrigger>
        <ContextMenuContent sideOffset={5} align="end">
          {props.menu}
        </ContextMenuContent>
      </ContextMenu>
    </Box>
  );
};
