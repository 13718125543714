/* eslint-disable no-template-curly-in-string */

export function registerMonacoCompletionDetails(monaco) {
  return monaco.languages.registerCompletionItemProvider('markdown', {
    triggerCharacters: [':'],
    provideCompletionItems: (model, position) => {
      const range = {
        startLineNumber: position.lineNumber,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column
      }
      const text = model.getValueInRange(range).trim();

      const prefix = ":::details"
      const matched = prefix.startsWith(text.toLowerCase())

      if (!matched) return { suggestions: [] }
      return {
        suggestions: [{
          label: ':::details',
          kind: monaco.languages.CompletionItemKind.Function,
          detail: 'details block',
          documentation: 'HackNote extended markdown syntax, details block',
          insertText: ':::details ${1:summary_text}\n${2:details_text}\n:::\n',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          range: range
        }]
      }
    },
  });
}