import React, {useCallback, useMemo, useState} from "react";
import { pdfjs, Page, Document } from 'react-pdf';
import { Button } from "@blueprintjs/core";
import axios from 'axios';
import useResizeObserver from "use-resize-observer/polyfilled";
import { GrDocumentZip } from "react-icons/gr";
import { AiFillFileMarkdown, AiFillFilePdf } from "react-icons/ai";
import toast from 'react-hot-toast';
import {unified} from 'unified';
import remarkParser from 'remark-parse';
import remarkFrontmatter from 'remark-frontmatter'
import remarkGfm from 'remark-gfm'
import _, { range } from 'lodash';
import yaml from 'js-yaml';
import toml from 'toml';
import { restApiUrl } from "../utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function PDF({projectTitle, projectId, teamId, noteId, note}) {
  const [numPages, setNumPages] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [compilePdfLoading, setCompilePdfLoading] = useState(false);
  const [downloadSourcePackLoading, setDownloadSourcePackLoading] = useState(false);

  const metadata = useMemo(() => {
    try {
      return parseMetadata(note)
    } catch (error) {
      console.error(error)
      return {}
    }
  }, [note])
  const isIssueNote = useMemo(() => {
    return !!noteId && (!metadata._type_ || metadata._type_ === "issue")
  }, [noteId, metadata])


  const { ref, width = 1 } = useResizeObserver();

  const loadPdf = useCallback(async () => {
    const url = isIssueNote
      ? `${restApiUrl}/teams/${teamId}/projects/${projectId}/notes/${noteId}/pdf`
      : `${restApiUrl}/teams/${teamId}/projects/${projectId}/pdf`
    const { data } = await axios.get(url, {
      responseType: 'arraybuffer',
      headers: {
        'Authorization': `Bearer ${localStorage.token}`
      }
    });
    return data
  }, [projectId, teamId, noteId, isIssueNote])

  const loadSourcePack = useCallback(async () => {
    const { data } = await axios.get(`${restApiUrl}/teams/${teamId}/projects/${projectId}/tex`, {
      responseType: 'arraybuffer',
      headers: {
        'Authorization': `Bearer ${localStorage.token}`
      }
    });
    return data
  }, [projectId, teamId])

  const loadMd = useCallback(async () => {
    const { data } = await axios.get(`${restApiUrl}/teams/${teamId}/projects/${projectId}/md`, {
      responseType: 'arraybuffer',
      headers: {
        'Authorization': `Bearer ${localStorage.token}`
      }
    });
    return data
  }, [projectId, teamId])

  const compile = useCallback(async () => {
    try {
      const data = await loadPdf();
      setPdf(data)
    } catch (error) {
      toast.error(error.message)
    }
  }, [loadPdf])
  const downloadSourcePack = useCallback(async () => {
    setDownloadSourcePackLoading(true)
    try {
      const data = await loadSourcePack();
      const blob = new Blob([data], {type: "application/zip"});

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${(projectTitle || "UnknownProject").replaceAll(" ", "_").concat("_LaTeX_Source_Pack")}.zip`;
      link.click();
    } catch (error) {
      toast.error(error.message)
    } finally {
      setDownloadSourcePackLoading(false)
    }
  }, [loadSourcePack, projectTitle])

  const downloadPdf = useCallback(async () => {
    try {
      if (!pdf) return;

      const blob = new Blob([pdf], {type: "application/pdf"});

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${(projectTitle || "UnknownProject").replaceAll(" ", "_").concat("_Audit_Report_by_WatchPug")}.pdf`
      link.click();
    } catch (error) {
      toast.error(error.message)
    }
  }, [pdf, projectTitle])

  const downloadMd = useCallback(async () => {
    try {
      const md = await loadMd()
      const blob = new Blob([md], {type: "text/markdown"});

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${(projectTitle || "UnknownProject").replaceAll(" ", "_").concat("_Audit_Report_by_WatchPug")}.md`
      link.click();
    } catch (error) {
      toast.error(error.message)
    }
  }, [loadMd, projectTitle])

  return (
    <div ref={ref}>
      <div className="mb-4 pdf-viewer">
        <div className="header">
          <Button intent="primary" loading={compilePdfLoading} small onClick={async () => {
            setCompilePdfLoading(true)
            try {
              await compile()
            } catch (error) {
            } finally {
              setCompilePdfLoading(false)
            }
          }}>Compile</Button>
          {!isIssueNote && (
            <Button loading={downloadSourcePackLoading} className="ml-2" small onClick={async () => {
              const data = loadSourcePack()
              await downloadSourcePack(data)
            }}>
              <GrDocumentZip />
            </Button>
          )}
          {!isIssueNote && (
            <Button className="ml-2" disabled={!pdf} small onClick={async () => {
              if (!pdf) return;
              await downloadPdf(pdf)
            }}>
              <AiFillFilePdf />
            </Button>
          )}
          {!isIssueNote && (
            <Button className="ml-2" small onClick={async () => {
              await downloadMd()
            }}>
              <AiFillFileMarkdown />
            </Button>
          )}
        </div>

        <Document
          file={pdf}
          onLoadSuccess={({ numPages })=> {
            setNumPages(numPages)
          }}
        >
          {range(isIssueNote ? 2 : 1, numPages+1).map(page => {
            if (page > numPages) return null;
            return (
              <div key={page} className="my-2">
                <Page pageNumber={page} width={Math.min(width, 720)} renderAnnotationLayer={false} />
              </div>
            )
          })}
        </Document>
      </div>

    </div>
  )
}

function parseMetadata(content) {
  let metadata = {}

  const tree = unified()
    .use(remarkParser)
    .use(remarkFrontmatter, ['yaml', 'toml'])
    .use(remarkGfm)
    .parse(content)

    const metadataNodes = tree.children.filter((node) => node.type === 'yaml' || node.type === 'toml');
  for (const node of metadataNodes) {
    if (node.type === 'yaml') {
      metadata = _.merge(metadata, yaml.load(node.value));
    } else {
      metadata = _.merge(metadata, toml.parse(node.value));
    }
  }
  return metadata
}