import useSWR from 'swr'
import toast from 'react-hot-toast'
import { fetcher, poster } from '../utils'
import { Dialog, FormGroup, InputGroup, Button, Spinner, ControlGroup, Divider } from '@blueprintjs/core'
import { Popover2 as Popover } from '@blueprintjs/popover2'
import { Formik } from 'formik'
import { useProfile } from '../hooks/useProfile'
import { ChromePicker } from 'react-color'
import SignInWithEthereum from './SignInWithEthereum'
import { SignInWithGithub } from '../pages/home'

export default function ProfileDialog({ isOpen, onClose }) {
  const { mutate: reloadRemoteProfile, data: remoteProfile, isValidating, error } = useSWR(isOpen ? `/profile` : null, fetcher)
  const isLoading = !remoteProfile && !error && isValidating

  const { setProfile } = useProfile()

  return (
    <Dialog className='bp4-dark' title={'Profile'} isOpen={isOpen} onClose={onClose} canOutsideClickClose={false}>
      {isLoading && (
        <div className="flex items-center justify-center" style={{ minHeight: 240 }}>
          <Spinner size={25} />
        </div>
      )}
      {!isLoading && error && (
        <div className='flex items-center justify-center' style={{ minHeight: 240 }}>
          Failed to fetch profile
        </div>
      )}
      {!isLoading && remoteProfile && (
        <Formik
          initialValues={{
            ...remoteProfile,
            color: remoteProfile.color || 'orange',
          }}
          onSubmit={async (values) => {
            const requestPromise = poster(
              `/profile`,
              {
                username: values.username,
                color: values.color,
              },
              'PUT'
            )
            toast.promise(requestPromise, {
              loading: `Updating profile...`,
              success: `Profile updated!`,
              error: `Error when updating profile`,
            })
            await requestPromise
            const profile = await reloadRemoteProfile()
            setProfile(profile)
          }}
          enableReinitialize
        >
          {({ values, handleChange, setFieldValue, dirty, isSubmitting, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className='flex flex-col m-4'>
                  <ControlGroup fill>
                    <FormGroup label='Display Name' labelFor='user-username'>
                      <InputGroup id='user-username' name='username' value={values.username} onChange={handleChange} />
                    </FormGroup>
                    <FormGroup className='pl-4' label='Color' labelFor='user-color'>
                      <Popover
                        style={{ backgroundColor: '#333' }}
                        content={
                          <ChromePicker
                            color={values.color}
                            onChange={(color) => {
                              setFieldValue('color', color.hex)
                            }}
                            disableAlpha
                          ></ChromePicker>
                        }
                        placement='auto'
                      >
                        <div className='w-8 h-8 rounded cursor-pointer' style={{ backgroundColor: values.color }}></div>
                      </Popover>
                    </FormGroup>
                  </ControlGroup>

                  <FormGroup label='ID' labelFor='user-id'>
                    <InputGroup id='user-id' name='id' disabled value={values.id} />
                  </FormGroup>
                  <FormGroup label='Wallet' labelFor='user-wallet'>
                    {values.wallet ? (
                      <InputGroup id='user-wallet' name='wallet' disabled value={values.wallet} />
                    ) : (
                      <SignInWithEthereum
                        onSuccess={async ({ token, refreshToken }) => {
                          localStorage.token = token
                          localStorage.refreshToken = refreshToken
                          const profile = await reloadRemoteProfile()
                          setProfile(profile)
                        }}
                        onError={(err) => toast.error(err.message)}
                        connectLabel='Connect to Ethereum Wallet'
                        signInLabel={(address) => `Connect to ${address.slice(0, 6)}..${address.slice(-4)}`}
                      />
                    )}
                  </FormGroup>
                  <FormGroup label='Github ID' labelFor='user-wallet'>
                    {values.githubId ? (
                      <InputGroup id='user-githubId' name='githubId' disabled value={values.githubId} />
                    ) : (
                      <SignInWithGithub label="connect to Github account"/>
                    )}
                  </FormGroup>
                  <Button intent='primary' type='submit' disabled={!dirty || isSubmitting}>
                    Update
                  </Button>
                </div>
              </form>
            )
          }}
        </Formik>
      )}
      <Divider />
      <Button
        type='button'
        className='block m-4'
        onClick={() => {
          localStorage.clear()
          window.location.replace('/')
        }}
      >
        Logout
      </Button>
    </Dialog>
  )
}
