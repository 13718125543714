import { Spinner } from "@blueprintjs/core";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { PdfSettings } from "../../../components/pdfSettings";
import { fetcher, poster } from "../../../utils";

export default function TeamSettingsPdf({ teamId }) {
  const { data: team, isLoading, error, refetch: refetchTeam } = useQuery(['Team', teamId], async () => {
    return await fetcher(`/teams/${teamId}`)
  }, {
    enabled: !!teamId,
  })
  const { data: defaultPdfSettings, isLoading: isDefaultPdfSettingsLoading } = useQuery(['DefaultPdfSettings'], async () => {
    return await fetcher(`/defaultPdfSettings`)
  }, {
    cacheTime: 60 * 60
  })

  const updateTeamPdfSettings = useMutation(async ({ pdfSettings }) => {
    const updated = await poster(`/teams/${teamId}/pdfSettings`, { pdfSettings }, 'PUT')
    return updated
  })

  return (
    <div style={{ height: 'calc(100vh - 96px)' }}>
      {isLoading && (
        <div className="flex justify-center items-center" style={{ minHeight: 240 }}>
          <Spinner size={25} />
        </div>
      )}
      {!isLoading && !isDefaultPdfSettingsLoading && error && (
        <div className="flex justify-center items-center" style={{ minHeight: 240 }}>
          {error}
        </div>
      )}
      {!isLoading && !isDefaultPdfSettingsLoading && team && (
        <PdfSettings
          teamId={teamId}
          parentSettings={defaultPdfSettings || {}}
          parentSettingsName={"default"}
          settings={team.pdfSettings || {}}
          onSubmit={async (newSettings) => {
            const requestPromise = updateTeamPdfSettings.mutateAsync({
              pdfSettings: newSettings,
            })
            toast.promise(requestPromise, {
              loading: `Updating team PDF theme...`,
              success: `Team PDF theme updated!`,
              error: `Error when updating team PDF theme`,
            })
            await requestPromise;
            await refetchTeam();
          }}
        />
        )}
    </div>
  )
}
