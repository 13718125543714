import { Button, FormGroup, InputGroup, Spinner } from "@blueprintjs/core";
import { Formik } from "formik";
import { pick } from "lodash";
import toast from 'react-hot-toast';
import { useMutation, useQuery } from "react-query";
import { useTeamPermission } from "../../../hooks/useTeams";
import { useMap } from "../../../module/y/hook";
import { fetcher, poster } from "../../../utils";

export default function TeamSettingsGeneralMain({ teamId }) {
  const yTeamMeta = useMap('META')
  const { data: team, isLoading, error, refetch: refetchTeam } = useQuery(['Team', teamId], async () => {
    return await fetcher(`/teams/${teamId}`)
  }, {
    enabled: !!teamId,
  })

  const updateTeam = useMutation(async (args) => {
    const updated = await poster(`/teams/${teamId}`, args, 'PUT')
    yTeamMeta.set('TEAM_NAME', args.name)
    return updated
  })
  const role = useTeamPermission(teamId)

  return (
    <div>
      {isLoading && (
        <div className="flex items-center justify-center" style={{ minHeight: 240 }}>
          <Spinner size={25} />
        </div>
      )}
      {!isLoading && error && (
        <div className="flex items-center justify-center" style={{ minHeight: 240 }}>
          {error}
        </div>
      )}
      {!isLoading && team && (
        <Formik
          initialValues={{
            ...team,
          }}
          onSubmit={async (values) => {
            const requestPromise = updateTeam.mutateAsync(pick(values, 'name'))
            toast.promise(requestPromise, {
              loading: `Updating team...`,
              success: `Team updated!`,
              error: `Error when updating team`,
            })
            await requestPromise;
            await refetchTeam();
          }}
          enableReinitialize
        >
          {({ values, handleChange, dirty, isSubmitting, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col">
                  <FormGroup
                    label="Name"
                    labelFor="name"
                  >
                    <InputGroup
                      disabled={role < 1}
                      id="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <Button intent="primary" type="submit" disabled={!dirty || isSubmitting || role < 1}>Update</Button>
                </div>
              </form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}
