import { useEffect } from 'react'
import { generateNKeysBetween } from '../fractional-indexing'

const doing = {}
const runIf = (name, cond, fn) => {
  if (doing[name]) return
  if (!cond()) return
  doing[name] = true
  fn()
}
const initPos = (yArr) => {
  yArr.doc.transact(() => {
    const positions = generateNKeysBetween(null, null, yArr.length)
    console.debug(`initialing pos for ${yArr.length} item`)
    yArr
      .toArray()
      .sort((a, b) => a.get('updatedAt') && b.get('updatedAt') ? b.get('updatedAt') - a.get('updatedAt') : 0)
      .forEach((item, i) => {
        item.set('pos', positions[i])
      })
  })
}

export const useInitYArrPos = (obj) => {
  const name = Object.keys(obj)[0]
  const yArr = obj[name]
  useEffect(() => {
    runIf(
      name,
      () => yArr.length > 0 && yArr.get(0).get('pos') === undefined,
      () => initPos(yArr)
    )
  }, [yArr])
}
