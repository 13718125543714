import { AtomEffect, DefaultValue } from "recoil";
export const localStorageEffect: <T>() => AtomEffect<T> = () => ({ setSelf, onSet, node }) => {
  const savedValue = localStorage.getItem(node.key);
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue) => {
    if (newValue instanceof DefaultValue) {
      localStorage.removeItem(node.key);
    } else {
      localStorage.setItem(node.key, JSON.stringify(newValue));
    }
  });
};