import replaceAllBetween from './patched/unist-util-replace-all-between';
import { u } from 'unist-builder'
import {toHast} from 'mdast-util-to-hast'
import { visit } from 'unist-util-visit';

export function remarkAlert() {
  return (tree) => {
    visit(tree, 'paragraph', (node, index, parent) => {
      if (node.children.length < 1) return;
      if (node.children[0].type !== 'text') return;
      const child = node.children[0]

      const matched = /^:::(info|success|warning|danger)\n.+\n:::$/.exec(child.value)
      if (matched) {
        child.value = child.value
          .replace(/^:::(info|success|warning|danger)(\n)?/, '')
          .replace(/\n:::/, '')
        parent.children[index] = u(
          'alert',
          { alertType: matched[1] },
          [child],
          ''
        )
      }
    })

    replaceAllBetween(tree, (node) => {
      if (node.type !== 'paragraph') return false
      if (node.children.length < 1) return false
      if (node.children[0].type !== 'text') return false

      const child = node.children[0]
      const matched = /^:::(info|success|warning|danger)(\n)?/.exec(child.value)
      if (matched) {
        node._type_ = matched[1]
        return true
      }

      return false;
    }, (node) => {
      if (node.type !== 'paragraph') return false
      if (node.children.length < 1) return false
      if (node.children[0].type !== 'text') return false

      const child = node.children[0]
      const matched = /(\n)?:::$/.exec(child.value)
      if (matched) {
        return true
      }

      return false;
    }, (nodes) => {
      const firstNode = nodes[0]
      const lastNode = nodes[nodes.length-1]
      const restNodes = nodes.slice(1, nodes.length-1)

      firstNode.children[0].value = firstNode.children[0].value.replace(/^:::(info|success|warning|danger)(\n)?/, '')
      lastNode.children[0].value = lastNode.children[0].value.replace(/(\n)?:::$/, '')

      const newNodes = [
        ...(firstNode.children[0].value ? [firstNode] : []),
        ...restNodes,
        ...(lastNode.children[0].value ? [lastNode] : [])
      ]

      return [u(
        'alert',
        { alertType: firstNode._type_ },
        newNodes,
        ''
      )]
    })
  }
}

export const remarkAlertHandlers = {
  'alert': remarkAlertHandler,
}

function remarkAlertHandler(h, node) {
  return h(node, 'div', {
    className: `alert alert-${node.alertType}`,
  }, node.children.map((i) => toHast(i)))
}
