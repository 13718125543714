import { format } from 'date-fns';
import { useKBar } from 'kbar';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HoverNoteCard from '../components/hoverCard';
import KBarDocumentPreview from '../components/kbarDocumentPreview';
import KBarNotePreview from '../components/kbarNotePreview';
import { PlainMarkdownNote } from '../components/note';
import { KBarXContext, useRegisterSearches } from "../module/kbar/KBarXProvider";
import { contentToTitle, fetcher, poster } from '../utils';
import { eventEmitter } from '../utils/eventEmitter';
import KBarNoteTemplatePreview from './kbarTemplatePreview';

export function TeamKBarXSetup({ children }) {
  const { teamId, projectId } = useParams()
  const navigate = useNavigate();

  const { query: kbarQuery } = useKBar(state => ({ query: state.query }))
  const kbarxQuery = useContext(KBarXContext)

  const [kbarPreviewingNote, setKbarPreviewingNote] = useState(null)
  const [kbarPreviewingDocument, setKbarPreviewingDocument] = useState(null)
  const [kbarPreviewingNoteTemplate, setKbarPreviewingNoteTemplate] = useState(null)

  const duplicateNote = async (note, toProjectId) => {
    if (!teamId) return;
    if (!toProjectId) return;
    const newNote = await poster(`/teams/${teamId}/projects/${toProjectId}/notes`, {
      content: note.content,
    })
    emitOnNoteDuplicated(newNote)
  }
  // const [searchConfig, setSearchConfig] = useState({
  //   orderBy: "updatedAt",
  //   orderDesc: true,
  //   limit: 50,
  //   nature: false
  // })
  // const [searchResultRenderConfig, setSearchResultRenderConfig] = useState({
  //   highlight: true
  // })
  const getQuery = (search) => {
    const query = {}
    // for new embedding search
    if (search.startsWith('?')) {
      return `q=${search.substr(1)}`
    }
    // legacy search
    if (search.includes(' order:time')) {
      query.orderBy = 'updatedAt'
      if (search.includes(' order:time+')) {
        query.orderAsc = true
        search = search.replaceAll(' order:time+', '')
      } else {
        search = search.replaceAll(' order:time', '')
      }
    }
    // if (search.includes(':n')) {
    //   query.nature = true
    //   search = search.replaceAll(':n', '')
    // }
    // if (!query.nature) {
    search = search.trim()
    search = `${search}*`
    // }
    return new URLSearchParams({q: search, ...query}).toString()
  }
  const searches = useMemo(() => {
    if (!teamId) return []
    return [{
      key: 'note-search',
      name: 'Notes',
      onSearch: async (query) => {
        return await fetcher(query.startsWith('?') ?
          `/ask/${teamId}?${getQuery(query)}`: 
          `/teams/${teamId}/search/notes?${getQuery(query)}`
          )
      },
      toAction: (item) => {
        console.log('toAction', item)
        return {
          id: item.key,
          name: item.title ?? contentToTitle(item.content),
          perform: () => {
            kbarxQuery.setDisableCloseOnClickAway(true)
            setKbarPreviewingNote(item)
            return -1;
          },
        }
      },
      onRender: (item, active) => {
        return (
          <HoverNoteCard content={
            <div className="break-all tiny-md-preview">
              <PlainMarkdownNote noteContent={item.content} />
            </div>
          }>
            <div
              style={{
                padding: "12px 16px",
                background: active ? "var(--a1)" : "var(--background)",
                borderLeft: `2px solid ${
                  active ? "var(--foreground)" : "transparent"
                }`,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div className="flex flex-col">
                <div className="font-medium text-gray-700">{item.title ?? contentToTitle(item.content)}</div>
                <div className="flex flex-row gap-2" style={{ marginBottom: 2 }}>
                  <div className="text-xs text-gray-700 underline">{item.projectTitle}</div>
                  {item.updatedAt && (
                    <div className="text-xs text-gray-500">{format(new Date(item.updatedAt), 'yyyy-MM-dd hh:mm')}</div>
                  )}
                </div>
              </div>
            </div>
          </HoverNoteCard>
        )
      }
    }, {
      key: 'document-search',
      name: 'Documents',
      onSearch: async (query) => {
        return await fetcher(`/teams/${teamId}/search/documents?${getQuery(query)}`)
      },
      toAction: (item) => {
        return {
          id: item.key,
          name: item.title ?? contentToTitle(item.content),
          perform: () => {
            kbarxQuery.setDisableCloseOnClickAway(true)
            setKbarPreviewingDocument(item)
            return -1;
          },
        }
      },
      onRender: (item, active) => {
        return (
          <HoverNoteCard content={
            <div className="break-all tiny-md-preview">
              <PlainMarkdownNote noteContent={item.content} />
            </div>
          }>
            <div
              style={{
                padding: "12px 16px",
                background: active ? "var(--a1)" : "var(--background)",
                borderLeft: `2px solid ${
                  active ? "var(--foreground)" : "transparent"
                }`,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div className="flex flex-col">
                <div className="font-medium text-gray-700">{contentToTitle(item.content)}</div>
                <div className="flex flex-row gap-2" style={{ marginBottom: 2 }}>
                  {item.updatedAt && (
                    <div className="text-xs text-gray-600">{format(new Date(item.updatedAt), 'yyyy-MM-dd hh:mm')}</div>
                  )}
                </div>
              </div>
            </div>
          </HoverNoteCard>
        )
      }
    }, {
      key: 'note-template-search',
      name: 'Note Templates',
      onSearch: async (query) => {
        return await fetcher(`/teams/${teamId}/search/noteTemplates?${getQuery(query)}`)
      },
      toAction: (item) => {
        return {
          id: item.key,
          name: contentToTitle(item.content),
          perform: () => {
            kbarxQuery.setDisableCloseOnClickAway(true)
            setKbarPreviewingNoteTemplate(item)
            return -1;
          },
        }
      },
      onRender: (item, active) => {
        return (
          <HoverNoteCard content={
            <div className="break-all tiny-md-preview">
              <PlainMarkdownNote noteContent={item.content} />
            </div>
          }>
            <div
              style={{
                padding: "12px 16px",
                background: active ? "var(--a1)" : "var(--background)",
                borderLeft: `2px solid ${
                  active ? "var(--foreground)" : "transparent"
                }`,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div className="flex flex-col">
                <div className="font-medium text-gray-700">{contentToTitle(item.content)}</div>
                <div className="flex flex-row gap-2" style={{ marginBottom: 2 }}>
                  {item.updatedAt && (
                    <div className="text-xs text-gray-600">{format(new Date(item.updatedAt), 'yyyy-MM-dd hh:mm')}</div>
                  )}
                </div>
              </div>
            </div>
          </HoverNoteCard>
        )
      }
    }]
  }, [kbarxQuery, teamId])

  useRegisterSearches(searches.slice(0,1), [teamId])
  // useRegisterSearches(searches, [teamId, searchConfig])
  // useRegisterActions([{
  //     key: "searchConfigs",
  //     name: "Search Config",
  //     type: "search-config",
  //     render() {
  //       return <div onClick={(e)=>{
  //         setSearchConfig(prev => ({
  //           ...prev,
  //           limit: 1
  //         }))
  //         e.nativeEvent.stopImmediatePropagation()
  //         e.nativeEvent.preventDefault()
  //         e.stopPropagation()
  //         e.preventDefault()
  //       }} className='px-4 py-2 bg-gray-50'>
  //         <div className='flex'>{Object.entries(searchConfig).map(([key, value]) => {
  //         return (
  //           <div key={key} className="flex mr-2">
  //             <div className='mr-1 font-bold'>{key}</div>
  //             <div>{value.toString()}</div>
  //           </div>
  //         )
  //       })}</div>
  //       </div>
  //     }
  //   }], [])

  return (
    <>
      {children}
      {kbarPreviewingNote && (
        <KBarNotePreview
          isOpen={!!kbarPreviewingNote}
          onClose={(event) => {
            setKbarPreviewingNote(null)
            setTimeout(() => {
              kbarxQuery.setDisableCloseOnClickAway(false)
            }, 100)
          }}
          project={{}}
          note={kbarPreviewingNote}
          onGoToEditor={(item) => {
            kbarxQuery.setDisableCloseOnClickAway(false)
            kbarQuery.toggle()
            setTimeout(() => {
              setKbarPreviewingNote(null)
              navigate(`/teams/${item.team}/projects/${item.project}/edit`)
            }, 100)
          }}
          onDuplicate={(item) => {
            kbarxQuery.setDisableCloseOnClickAway(false)
            kbarQuery.toggle()
            setTimeout(() => {
              setKbarPreviewingNote(null)
              duplicateNote(item, projectId)
            }, 100)
          }}
        />
      )}
      {kbarPreviewingDocument && (
        <KBarDocumentPreview
          isOpen={!!kbarPreviewingDocument}
          onClose={(event) => {
            setKbarPreviewingDocument(null)
            setTimeout(() => {
              kbarxQuery.setDisableCloseOnClickAway(false)
            }, 100)
          }}
          document={kbarPreviewingDocument}
          onGoToEditor={(item) => {
            kbarxQuery.setDisableCloseOnClickAway(false)
            kbarQuery.toggle()
            setTimeout(() => {
              setKbarPreviewingDocument(null)
              navigate(`/teams/${item.team}/documents/${item.group}/edit`)
            }, 100)
          }}
        />
      )}
      {kbarPreviewingNoteTemplate && (
        <KBarNoteTemplatePreview
          isOpen={!!kbarPreviewingNoteTemplate}
          onClose={(event) => {
            setKbarPreviewingDocument(null)
            setTimeout(() => {
              kbarxQuery.setDisableCloseOnClickAway(false)
            }, 100)
          }}
          noteTemplate={kbarPreviewingNoteTemplate}
          onGoToEditor={(item) => {
            kbarxQuery.setDisableCloseOnClickAway(false)
            kbarQuery.toggle()
            setTimeout(() => {
              setKbarPreviewingDocument(null)
              navigate(`/teams/${item.team}/noteTemplates/${item.group}/edit`)
            }, 100)
          }}
        />
      )}
    </>
  )
}

function emitOnNoteDuplicated(newNote) {
  eventEmitter.emit("AppKBar:NoteDuplicated", newNote)
}
export function useOnNoteDuplicated(callback) {
  useEffect(() => {
    eventEmitter.on("AppKBar:NoteDuplicated", callback)
    return () => {
      eventEmitter.off("AppKBar:NoteDuplicated", callback)
    }
  }, [callback])
}