import React from "react";
import useSWR from 'swr'

import Note from '../components/note'
import Header from '../components/header'

import {useTitle} from 'react-use'

import {
  useParams
} from "react-router-dom"
import {fetcher} from '../utils'
import {useSWRCacheReset} from "../module/chaos/useSWRCacheReset";
import {GenericErrorBoundary} from "../module/chaos/GenericErrorBoundary";

function ProjectViewMain() {
  let { projectId, teamId } = useParams();
  const resetSwrCacheProjectNotes = useSWRCacheReset(`/public/teams/${teamId}/projects/${projectId}/notes`);
  const { data: {
    notes,
    project,
    team
  } } = useSWR(`/public/teams/${teamId}/projects/${projectId}/notes`, fetcher, { suspense: true })

  useTitle(`${project.title} - ${team.name}`)

  return (
    <div>
      <GenericErrorBoundary onReset={resetSwrCacheProjectNotes}>
        <Header breads={[{title: team.name}, {title:project.title}]} />
      </GenericErrorBoundary>
      <div className="flex flex-col items-center justify-center py-3">
      {notes.map(note => {
        return (
          <GenericErrorBoundary onReset={resetSwrCacheProjectNotes}>
            <Note noteContent={note.content} />
          </GenericErrorBoundary>
        )
      })}
      </div>
    </div>
  );
}

export default function ProjectView() {
    const { teamId, projectId } = useParams();
    const resetSwrCacheProjectNotes = useSWRCacheReset(`/public/teams/${teamId}/projects/${projectId}/notes`);
    resetSwrCacheProjectNotes()
    return (
        <GenericErrorBoundary onReset={resetSwrCacheProjectNotes}>
            <ProjectViewMain />
        </GenericErrorBoundary>
    );
}
