import { visit } from 'unist-util-visit';

export function remarkMark(options) {
  return (tree) => {
    visit(tree, 'text', (node, index, parent) => {
      if (!node.value.includes('==')) return;
      let matched = node.value.matchAll('==')
      const matchedArray = Array.from(matched)
      if (matchedArray.length === 0) return;

      const childrenNodes = []
      let startIndex = 0;
      for (let i = 0; i+1 < matchedArray.length; i += 2) {
        if (matchedArray[i].index + 2 === matchedArray[i+1].index) continue
        if (startIndex < matchedArray[i].index) {
          childrenNodes.push({
            type: 'text',
            value: node.value.slice(startIndex, matchedArray[i].index),
          })
        }
        childrenNodes.push({
          type: 'mark',
          children: [{
            type: 'text',
            value: node.value.slice(matchedArray[i].index + 2, matchedArray[i+1].index),
          }],
        })
        startIndex = matchedArray[i+1].index + 2
      }
      if (startIndex < node.value.length) {
        childrenNodes.push({
          type: 'text',
          value: node.value.slice(startIndex, node.value.length),
        })
      }

      parent.children.splice(index, 1, ...childrenNodes)
    })
  }
}
export const remarkRehypeMarkHandlers = {
  mark: remarkRehypeMarkHandler,
}

function remarkRehypeMarkHandler(h, node) {
  return h(node, 'mark', {}, node.children.map((i) => {
    return { type: 'text', value: i.value }
  }))
}
