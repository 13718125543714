import { Button, Drawer } from "@blueprintjs/core";
import Split from 'react-split';
import { GenericErrorBoundary } from '../module/chaos/GenericErrorBoundary';
import { contentToTitle } from '../utils';
import YEditorReadonly from "./yeditorReadonly";
import Note from './note';

export default function KBarNoteTemplatePreview({
  isOpen, onClose, noteTemplate,
  onGoToEditor
}) {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      className="bp4-dark"
      style={{ transition: 'none', backgroundColor: "#333" }}
      title={
        <div className="flex flex-col ">
          <span className="text-sm">Quick Preview - [Note Template] {contentToTitle(noteTemplate.content)}</span>
        </div>
      }
      hasBackdrop={false}
      size={"100%"}
      usePortal={true}
    >
      <div className="w-full" style={{ height: 'calc(100% - 40px - 40px)' }}>
        <Split className='split h-full flex-grow overflow-auto' sizes={[50, 50]} minSize={[100, 100]} expandToMin={true}>
          <div className='overflow-hidden'>
            <GenericErrorBoundary>
              {noteTemplate && (
                <YEditorReadonly path={`/noteTemplate/${noteTemplate.key}/readonly`} content={noteTemplate.content} />
              )}
            </GenericErrorBoundary>
          </div>
          <div className='overflow-auto h-full'>
            <div className='flex justify-center'>
              <GenericErrorBoundary>
                {noteTemplate && noteTemplate.content ? <Note noteContent={noteTemplate ? noteTemplate.content : ''} /> : null}
              </GenericErrorBoundary>
            </div>
          </div>
        </Split>
      </div>
      <div style={{ height: 40, padding: 5, paddingLeft: 20, borderTopWidth: 1, borderColor: '#414141' }} className="flex flex-row justify-start items-center gap-2 h-full">
        <Button onClick={() => { onGoToEditor(noteTemplate) }}>Go to Editor</Button>
      </div>
    </Drawer>
  )
}
