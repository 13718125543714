import React from "react";
import {useTitle} from 'react-use';
import {
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";

import Header from '../../components/header'
import { useMutation } from "react-query";
import { useTeams } from "../../hooks/useTeams";
import { poster } from "../../utils";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function TeamsMain() {
  let navigate = useNavigate();
  let query = useQuery();
  const {refetch: refetchTeams}= useTeams()
  const token = query.get('token');
  const refreshToken = query.get('refreshToken');
  if (token && token.length > 0) {
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    navigate('/teams', {replace: true});
  }
  const { teams } = useTeams()
  const createTeam = useMutation(async () => {
    await poster(`/teams`, {
      name: 'Untitled Team',
    })
    await refetchTeams()
  })

  useTitle("Teams")

  return (
    <div className="min-h-screen" style={{
      backgroundColor: '#333'
    }}>
      <Header displayDocConnectStatus breads={[{title: 'Teams'}]}/>
      <ul className="w-full text-lg notes-list">
        <li className="truncate cursor-pointer">
          <div
            className="flex flex-row items-center w-full p-4 py-6 text-2xl"
            onClick={async () => {
              const team = await createTeam.mutateAsync()
              navigate(`/teams/${team.key}`, {replace: true});
            }}
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 10H20" stroke="white"/>
              <path d="M10 20L10 -5.96046e-07" stroke="white"/>
            </svg>
            <span className="ml-3">New Team</span>
          </div>
        </li>
      {teams.map(team => {
        return <li className="truncate cursor-pointer" key={team.key}>
          <Link className="block w-full p-4 py-6 text-2xl font-bold" to={`/teams/${team.key}`}>{team.name}</Link>
        </li>
      })}
      </ul>
    </div>
  );
}

