import { Drawer, Spinner, NonIdealState } from "@blueprintjs/core";
import React, { useState } from 'react';
import Split from 'react-split';
import useSWR from 'swr';
import { contentToTitle, fetcher } from '../utils';
import { GenericErrorBoundary } from '../module/chaos/GenericErrorBoundary'
import Note from './note'
import FolderList from "./folder/folderList";
import YEditorReadonly from "./yeditorReadonly";
import TimeAgo from "./TimeAgo";
import { ContextMenuItem } from "./contextMenu";
import toast from "react-hot-toast";
import { parse } from "date-fns";

export default function DeletedNotes({isOpen, onClose, teamId, projectId, onRestore}) {
  const { data: deletedNotes, isValidating, error } = useSWR(isOpen ? `/teams/${teamId}/projects/${projectId}/deletedNotes` : null, fetcher)

  const isLoading = !deletedNotes && !error && isValidating

  const [editingNote, setEditingNote] = useState(null)

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      canOutsideClickClose={false}
      className="bp4-dark"
      style={{ transition: 'none', backgroundColor: "#333" }}
      title={
        <div className="flex flex-col ">
          <span className="text-sm">Deleted notes</span>
        </div>
      }
      hasBackdrop={false}
      size={"100%"}
      usePortal={true}
    >
      {isLoading && (
        <div className="flex flex-col items-center justify-center h-full">
          <NonIdealState
            icon={<Spinner/>}
            title={"Loading Deleted Notes"}
          />
        </div>
      )}
      {!isLoading && (
        <Split className='split flex-grow overflow-auto' sizes={[14, 43, 43]} minSize={[100, 300, 300]} expandToMin={true}>
          <div className='h-full flex flex-col overflow-hidden' style={{ width: 200 }}>
            <div className='flex-1 overflow-y-scroll'>
              <FolderList
                entityName={'Deleted Note'}
                activeEntityKey={editingNote?.key}
                isDragDisabled
                folders={[]}
                folderOpened={{}}
                entities={deletedNotes || []}
                onEntityKey={(entity) => {
                  return entity.key
                }}
                onEntityMenu={(note) => {
                  return (
                    <GenericErrorBoundary>
                      <ContextMenuItem
                        onClick={() => {
                          const yNote = onRestore(note)
                          if (yNote) {
                            toast.success(`Success to restore a deleted note to new one (n=${yNote.get('n')})!`)
                          }
                        }}
                      >
                        Restore
                      </ContextMenuItem>
                    </GenericErrorBoundary>
                  )
                }}
                onEntityRender={(note, index) => {
                  const title = contentToTitle(note.content, { publishIndex: '' })
                  return (
                    <div className='w-full cursor-pointer flex flex-row items-center'>
                      {/* tmp disable isNoteTitleWrapped option */}
                      <div className={`flex-1 truncate`}>
                        {title}
                      </div>
                      <div title={note.authorUser?.username} className="mr-1 w-4 h-4 rounded-full overflow-hidden">
                        {note.authorUser?.avatar ? (
                          <img className="w-full h-full" src={note.authorUser?.avatar} alt="user avatar" />
                        ) : (
                          <div className="w-full h-full bg-gray-600" />
                        )}
                      </div>
                      {/* <div className=" bg-blue-900 text-xs w-4 h-4 text-center rounded-sm mr-1">{note.n}</div> */}
                    </div>
                  )
                }}
                onEntityTooltipRender={(note, index) => {
                  const title = contentToTitle(note.content, { publishIndex: '' })
                  const _deletedAt = (() => {
                    let d = parse(String(note.deletedAt), "yyyyMMddhhmmss", new Date())
                    if (isNaN(d) || d.toString() === 'Invalid Date') {
                      return null
                    }
                    return d
                  })()
                  return (
                    <div className="bg-white p-2 rounded" style={{ maxWidth: 260 }}>
                      <div>
                        <span>{title}</span>
                      </div>
                      <div className="mt-2">
                        <div className="flex-0 flex flex-col">
                          <div className="ml-1 text-gray-600 text-sm">
                            <span>Updated at</span>
                            <span> </span>
                            <span className="font-bold"><TimeAgo date={note.updatedAt} /></span>
                          </div>
                          {_deletedAt && (
                            <div className="ml-1 text-gray-600 text-sm">
                              <span>Deleted at</span>
                              <span> </span>
                              <span className="font-bold"><TimeAgo date={_deletedAt} /></span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                }}
                onEntityClick={(entityKey) => {
                  const yDocument = deletedNotes?.find((i) => i.key === entityKey)
                  if (!yDocument) return;
                  setEditingNote(yDocument)
                }}
              />
            </div>
          </div>
          <div className='overflow-hidden'>
            <GenericErrorBoundary>
              {editingNote ? (
                <YEditorReadonly
                  path={`/deletedNotes/${editingNote.key}/content`}
                  content={editingNote.content}
                />
              ) : (
                <div className='flex items-center justify-center h-full'>
                  <p className='text-white opacity-50'>Command + K for Quick Actions</p>
                </div>
              )}
            </GenericErrorBoundary>
          </div>
          <div className='overflow-auto h-full'>
            <div className='flex justify-center '>
              <GenericErrorBoundary>{editingNote ? <Note noteContent={editingNote.content} /> : null}</GenericErrorBoundary>
            </div>
          </div>
        </Split>
      )}
    </Drawer>
  )
}
