import { Collapse } from '@blueprintjs/core'
import * as Tooltip from '@radix-ui/react-tooltip';
import { chain } from 'lodash'
import { useRef } from 'react'
import { useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { AiFillFolder, AiFillFolderOpen } from 'react-icons/ai'
import { GenericErrorBoundary } from '../../module/chaos/GenericErrorBoundary'
import { ContextMenuBox, ContextMenuItem, ContextMenuSeparator } from '../contextMenu'

export default function FolderList({
  entityName,
  isDragDisabled,
  activeEntityKey,
  folders, entities,
  folderOpened,
  onEntityMenu,
  onEntityClassName,
  onEntityStyle,
  onEntityRender,
  onEntityTooltipRender,
  onEntityKey, onEntityPos,
  onFolderKey, onFolderPos,
  onEntityClick,
  onEntityCreateInFolder,
  onEntityFolderKey,
  onEntityFolderAndPosChange,
  onFolderPosChange,
  onFolderDelete,
  onFolderOpened,
  onFolderNameChange,
}) {
  const _onEntityKey = useMemo(() => onEntityKey || ((i) => i.key), [onEntityKey])
  const _onEntityPos = useMemo(() => onEntityPos || ((i) => i.pos), [onEntityPos])
  const _onFolderKey = useMemo(() => onFolderKey || ((i) => i.key), [onFolderKey])
  const _onFolderPos = useMemo(() => onFolderPos || ((i) => i.pos), [onFolderPos])
  const _onEntityFolderKey = useMemo(() => onEntityFolderKey || ((i) => i.folder), [onEntityFolderKey])

  const activeEntity = useMemo(() => entities.find((i) => i.key === activeEntityKey), [activeEntityKey, entities])

  const items = useMemo(() => {
    return chain(entities || [])
      .groupBy((i) => _onEntityFolderKey(i))
      .mapValues((entities, folderKey) => {
        const folder = folders.find((i) => i.key === folderKey)
        if (folder) {
          return {
            type: 'folder',
            folder: {
              ...folder,
              opened: (folderOpened || {})[folder.key],
            },
            items: entities.map((i) => {
              return {
                type: 'entity',
                entity: i,
              }
            }).sort((a, b) => {
              const aPos = _onEntityPos(a.entity)
              const bPos = _onEntityPos(b.entity)
              if (aPos < bPos) return -1
              else if (aPos > bPos) return 1
              else return 0
            }),
          }
        } else {
          return entities.map((i) => {
            return {
              type: 'entity',
              entity: i,
            }
          }).sort((a, b) => {
            const aPos = _onEntityPos(a.entity)
            const bPos = _onEntityPos(b.entity)
            if (aPos < bPos) return -1
            else if (aPos > bPos) return 1
            else return 0
          })
        }
      })
      .thru((data) => {
        for (const folder of folders) {
          if (!data[folder.key]) {
            data[folder.key] = {
              type: 'folder',
              folder: {
                ...folder,
                opened: (folderOpened || {})[folder.key],
              },
              items: [],
            }
          }
        }
        return data
      })
      .values()
      .flatten()
      .sort((a, b) => {
        if (a.type === b.type) {
          if (a.type === 'folder') {
            const aPos = _onFolderPos(a.folder)
            const bPos = _onFolderPos(b.folder)
            if (aPos < bPos) return -1
            else if (aPos > bPos) return 1
            else return 0
          } else {
            const aPos = _onEntityPos(a.entity)
            const bPos = _onEntityPos(b.entity)
            if (aPos < bPos) return -1
            else if (aPos > bPos) return 1
            else return 0
          }
        } else {
          return (a.type === 'folder') ? -1 : 1
        }
      })
      .thru((items) => {
        let fullIndex = 0;
        for (const i of items) {
          if (i.type === "folder") {
            for (const i2 of i.items) {
              i2.entity.fullIndex = fullIndex;
              fullIndex++;
            }
          } else {
            i.entity.fullIndex = fullIndex;
            fullIndex++;
          }
        }
        return items
      })
      .value()
  }, [entities, _onEntityFolderKey, folders, folderOpened, _onEntityPos, _onFolderPos])

  const folderItems = useMemo(() => {
    return items.filter((i) => i.type === "folder")
  }, [items])

  const entityItems = useMemo(() => {
    return items.filter((i) => i.type === "entity")
  }, [items])

  if (isDragDisabled) {
    return (
      <div className="text-white flex flex-col flex-1 h-full">
        {folderItems.map((item, itemIndex) => {
          return (
            <FolderListItemFolder key={`folder:${item.folder.key}`} entityName={entityName} folder={item.folder} activeEntity={activeEntity} onFolderOpened={onFolderOpened} onFolderNameChange={onFolderNameChange} onFolderDelete={onFolderDelete} onEntityCreateInFolder={onEntityCreateInFolder}>
              {item.items.length === 0 && (
                <PlainFolderListEmptyFolderHint />
              )}
              {item.items.map((item, itemIndex) => {
                return (
                  <FolderListItemEntity key={`entity:${item.entity.key}`} activeEntityKey={activeEntityKey} index={itemIndex} entity={item.entity} onEntityClick={onEntityClick} onEntityMenu={onEntityMenu} onEntityClassName={onEntityClassName} onEntityStyle={onEntityStyle} onEntityRender={onEntityRender} onEntityTooltipRender={onEntityTooltipRender} />
                )
              })}
            </FolderListItemFolder>
          )
        })}
        {entityItems.map((item, itemIndex) => {
          return (
            <FolderListItemEntity key={`entity:${item.entity.key}`} activeEntityKey={activeEntityKey} index={itemIndex} entity={item.entity} onEntityClick={onEntityClick} onEntityMenu={onEntityMenu} onEntityClassName={onEntityClassName} onEntityStyle={onEntityStyle} onEntityRender={onEntityRender} onEntityTooltipRender={onEntityTooltipRender} />
          )
        })}
      </div>
    )
  }

  return (
    <div className="text-white flex flex-col flex-1 h-full">
    <DragDropContext
      onDragEnd={(result) => {
        if (result.type === 'folder') {
          if (result.destination) {
            if (result.destination.droppableId === 'folder-root') {
              const folderKey = result.draggableId
              const toIndex = result.destination.index
              onFolderPosChange(folderKey, toIndex)
            }
          }
        } else if (result.type === 'entity') {
          if (result.destination) {
            const entityKey = result.draggableId
            if (result.source.droppableId === 'entity-root' && result.destination.droppableId !== 'entity-root') {
              // move entity into folder
              const folderKey = result.destination.droppableId
              const toIndex = result.destination.index
              onEntityFolderAndPosChange(entityKey, folderKey, toIndex)
            } else if (result.source.droppableId !== 'entity-root' && result.destination.droppableId === 'entity-root') {
              // move entity out folder
              const folderKey = null
              const toIndex = result.destination.index
              onEntityFolderAndPosChange(entityKey, folderKey, toIndex)
            } else {
              if (result.source.droppableId === 'entity-root') {
                // move entity in root
                const folderKey = null
                const toIndex = result.destination.index
                onEntityFolderAndPosChange(entityKey, folderKey, toIndex)
              } else {
                // move entity in folder
                const folderKey = result.destination.droppableId
                const toIndex = result.destination.index
                onEntityFolderAndPosChange(entityKey, folderKey, toIndex)
              }
            }
          }
        }

      }}
    >
      <FolderListFolderDroppable items={folderItems}>
        {folderItems.map((item, itemIndex) => {
          return (
            <FolderListFolderDraggable key={`folder:${item.folder.key}`} index={itemIndex} folder={item.folder} onFolderKey={_onFolderKey}>
              <FolderListEntityInFolderDroppable folder={item.folder} items={item.items} onEntityRender={onEntityRender}>
                <FolderListItemFolder entityName={entityName} folder={item.folder} activeEntity={activeEntity} onFolderOpened={onFolderOpened} onFolderNameChange={onFolderNameChange} onFolderDelete={onFolderDelete} onEntityCreateInFolder={onEntityCreateInFolder}>
                  {item.items.length === 0 && (
                    <PlainFolderListEmptyFolderHint />
                  )}
                  {item.items.map((item, itemIndex) => {
                    return (
                      <FolderListEntityDraggable key={`entity:${item.entity.key}`} index={itemIndex} entity={item.entity} onEntityKey={_onEntityKey}>
                        <FolderListItemEntity activeEntityKey={activeEntityKey} index={itemIndex} entity={item.entity} onEntityClick={onEntityClick} onEntityMenu={onEntityMenu} onEntityClassName={onEntityClassName} onEntityStyle={onEntityStyle} onEntityRender={onEntityRender} onEntityTooltipRender={onEntityTooltipRender} />
                      </FolderListEntityDraggable>
                    )
                  })}
                </FolderListItemFolder>
              </FolderListEntityInFolderDroppable>
            </FolderListFolderDraggable>
          )
        })}
      </FolderListFolderDroppable>
      {/* tmp fix https://github.com/atlassian/react-beautiful-dnd/issues/1734 */}
      <div></div>
      <FolderListEntityDroppable items={entityItems} onEntityRender={onEntityRender}>
        {entityItems.map((item, itemIndex) => {
          return (
            <FolderListEntityDraggable key={`entity:${item.entity.key}`} index={itemIndex} entity={item.entity} onEntityKey={_onEntityKey}>
              <FolderListItemEntity activeEntityKey={activeEntityKey} index={itemIndex} entity={item.entity} onEntityClick={onEntityClick} onEntityMenu={onEntityMenu} onEntityClassName={onEntityClassName} onEntityStyle={onEntityStyle} onEntityRender={onEntityRender} onEntityTooltipRender={onEntityTooltipRender} />
            </FolderListEntityDraggable>
          )
        })}
      </FolderListEntityDroppable>
    </DragDropContext>
    </div>
  )
}

function FolderListEntityInFolderDroppable({ folder, items, onEntityRender, children }) {
  return (
    <Droppable droppableId={folder.key} type={`entity`}
      renderClone={(provided, snapshot, rubric) => {
        const index = rubric.source.index
        const entity = items[index].entity
        return (
          <div
            ref={provided.innerRef}
            className="bg-gray-500 opacity-70 select-none"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <PlainFolderListItemEntity index={index} entity={entity} onEntityRender={onEntityRender} />
          </div>
        )
      }}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className={`${snapshot.isDraggingOver ? 'bg-gray-600' : ''}`}
          {...provided.droppableProps}
        >
          {children}
          {folder.opened ? provided.placeholder : null}
        </div>
      )}
    </Droppable>
  )
}

function FolderListFolderDroppable({ items, children }) {
  return (
    <Droppable droppableId={`folder-root`} type={`folder`}
      renderClone={(provided, snapshot, rubric) => {
        const folder = items[rubric.source.index].folder
        return (
          <div
            ref={provided.innerRef}
            className="bg-gray-500 opacity-70 select-none"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <PlainFolderListItemFolder folder={folder} />
          </div>
        )
      }}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className={`${snapshot.isDraggingOver ? 'bg-gray-600' : ''}`}
          {...provided.droppableProps}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

function FolderListEntityDroppable({items, onEntityRender, children }) {
  return (
    <Droppable droppableId={`entity-root`} type={`entity`}
      renderClone={(provided, snapshot, rubric) => {
        const index = rubric.source.index
        const entity = items[index].entity
        return (
          <div
            ref={provided.innerRef}
            className="bg-gray-500 opacity-70 select-none"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <PlainFolderListItemEntity index={index} entity={entity} onEntityRender={onEntityRender} />
          </div>
        )
      }}
    >
      {(provided, snapshot) => {
        return (
        <div
          ref={provided.innerRef}
          className={`flex-1 ${snapshot.isDraggingOver ? 'bg-gray-600' : ''}`}
          {...provided.droppableProps}
        >
          {children}
          {provided.placeholder}
        </div>
      )}}
    </Droppable>
  )
}

function FolderListFolderDraggable({ index, folder, onFolderKey, children }) {
  const key = onFolderKey(folder)
  return (
    <Draggable key={folder.key} draggableId={key} type={'folder'} index={index}>
      {(dragProvided) => (
        <div
          ref={dragProvided.innerRef}
          {...dragProvided.draggableProps}
          {...dragProvided.dragHandleProps}
        >
          {children}
        </div>
      )}
    </Draggable>
  )
}

function FolderListEntityDraggable({ index, entity, onEntityKey, children }) {
  const key = onEntityKey(entity)
  return (
    <Draggable key={entity.key} draggableId={key} type={'entity'} index={index}>
      {(dragProvided) => (
        <div
          ref={dragProvided.innerRef}
          {...dragProvided.draggableProps}
          {...dragProvided.dragHandleProps}
        >
          {children}
        </div>
      )}
    </Draggable>
  )
}

function FolderListItemFolder({
  entityName,
  folder, activeEntity,
  onFolderOpened, onFolderNameChange, onFolderDelete, onEntityCreateInFolder,
  children
}) {
  const active = folder.key === activeEntity?.folder && !folder.opened
  const shouldOpened = folder.opened
  const renameInputRef = useRef(null)
  const [renaming, setRenaming] = useState(false)
  const handleRenameBlur = () => {
    onFolderNameChange(folder.key, folder.name)
    setRenaming(false)
  }
  const handleRenameChange = (event) => {
    folder.name = event.target.value
  }
  const handleRenameKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      handleRenameBlur()
    }
  }

  return (
    <ContextMenuBox menu={
      <GenericErrorBoundary>
        <ContextMenuItem
          onClick={() => {
            setRenaming(true)
            setTimeout(() => {
              renameInputRef.current?.focus()
            }, 10)
          }}
        >
          <span>Rename</span>
        </ContextMenuItem>
        <ContextMenuItem
          onClick={() => {
            onFolderDelete(folder.key)
          }}
        >
          <span className='text-red-800'>Delete</span>
        </ContextMenuItem>
        {onEntityCreateInFolder && (
          <>
            <ContextMenuSeparator />
            <ContextMenuItem
              onClick={() => {
                onEntityCreateInFolder(folder.key)
              }}
            >
              <span>Create New {entityName || 'Item'}</span>
            </ContextMenuItem>
          </>
        )}
      </GenericErrorBoundary>
    }>
      <div
        className={`flex flex-row items-center gap-1 px-2 py-3 cursor-pointer text-yellow-100 ${renaming ? '' : 'hover:bg-gray-600'} select-none`}
        style={{
          color: active ? 'rgb(67, 138, 255)' : undefined,
        }}
        onClick={() => {
          if (renaming) return;
          onFolderOpened(folder.key, !folder.opened)
        }}
      >
        {shouldOpened ? (
          <AiFillFolderOpen size={16} />
        ) : (
          <AiFillFolder size={16} />
        )}
        <input
          className='flex-1 py-.5'
          style={{ display: renaming ? undefined : 'none' }}
          disabled={!renaming}
          ref={renameInputRef}
          defaultValue={folder.name}
          onChange={handleRenameChange}
          onBlur={handleRenameBlur}
          onKeyDown={handleRenameKeyDown}
        />
        <span className='whitespace-pre-wrap' style={{ display: !renaming ? undefined : 'none' }}>{folder.name}</span>
      </div>
      <Collapse
        isOpen={shouldOpened}
      >
        <div>
          {children}
        </div>
      </Collapse>
    </ContextMenuBox>
  )
}

function PlainFolderListItemFolder({ folder }) {
  return (
    <div
      className={`flex flex-row items-center gap-1 px-2 py-3 cursor-pointer text-yellow-100 hover:bg-gray-600 select-none`}
    >
      <AiFillFolder size={16} />
      <span>{folder.name}</span>
    </div>
  )
}

function FolderListItemEntity({ activeEntityKey, index, entity, onEntityMenu, onEntityClassName, onEntityStyle, onEntityRender, onEntityTooltipRender, onEntityClick }) {
  const active = entity.key === activeEntityKey
  const [tooltipOpen, setToolTipOpen] = useState(false)
  return (
    <ContextMenuBox menu={onEntityMenu(entity)}>
      <Tooltip.Root open={tooltipOpen}>
        <Tooltip.Trigger asChild>
          <div
            className={`flex flex-row items-center p-2 cursor-pointer hover:bg-gray-600 select-none ${onEntityClassName ? onEntityClassName(entity) : ''}`}
            style={{
              color: active ? 'rgb(67, 138, 255)' : undefined,
              backgroundColor: active ? 'rgba(255, 255, 255, 0.05)' : undefined,
              ...(onEntityStyle ? onEntityStyle(entity) : {}),
            }}
            onClick={() => {
              onEntityClick(entity.key)
            }}
            onMouseEnter={() => {
              setToolTipOpen(true)
            }}
            onMouseLeave={() => {
              setToolTipOpen(false)
            }}
          >
            {entity.folder && (
              <div style={{ width: 16 }} />
            )}
            <div style={{ width: entity.folder ? 'calc(100% - 16px)' : '100%' }}>
              {onEntityRender(entity, index)}
            </div>
          </div>
        </Tooltip.Trigger>
        {onEntityTooltipRender && (
          <Tooltip.Portal style={{ zIndex: 999999 }}>
            <Tooltip.Content style={{ zIndex: 999999 }} forceMount side="right" align="start" asChild>
              {onEntityTooltipRender(entity, index)}
            </Tooltip.Content>
          </Tooltip.Portal>
        )}
      </Tooltip.Root>
    </ContextMenuBox>
  )
}

function PlainFolderListItemEntity({ index, entity, onEntityRender }) {
  return (
    <div
      className="flex flex-row items-center gap-1 p-2 cursor-pointer hover:bg-gray-600 select-none"
    >
      {entity.folder && (
        <AiFillFolderOpen size={16} style={{ opacity: 0 }} />
      )}
      {onEntityRender(entity, index)}
    </div>
  )
}

function PlainFolderListEmptyFolderHint() {
  return (
    <div
      className="flex flex-row items-center gap-1 p-2 cursor-pointer hover:bg-gray-600 select-none text-gray-400"
    >
      <AiFillFolderOpen size={16} style={{ opacity: 0 }} />
      <span>{`(Empty)`}</span>
    </div>
  )
}
