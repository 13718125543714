import { Alert, Button, Divider, FormGroup, Popover } from "@blueprintjs/core";
import axios from "axios";
import { Formik } from "formik";
import { range } from "lodash";
import React, { useCallback } from "react";
import { useState } from "react";
import { ChromePicker } from "react-color";
import toast from "react-hot-toast";
import { Document, Page } from "react-pdf";
import { useMutation } from "react-query";
import { restApiUrl } from "../utils";
import { FileDataUrlUploadButton } from "./fileDataUrlUploadButton";
import useResizeObserver from "use-resize-observer/polyfilled";

export function PdfSettings({ teamId, settings, parentSettings, parentSettingsName, onSubmit }) {

  const [pdf, setPdf] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const { ref, width = 1 } = useResizeObserver();
  const [restoreConfirmOpen, setRestoreConfirmOpen] = useState(false)


  const loadPdf = useMutation(async ({ settings }) => {
    const url = `${restApiUrl}/teams/${teamId}/pdf-settings-preview`
    const { data } = await axios.post(url, {
      settings,
    }, {
      responseType: 'arraybuffer',
      headers: {
        'Authorization': `Bearer ${localStorage.token}`
      }
    });
    return data
  })

  const preview = useCallback(async (settings) => {
    try {
      const data = await loadPdf.mutateAsync({ settings });
      setPdf(data)
    } catch (error) {
      toast.error(error.message)
    }
  }, [loadPdf])

  return (
    <div className="h-full">
      <Formik
        initialValues={settings}
        onSubmit={async (values) => {
          onSubmit(values)
        }}
        enableReinitialize
      >
        {({values, handleSubmit, setFieldValue, setValues }) => {
          return (
            <div className="flex flex-row h-full">
              <div className="w-1/2 pr-4 h-full overflow-scroll">
                <form className="top-0 sticky" onSubmit={handleSubmit}>
                  <div className="flex flex-col">
                    <div className="text-xl mb-2 mt-4">
                      Template
                    </div>

                    <FormGroup
                      label={
                        <FormGroupInheritLabel
                          label="Presets"
                          inherited={!values.templateId}
                          inheritName={parentSettingsName}
                        />
                      }
                      helperText={
                        <div>
                          <div>Preset PDF template provided by HackNote</div>
                        </div>
                      }
                    >
                      <div className="flex gap-8">
                        <div className="flex flex-row gap-4">
                          {["watchpug"].map((templateId) => {
                            const valuesTemplateId = values.templateId || parentSettings.templateId || "watchpug"
                            const selected = templateId === valuesTemplateId
                            return (
                              <div
                                key={templateId}
                                className={`${selected ? 'border-2 border-blue-500 text-blue-500 p-0' : 'border border-gray-300 text-gray-300 p-1'} w-28 h-36 cursor-pointer flex justify-center items-center`}
                                onClick={() => {
                                  setFieldValue('templateId', templateId)
                                }}
                              >
                                {templateId}
                              </div>
                            )
                          })}
                          {/* <div
                            className={`${values.templateId === 'custom' ? 'border-2 border-blue-500 text-blue-500 p-0' : 'border border-gray-300 text-gray-300 p-1'} w-28 h-36 cursor-pointer flex justify-center items-center`}
                            onClick={() => {
                              setFieldValue('templateId', 'custom')
                            }}
                          >
                            Custom
                          </div> */}
                        </div>
                      </div>
                    </FormGroup>

                    {/* {values.templateId === 'custom' && (
                      <div>upload template</div>
                    )} */}

                    <div className="text-xl mb-2 mt-4">
                      Brand/Logo
                    </div>
                    <div className="flex gap-8">
                      <FormGroup
                        label={
                          <FormGroupInheritLabel
                            label="Brand"
                            inherited={!values.brand}
                            inheritName={parentSettingsName}
                          />
                        }
                        labelFor="brand"
                        helperText={
                          <div>
                            <div>support .jpg, .jpeg, .png, .pdf format</div>
                            <div>max file size: 1MB</div>
                            <div>recommend size ratio: 160px : 40px</div>
                          </div>
                        }
                      >
                        <FileDataUrlUploadButton
                          data={values.brand || parentSettings.brand}
                          previewWidth={140}
                          previewHeight={140}
                          maxSize={1024}
                          accept=".jpg,.jpeg,.png,.pdf"
                          onUpload={(data) => {
                            console.log(data)
                            setFieldValue('brand', data)
                          }}
                        />
                      </FormGroup>
                      <FormGroup
                        label={
                          <FormGroupInheritLabel
                            label="Logo"
                            inherited={!values.logo}
                            inheritName={parentSettingsName}
                          />
                        }
                        labelFor="logo"
                        helperText={
                          <div>
                            <div>support .jpg, .jpeg, .png, .pdf format</div>
                            <div>max file size: 1MB</div>
                            <div>recommend size ratio: 40px : 40px</div>
                          </div>
                        }
                      >
                        <FileDataUrlUploadButton
                          data={values.logo || parentSettings.logo}
                          previewWidth={140}
                          previewHeight={140}
                          maxSize={1024}
                          accept=".jpg,.jpeg,.png,.pdf"
                          onUpload={(data) => {
                            console.log(data)
                            setFieldValue('logo', data)
                          }}
                        />
                      </FormGroup>
                    </div>
                    <div className="text-xl mb-2 mt-4">
                      Theme
                    </div>
                    <div className="flex gap-8">
                      <FormGroup
                        label={
                          <FormGroupInheritLabel
                            label="Primary Color"
                            inherited={!values.primaryColor}
                            inheritName={parentSettingsName}
                          />
                        }
                        labelFor="primaryColor"
                      >
                        <Popover
                          style={{ backgroundColor: "#333" }}
                          content={
                            <ChromePicker
                              color={values.primaryColor || parentSettings.primaryColor}
                              onChange={(color) => {
                                setFieldValue("primaryColor", color.hex)
                              }}
                              disableAlpha
                            ></ChromePicker>
                          }
                          placement="auto"
                        >
                          <div
                            className="w-8 h-8 rounded cursor-pointer border border-gray-300"
                            style={{ backgroundColor: values.primaryColor || parentSettings.primaryColor }}
                          ></div>
                        </Popover>
                      </FormGroup>
                    </div>
                    <div className="flex flex-col gap-2 sticky bottom-0 py-2" style={{ backgroundColor: '#333333' }}>
                      <Button intent="primary" type="submit">Update</Button>
                      <Button minimal
                        onClick={() => {
                          setRestoreConfirmOpen(true)
                        }}
                      >
                        <div className="text-gray-400">
                          Restore to default settings.
                        </div>
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
              <Divider />
              <div ref={ref} className="w-1/2 pl-4 pr-4 h-full overflow-scroll">
                <div className="sticky top-0 z-10 py-1 -mr-1" style={{ backgroundColor: '#333333' }}>
                  <Button
                    intent="primary"
                    loading={loadPdf.isLoading}
                    small
                    onClick={() => {
                      preview(values)
                    }}
                  >
                    Preview
                  </Button>
                </div>
                <Document
                  file={pdf}
                  onLoadSuccess={({ numPages })=> {
                    setNumPages(numPages)
                  }}
                >
                  {range(1, numPages+1).map(page => {
                    if (page > numPages) return null;
                    return (
                      <div key={page} className="my-2">
                        <Page pageNumber={page} width={Math.min(width, 720)} renderAnnotationLayer={false} />
                      </div>
                    )
                  })}
                </Document>
              </div>
              <Alert
                className="bp4-dark"
                style={{ backgroundColor: "#333" }}
                cancel
                cancelButtonText="Cancel"
                confirmButtonText="Restore"
                icon="undo"
                intent="danger"
                isOpen={restoreConfirmOpen}
                onClose={() => { setRestoreConfirmOpen(false) }}
                onConfirm={async () => {
                  setRestoreConfirmOpen(false)
                  setValues({})
                }}
              >
                <p><span className="font-bold">Restore PDF settings</span></p>
                <p>
                Are you sure you want to restore PDF settings to default?
                </p>
              </Alert>
            </div>
          )
        }}
      </Formik>
    </div>
  )
}

function FormGroupInheritLabel({ label, inherited, inheritName }) {
  return (
    <div>
      <div>{label}</div>
      {inherited && (
        <div className="inline-block bg-indigo-500 rounded px-1 text-xs">from {inheritName}</div>
      )}
    </div>
  )
}