import { MenuItem, Tag } from '@blueprintjs/core'
import { useQuery } from 'react-query'
import { fetcher } from '../../utils'
import { Suggest2 } from "@blueprintjs/select";
import {matchSorter} from 'match-sorter'
import { AiOutlineLink } from 'react-icons/ai';
import { HiDocumentText } from 'react-icons/hi';

export default function DocumentRefBar({ teamId, selectedDocuments, onSelect, onRemove }) {
  const { data: allDocuments } = useQuery(["documents-list"], async () => {
    return await fetcher(`/teams/${teamId}/documents/list?limit=999`)
  })

  return (
    <div className='flex flex-row gap-1 p-1' style={{ color: '#c9c9c9' }}>
      {(selectedDocuments || []).map((document) => {
        return (
          <div key={document.key}>
            <Tag
              className='h-full cursor-pointer'
              key={document.key}
              // minimal
              onRemove={(event) => {
                event.stopPropagation();
                onRemove && onRemove(document)
              }}
              onClick={()=> {
                window.open(`/teams/${teamId}/documents/${document.key}/edit`, '_blank')
              }}
              icon={<AiOutlineLink />}
            >
              {document.title}
            </Tag>
          </div>
        )
      })}
      <Suggest2
        inputProps={{
          placeholder: 'Add Document',
          style: { color: '#c9c9c9', backgroundColor: '#2d2d2d', border: 'none', boxShadow: 'none', height: 24 },
        }}
        items={allDocuments || []}
        itemListPredicate={(query, items) => {
          if (items.length === 0) return [];
          return matchSorter(items, query, { keys: ['key', 'title'] })
        }}
        itemRenderer={(document, props) => {
          return (
            <MenuItem
              active={props.modifiers.active}
              disabled={props.modifiers.disabled}
              rolestructure="listoption"
              key={document.key}
              onClick={props.handleClick}
              onFocus={props.handleFocus}
              text={document.title}
              icon={<HiDocumentText />}
            />
          )
        }}
        onItemSelect={(tag) => {
          onSelect && onSelect(tag)
        }}
        inputValueRenderer={(tag) => {
          return null
        }}
      />
    </div>
  )
}
