/* eslint-disable no-template-curly-in-string */

export function registerMonacoCompletionEmbedBlock(monaco) {
  return monaco.languages.registerCompletionItemProvider('markdown', {
    triggerCharacters: ['{', '%'],
    provideCompletionItems: (model, position) => {
      const range = {
        startLineNumber: position.lineNumber,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column
      }
      const text = model.getValueInRange(range).trim();
      if (model.getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: position.column,
        endLineNumber: position.lineNumber,
        endColumn: position.column + 1,
      }) === '}') {
        range.endColumn = range.endColumn + 1;
      }

      const suggestions = [{
        label: '{%youtube',
        kind: monaco.languages.CompletionItemKind.Function,
        detail: 'embed block - youtube',
        documentation: 'HackNote extended markdown syntax, embed block, youtube',
        insertText: '{%youtube ${1:id} %}\n',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
      }, {
        label: '{%gist',
        kind: monaco.languages.CompletionItemKind.Function,
        detail: 'embed block - gist',
        documentation: 'HackNote extended markdown syntax, embed block, gist',
        insertText: '{%gist ${1:token} %}\n',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
      }, {
        label: '{%figma',
        kind: monaco.languages.CompletionItemKind.Function,
        detail: 'embed block - figma',
        documentation: 'HackNote extended markdown syntax, embed block, figma',
        insertText: '{%figma ${1:token} %}\n',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
      }, {
        label: '{%desmos',
        kind: monaco.languages.CompletionItemKind.Function,
        detail: 'embed block - desmos',
        documentation: 'HackNote extended markdown syntax, embed block, desmos',
        insertText: '{%desmos ${1:id} %}\n',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
      }, {
        label: '{%observable',
        kind: monaco.languages.CompletionItemKind.Function,
        detail: 'embed block - observable',
        documentation: 'HackNote extended markdown syntax, embed block, observable',
        insertText: '{%observable ${1:token} ${2:cell} ${3:width} ${4:height} %}\n',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
      }]

      const prefixes = [
        "{%",
        "{%youtube",
        "{%gist",
        "{%figma",
        "{%desmos",
        "{%observable",
      ]
      const matchedPrefixIndex = prefixes.findIndex((prefix) => prefix.startsWith(text.toLowerCase()))

      if (matchedPrefixIndex === -1) return { suggestions: [] }
      if (matchedPrefixIndex === 0) return { suggestions }
      return {
        suggestions: [suggestions[matchedPrefixIndex-1]]
      }
    },
  });
}