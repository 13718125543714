/* eslint-disable react/jsx-no-target-blank */
import { Alert, Button, Menu, MenuItem, Tab, Tabs } from '@blueprintjs/core'
import { Popover2 as Popover } from '@blueprintjs/popover2'
import { chain, groupBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlinePlus } from 'react-icons/ai'
import { BiHide, BiPaperPlane, BiRecycle } from 'react-icons/bi'
import { CgMoreAlt, CgTemplate } from 'react-icons/cg'
import { FaFolderPlus } from 'react-icons/fa'
import { MdSyncProblem } from 'react-icons/md'
import { RiCheckDoubleFill, RiLockPasswordLine } from 'react-icons/ri'
import { TbPencilOff } from 'react-icons/tb'
import { useMutation, useQuery } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import Split from 'react-split'
import { useTitle } from 'react-use'
import { useRecoilState } from 'recoil'
import * as Y from 'yjs'
import AuthorBar from '../../components/authorBar'
import Collaborators from '../../components/Collaborators'
import { ContextMenuItem, ContextMenuSeparator } from '../../components/contextMenu'
import ContributorsBar from '../../components/contributorsBar'
import CreateNoteFromTemplateDialog from '../../components/createNoteFromTemplateDialog'
import DeletedNotes from '../../components/deletedNotes'
import Editor from '../../components/editor'
import FolderList from '../../components/folder/folderList'
import Header from '../../components/header'
import HistoryNotes from '../../components/historyNotes'
import Note from '../../components/note'
import PDF from '../../components/pdf'
import CategoryRefBar from '../../components/refbar/categoryRefBar'
import DocumentRefBar from '../../components/refbar/documentRefBar'
import TagRefBar from '../../components/refbar/tagRefBar'
import { TeamKBarXSetup, useOnNoteDuplicated } from '../../components/teamKBarSetup'
import TimeAgo, { formatRelative } from '../../components/TimeAgo'
import Title from '../../components/Title'
import ViewLinkPasswordSetting from '../../components/ViewLinkPasswordSetting'
import useDebounceValue from '../../hooks/useDecounceValue'
import { EditorView, editorViewStateFamily, EditorViewUISet } from '../../hooks/useEditorConfig'
import { useProfile } from '../../hooks/useProfile'
import { useNoteUISetting, useSidebarSize } from '../../hooks/useUISetting'
import { GenericErrorBoundary } from '../../module/chaos/GenericErrorBoundary'
import { generateNKeysBetween } from '../../module/fractional-indexing'
import { KBarX } from '../../module/kbar/KBarX'
import { useRegisterActions } from '../../module/kbar/KBarXProvider'
import {
  DocumentProvider,
  getDoc,
  getSortedArray,
  getSortedArray2,
  insertMap,
  moveMap,
  useArray,
  useDoc,
  useForceUpdate,
  useMap
} from '../../module/y/hook'
import { contentToTitle, fetcher, generateKey, poster, reportUrl } from '../../utils'

const NEW_NOTE_CONTENT = '# New Note'
const NEW_PRIVATE_NOTE = ''

const createYNoteFolder = (team, project) => {
  const m = new Y.Map()
  Object.entries({
    key: generateKey(),
    name: 'Untitled Folder',
    team,
    project,
  }).forEach(([key, value]) => {
    m.set(key, value)
  })
  return m
}

const createYNoteBaseOn = (baseOn) => {
  const m = new Y.Map()
  Object.entries({
    ...baseOn,
    content: new Y.Text(baseOn.content),
    privateNote: new Y.Text(baseOn.privateNote),
    key: generateKey(),
    published: 0,
    updatedAt: Date.now(),
    deletedAt: null,
  }).forEach(([key, value]) => {
    m.set(key, value)
  })
  return m
}
const createYNote = (team, project, author) => {
  const m = new Y.Map()
  Object.entries({
    key: generateKey(),
    privateNote: new Y.Text(NEW_PRIVATE_NOTE),
    content: new Y.Text(NEW_NOTE_CONTENT),
    published: 0,
    team,
    project,
    author,
    contributors: author ? [author] : [],
  }).forEach(([key, value]) => {
    m.set(key, value)
  })
  return m
}
const createNoteOnServe = (note) => poster(`/teams/${note.team}/projects/${note.project}/notes`, note)
const updateProjectOnServer = (teamId, projectId, title) => poster(`/teams/${teamId}/projects/${projectId}`, { title }, 'PUT')
const updateNotePosOnServer = (teamId, noteId, pos) => poster(`/teams/${teamId}/notes/${noteId}/pos`, { pos }, 'PUT')
const updateProjectNoteFolderPosOnServer = (teamId, projectId, folderId, pos) =>
  poster(`/teams/${teamId}/projects/${projectId}/folders/${folderId}/pos`, { pos }, 'PUT')
const createProjectNoteFolderOnServer = (teamId, projectId, doc) => poster(`/teams/${teamId}/projects/${projectId}/folders`, doc, 'POST')
const updateProjectNoteFolderOnServer = (teamId, projectId, folderId, doc) =>
  poster(`/teams/${teamId}/projects/${projectId}/folders/${folderId}`, doc, 'PUT')
const deleteProjectNoteFolderOnServer = (teamId, projectId, folderId) =>
  poster(`/teams/${teamId}/projects/${projectId}/folders/${folderId}`, {}, 'DELETE')
const updateNoteFolderOnServer = (teamId, projectId, noteId, folder) =>
  poster(`/teams/${teamId}/projects/${projectId}/notes/${noteId}/folder`, { folder }, 'PUT')

function EditMain({ teamId, projectId }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const { profile } = useProfile()
  const yDoc = useDoc()
  const [historyNotesDrawerSelectedNote, setHistoryNotesDrawerSelectedNote] = useState(null)
  const [openCreateNoteFromTemplateDialog, setOpenCreateNoteFromTemplateDialog] = useState(false)
  const [deletedNotesDrawerShow, setDeletedNotesDrawerShow] = useState(false)
  const [viewLinkPasswordShow, setViewLinkPasswordShow] = useState(false)
  const yMeta = useMap('META')
  const yFolders = useArray('FOLDERS')
  const yFolderOpened = useMap(`FOLDER_OPENED:${profile.id}`)
  const yNotes = useArray('NOTES', true, 1000)
  const notes = yNotes.toArray().map((i) => i.toJSON())
  const folders = yFolders.toArray().map((i) => i.toJSON())
  const sortedFolders = getSortedArray(yFolders || []).map((yd) => yd.toJSON())
  const lastNoteFolderPos = useMemo(() => sortedFolders[sortedFolders.length - 1]?.pos, [sortedFolders])

  const forceUpdate = useForceUpdate()
  const [editingNote, _setEditingNote] = useState(null)
  const [previewType, setPreviewType] = useState('md')
  const orderedPlainNotes = useMemo(() => {
    const _yNotes = yNotes?.toArray() || []
    const sortedNotesGroupedByFolder = chain(_yNotes)
      .groupBy((i) => i.get('folder') || 'null')
      .mapValues((notes) => {
        return getSortedArray2(notes).map((yn) => ({ ...yn.toJSON(), index: yn.index }))
      })
      .value()
    return chain(sortedFolders)
      .map((folder) => folder.key)
      .push('null')
      .map((folderKey) => {
        return sortedNotesGroupedByFolder[folderKey] || null
      })
      .compact()
      .flatten()
      .value()
  }, [sortedFolders, yNotes])

  // fix wrong note id
  useEffect(() => {
    if (!yNotes.length) return
    const notes = yNotes.state.map((n, i) => ({ ...n, i: i }))
    const conflictIdNotes = Object.values(groupBy(notes, (n) => n.n))
      .filter((n) => n.length > 1)
      .map((n) => n.slice(1))
      .flat()

    yDoc.transact(() => {
      const latestId = Math.max(...notes.map(n => n.n ?? 0)) + 1
      conflictIdNotes.forEach((note, i) => {
        yNotes.get(note.i).set('n', latestId + i)
      })
    })
  }, [yNotes])

  const setEditingNote = (note) => {
    if (note === null) return _setEditingNote(null)
    let noteId = note.get('n')
    if (!Number.isInteger(noteId) || noteId < 0) {
      noteId = Math.max([...yNotes.map((y) => y.get('n')).filter((i) => i != null)]) + 1
      console.log('new note id is created: ', noteId)
      note.set('n', noteId)
    }
    searchParams.set('n', noteId)
    setSearchParams(searchParams)
    _setEditingNote(note)
  }
  const publishIndexMap = useMemo(
    () => orderedPlainNotes.filter((n) => !!n.published).reduce((r, n, i) => ({ ...r, [n.key]: i + 1 }), {}),
    [orderedPlainNotes]
  )
  const editingNoteVariables = useMemo(
    () =>
      editingNote
        ? {
          publishIndex: publishIndexMap[editingNote.toJSON().key] ?? '_',
        }
        : {},
    [publishIndexMap, editingNote]
  )
  const lastNotePos = useMemo(() => orderedPlainNotes[orderedPlainNotes.length - 1]?.pos, [orderedPlainNotes])
  useEffect(() => {
    const n = +searchParams.get('n')
    if (!editingNote && n != null) {
      yNotes.forEach((yn) => {
        if (yn.get('n') === n) {
          setEditingNote(yn)
        }
      })
    }
  }, [searchParams, orderedPlainNotes])
  const projectTitle = yMeta.state.PROJECT_TITLE ?? '...'
  const teamName = yMeta.state.TEAM_NAME ?? '...'
  const [dbNotes, setDbNotes] = useState([])
  useEffect(() => {
    fetcher(`/teams/${teamId}/projects/${projectId}/notes`).then((notes) => {
      setDbNotes(notes)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useTitle(projectTitle)

  const pushNote = (note, lastPos) => {
    yDoc.transact(() => {
      note.set('pos', generateNKeysBetween(lastPos ?? null, null, 1))
      note.set('n', Math.max(...[...yNotes.map((yn) => (yn.get('n') ?? 0) + 1), yNotes.length]))
      insertMap(yNotes, note, yNotes.length)
    })
    setEditingNote(note)
    createNoteOnServe(note.toJSON())
      .then((note) => setDbNotes((notes) => [...notes, { ...note, updatedAt: Date.now() }]))
  }

  const createNewNoteFolder = () => {
    const newFolder = createYNoteFolder(teamId, projectId)
    yDoc.transact(() => {
      newFolder.set('pos', generateNKeysBetween(lastNoteFolderPos ?? null, null, 1))
      insertMap(yFolders, newFolder, yFolders.length)
    })
    createProjectNoteFolderOnServer(teamId, projectId, newFolder.toJSON())
  }

  const createNewNote = (folderKey) => {
    const newNote = createYNote(teamId, projectId, profile.id)
    if (folderKey) {
      newNote.set('folder', folderKey)
    }
    pushNote(newNote, lastNotePos)
  }

  const createNewNoteFromTemplate = (data) => {
    const newNote = createYNote(teamId, projectId, profile.id)
    newNote.set('content', new Y.Text(data.content))
    newNote.set('template', data.template)
    pushNote(newNote, lastNotePos)
  }

  const createNewNoteFromDeleted = (note) => {
    const newNote = createYNote(teamId, projectId, profile.id)
    newNote.set('content', new Y.Text(note.content))
    newNote.set('privateNote', new Y.Text(note.privateNote))
    newNote.set('author', note.author)
    newNote.set('contributors', note.contributors || [])
    pushNote(newNote, lastNotePos)
    return newNote
  }

  const transferNoteAuthor = (note, newAuthor) => {
    if (!note) return
    const author = note.get('author')
    if (author && profile && author !== profile.id) return

    yDoc.transact(() => {
      note.set('author', newAuthor)
    })
  }

  useRegisterActions(
    [
      {
        id: 'newNote',
        name: 'New Note',
        shortcut: ['n'],
        keywords: 'new create',
        perform: () => {
          createNewNote()
        },
      },
    ],
    [lastNotePos]
  )

  useOnNoteDuplicated((note) => {
    return pushNote(
      createYNoteBaseOn({
        ...note,
        author: profile?.id,
        contributors: profile?.id ? [profile.id] : [],
      })
    )
  })

  const { data: linkedDocuments, refetch: refetchLinkedDocuments } = useQuery(
    ['NoteLinkedDocuments', editingNote?.get('key')],
    async () => {
      return await fetcher(`/teams/${teamId}/notes/${editingNote.get('key')}/documents/list`)
    },
    {
      enabled: !!editingNote,
    }
  )
  const linkNoteDocument = useMutation(async (args) => {
    return await poster(
      `/teams/${teamId}/notes/${args.noteId}/documentIds`,
      {
        documentIds: args.documentIds,
      },
      'PUT'
    )
  })
  const unlinkNoteDocument = useMutation(async (args) => {
    return await poster(
      `/teams/${teamId}/notes/${args.noteId}/documentIds`,
      {
        documentIds: args.documentIds,
      },
      'DELETE'
    )
  })

  const { data: linkedTags, refetch: refetchLinkedTags } = useQuery(
    ['NoteLinkedTags', editingNote?.get('key')],
    async () => {
      return await fetcher(`/teams/${teamId}/notes/${editingNote.get('key')}/tags/list`)
    },
    {
      enabled: !!editingNote,
    }
  )
  const linkNoteTag = useMutation(async (args) => {
    return await poster(
      `/teams/${teamId}/notes/${args.noteId}/tags`,
      {
        tags: args.tags,
      },
      'PUT'
    )
  })
  const unlinkNoteTag = useMutation(async (args) => {
    return await poster(
      `/teams/${teamId}/notes/${args.noteId}/tags`,
      {
        tags: args.tags,
      },
      'DELETE'
    )
  })

  const { data: selectedCategory, refetch: refetchSelectedCategory } = useQuery(
    ['NoteSelectedCategory', editingNote?.get('key')],
    async () => {
      const result = await fetcher(`/teams/${teamId}/notes/${editingNote.get('key')}/category`)
      return result.category || null
    },
    {
      enabled: !!editingNote,
    }
  )
  const setSelectedCategory = useMutation(async (args) => {
    return await poster(
      `/teams/${teamId}/notes/${args.noteId}/category`,
      {
        category: args.category,
      },
      'PUT'
    )
  })
  const deleteSelectedCategory = useMutation(async (args) => {
    return await poster(`/teams/${teamId}/notes/${args.noteId}/category`, {}, 'DELETE')
  })
  const { size, setSize } = useSidebarSize(projectId)
  const [deletingNote, setDeletingNote] = useState(null)

  const { isSyncStatusShown, toggleIsSyncStatusShown, isNoteTitleWrapped, toggleIsNoteTitleWrapped, publishStyle, togglePublishStyle } =
    useNoteUISetting()

  const onProjectTitleChange = (title) => {
    yMeta.set('PROJECT_TITLE', title)
    getDoc(`TEAM_DATA-TEAM:${teamId}`, { type: 'TEAM_DATA', teamId }, undefined, (doc) => {
      doc
        .getArray('PROJECTS')
        .toArray()
        .find((d) => d.get('key') === projectId)
        ?.set('title', title)
    })
    updateProjectOnServer(teamId, projectId, title)
  }
  const breads = useMemo(
    () => [
      { title: teamName, link: `/teams/${teamId}` },
      { title: projectTitle, onChange: onProjectTitleChange },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamName]
  )
  const [editorView, setEditorView] = useRecoilState(editorViewStateFamily(projectId))
  const EditorViewUI = EditorViewUISet[editorView]
  const debounceEditorView = useDebounceValue(editorView, 400)
  const editorViewSize = useMemo(() => {
    switch (debounceEditorView) {
      case EditorView.EDIT:
        return [100, 0]
      case EditorView.PREVIEW:
        return [0, 100]
      default:
        return [50, 50]
    }
  }, [debounceEditorView])
  return (
    <TeamKBarXSetup>
      <KBarX>
        <Header
          renderLeft={() => {
            return <Title title={EditorViewUI.label}>
              <Button icon={<EditorViewUI.Icon color='#ddd' />} minimal onClick={() =>
                setEditorView((editorView + 1) % 3)
              }></Button>
            </Title>
          }}
          displayDocConnectStatus teamId={teamId} breads={breads} />
        <Alert
          className='bp4-dark'
          style={{ backgroundColor: '#333' }}
          cancel
          cancelButtonText='Cancel'
          confirmButtonText='Delete Note'
          icon='delete'
          intent='danger'
          isOpen={!!deletingNote}
          onClose={() => {
            setDeletingNote(null)
          }}
          onConfirm={async () => {
            if (!deletingNote) return
            const yNoteIndex = yNotes.toArray().findIndex((i) => i.get('key') === deletingNote.key)
            if (yNoteIndex === -1) return
            poster(`/teams/${teamId}/notes/${deletingNote.key}`, {}, 'DELETE')
            const editingKey = editingNote ? editingNote.get('key') : null
            yNotes.delete(yNoteIndex, 1)
            if (editingKey === deletingNote.key) {
              setEditingNote(null)
            }
          }}
        >
          <p>
            <span className='font-bold'>Confirm Delete Note</span>
          </p>
          <p>{deletingNote ? contentToTitle(deletingNote.content) : null}</p>
        </Alert>
        <Split className='flex-grow overflow-auto split' sizes={size} onDragEnd={setSize}>
          <div className='flex flex-col h-full overflow-hidden'>
            <div className='flex flex-row items-center justify-between p-1' style={{ backgroundColor: '#2d2d2d' }}>
              <div>
                <Title title='Create New Note'>
                  <Button
                    minimal
                    onClick={() => {
                      createNewNote()
                      document.activeElement.blur()
                    }}
                  >
                    <AiOutlinePlus className='text-white' />
                  </Button>
                </Title>
                <Title title='Create New Folder'>
                  <Button
                    minimal
                    onClick={() => {
                      createNewNoteFolder()
                      document.activeElement.blur()
                    }}
                  >
                    <FaFolderPlus className='text-white' />
                  </Button>
                </Title>
                <Title title='Create Note From Template'>
                  <Button
                    minimal
                    onClick={() => {
                      setOpenCreateNoteFromTemplateDialog(true)
                    }}
                  >
                    <CgTemplate className='text-white' />
                  </Button>
                </Title>
              </div>
              <div className='flex flex-row items-center'>
                <Title title='Notes Recycle'>
                  <Button
                    minimal
                    onClick={() => {
                      setDeletedNotesDrawerShow(true)
                    }}
                  >
                    <BiRecycle className='text-white' />
                  </Button>
                </Title>
                <Title title='View Link Password'>
                  <Button
                    minimal
                    onClick={() => {
                      setViewLinkPasswordShow(true)
                    }}
                  >
                    <RiLockPasswordLine className='text-white' />
                  </Button>
                </Title>
                <Popover
                  content={
                    <Menu>
                      <MenuItem
                        icon={isSyncStatusShown ? 'small-tick' : 'small-square'}
                        text='Show sync status for note'
                        onClick={toggleIsSyncStatusShown}
                      />
                      <MenuItem
                        icon={isNoteTitleWrapped ? 'small-tick' : 'small-square'}
                        text='Wrap note title'
                        onClick={toggleIsNoteTitleWrapped}
                      />
                      <MenuItem
                        icon={publishStyle ? 'small-tick' : 'small-square'}
                        text='Minimize publish status UI'
                        onClick={togglePublishStyle}
                      />
                      <MenuItem
                        icon={<div><TbPencilOff /></div>}
                        text='Disallow write to published'
                        onClick={() => {
                          yNotes.toArray().forEach((yNote) => {
                            if (!yNote.get('published')) return;
                            yNote.set('locked', true)
                          })
                          forceUpdate()
                        }}
                      />
                    </Menu>
                  }
                  position='bottom-right'
                  minimal
                  popoverClassName='bp4-dark'
                >
                  <Button minimal>
                    <CgMoreAlt className='text-white' />
                  </Button>
                </Popover>
              </div>
            </div>
            <div className='flex-1 overflow-y-scroll'>
              <FolderList
                entityName={'Note'}
                activeEntityKey={editingNote?.get('key')}
                folders={folders}
                folderOpened={yFolderOpened.state}
                entities={notes}
                onEntityMenu={(note) => {
                  const dbNote = dbNotes.find((n) => n.key === note.key)
                  const noteLink = `${reportUrl}/${teamId}/${projectId}#n=${note.n}`
                  return (
                    <GenericErrorBoundary>
                      <ContextMenuItem>
                        {note.key}
                      </ContextMenuItem>
                      <ContextMenuItem>
                        <a href={noteLink} target='_blank'>
                          Open View Page
                        </a>
                      </ContextMenuItem>

                      <ContextMenuItem
                        onClick={() => {
                          setHistoryNotesDrawerSelectedNote(note)
                        }}
                      >
                        Version History
                      </ContextMenuItem>
                      <ContextMenuItem
                        onClick={() => {
                          const copyPromise = navigator.clipboard.writeText(noteLink)
                          toast.promise(copyPromise, {
                            success: `View link Copied!`,
                          })
                        }}
                      >
                        Copy View Link
                      </ContextMenuItem>
                      <ContextMenuItem
                        onClick={async () => {
                          // TODO: replace api call
                          poster(`/teams/${teamId}/notes/${note.key}/${note.published ? 'unpublish' : 'publish'}`, {}, 'POST')
                          const yNote = yNotes.toArray().find((i) => i.get('key') === note.key)
                          yNote?.set('published', !note.published)
                          forceUpdate()
                        }}
                      >
                        {note.published ? (
                          <span className='text-yellow-800'>Undo Publish</span>
                        ) : (
                          <span className='text-green-800'>Publish</span>
                        )}
                      </ContextMenuItem>
                      {!!note.published && (
                        <ContextMenuItem
                          onClick={async () => {
                            // TODO: replace api call
                            poster(`/teams/${teamId}/notes/${note.key}/${note.hidden ? 'unhide' : 'hide'}`, {}, 'POST')
                            const yNote = yNotes.toArray().find((i) => i.get('key') === note.key)
                            yNote?.set('hidden', !note.hidden)
                            forceUpdate()
                          }}
                        >
                          {note.hidden ? (
                            <span>Undo Hide</span>
                          ) : (
                            <span>Hide</span>
                          )}
                        </ContextMenuItem>
                      )}
                      <ContextMenuItem
                        onClick={async () => {
                          const yNote = yNotes.toArray().find((i) => i.get('key') === note.key)
                          yNote?.set('locked', !note.locked)
                          forceUpdate()
                        }}
                      >
                        {note.locked ? (
                          <span>Writable</span>
                        ) : (
                          <span>Read only</span>
                        )}
                      </ContextMenuItem>
                      <ContextMenuItem
                        onClick={() => {
                          pushNote(
                            createYNoteBaseOn({
                              ...note,
                              author: profile?.id,
                              contributors: profile?.id ? [profile.id] : [],
                            })
                          )
                        }}
                      >
                        Duplicate
                      </ContextMenuItem>
                      <ContextMenuSeparator />
                      <ContextMenuItem
                        onClick={() => {
                          poster(`/teams/${teamId}/projects/${projectId}/notes/${note.key}`, { ...note, updatedAt: Date.now() }, 'PUT')
                            .then(async (note) => {
                              setDbNotes((notes) => notes.map((n) => (n.key === note.key ? note : n)))
                            })
                            .catch((err) => toast.error('Fail to update editing note!'))
                        }}
                      >
                        <div>
                          <div>Sync DB</div>
                          <div className='mt-1 text-xs text-gray-600'>
                            last: <TimeAgo date={dbNote?.updatedAt} />
                          </div>
                        </div>
                      </ContextMenuItem>
                      <ContextMenuSeparator />
                      <ContextMenuItem
                        onClick={() => {
                          setDeletingNote(note)
                        }}
                      >
                        <span className='text-red-800'>Delete</span>
                      </ContextMenuItem>
                    </GenericErrorBoundary>
                  )
                }}
                onEntityStyle={(note) => {
                  const active = note.key === editingNote?.get('key')
                  if (publishStyle) {
                    if (note.published) {
                      return {
                        borderRight: '4px solid #008000',
                      }
                    } else {
                      return {
                        borderRight: '4px solid transparent',
                      }
                    }
                  } else {
                    if (note.published) {
                      return {
                        backgroundColor: active ? '#00800040' : '#00800070',
                      }
                    }
                  }
                }}
                onEntityRender={(note, index) => {
                  const dbNote = dbNotes.find((n) => n.key === note.key)
                  const isSynced = dbNote && dbNote?.content === note.content
                  const title = contentToTitle(note.content, { publishIndex: publishIndexMap[note.key] ?? '' })
                  return (
                    <div className='relative flex flex-row items-center w-full cursor-pointer'>
                      <div className={`flex-1 ${isNoteTitleWrapped ? 'whitespace-pre-wrap' : 'truncate '}`}>{title}</div>
                      <Collaborators avatarProps={{ size: 18, gap: -3, className: 'mr-2' }} myId={profile?.id} noteKey={note.key} />
                      {note.locked && (
                        <div className='flex items-center justify-center w-4 h-4 mr-1 text-xs text-center rounded-sm'>
                          <TbPencilOff className={note.locked ? "text-red-300" : ""} />
                        </div>
                      )}
                      {!!(note.published && note.hidden) && (
                        <div className='flex items-center justify-center w-4 h-4 mr-1 text-xs text-center rounded-sm'>
                          <BiHide className={"text-red-300"} />
                        </div>
                      )}
                      <div className='w-4 h-4 mr-1 text-xs text-center bg-blue-900 rounded-sm '>{note.n}</div>
                      {isSyncStatusShown && (
                        <div className='flex flex-row items-center flex-0' title={dbNote?.updatedAt && formatRelative(dbNote?.updatedAt)}>
                          {isSynced ? <RiCheckDoubleFill className='text-green-500' /> : <MdSyncProblem className='text-red-400' />}
                        </div>
                      )}
                    </div>
                  )
                }}
                onEntityTooltipRender={(note, index) => {
                  const dbNote = dbNotes.find((n) => n.key === note.key)
                  const isSynced = dbNote && dbNote?.content === note.content
                  const title = contentToTitle(note.content, { publishIndex: publishIndexMap[note.key] ?? '' })
                  return (
                    <div className='p-2 bg-white rounded' style={{ maxWidth: 260 }}>
                      <div>
                        <span className='inline-flex items-center justify-center w-4 h-4 mr-1 text-xs text-white bg-blue-900 rounded-sm'>
                          {note.n}
                        </span>
                        <span className='inline-flex items-center justify-center w-4 h-4 mr-1 text-xs text-white bg-pink-900 rounded-full'>
                          {note.fullIndex}
                        </span>
                        <span>{title}</span>
                      </div>
                      <div className='mt-2'>
                        <div className='flex flex-row items-center flex-0'>
                          <div style={{ width: 16, height: 16 }} className='flex items-center justify-center'>
                            {isSynced ? <RiCheckDoubleFill className='text-green-500' /> : <MdSyncProblem className='text-red-400' />}
                          </div>
                          <div className='ml-1 text-sm text-gray-600'>
                            <TimeAgo date={dbNote?.updatedAt} />
                          </div>
                        </div>
                        {!!note.published && (
                          <div className='flex flex-row items-center flex-0'>
                            <div style={{ width: 16, height: 16 }} className='flex items-center justify-center'>
                              <BiPaperPlane className='text-green-500' size={12} />
                            </div>
                            <div className='ml-1 text-sm text-gray-600'>published</div>
                          </div>
                        )}
                        {note.locked && (
                          <div className='flex flex-row items-center flex-0'>
                            <div style={{ width: 16, height: 16 }} className='flex items-center justify-center'>
                              <TbPencilOff className={note.locked ? "text-red-300" : ""} />
                            </div>
                            <div className='ml-1 text-sm text-gray-600'>read only</div>
                          </div>
                        )}
                        {!!(note.published && note.hidden) && (
                          <div className='flex flex-row items-center flex-0'>
                            <div style={{ width: 16, height: 16 }} className='flex items-center justify-center'>
                              <BiHide className={"text-red-300"} />
                            </div>
                            <div className='ml-1 text-sm text-gray-600'>hidden in published</div>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                }}
                onEntityClick={(entityKey) => {
                  if (entityKey === editingNote?.get('key')) return
                  const yDocument = yNotes?.toArray().find((i) => i.get('key') === entityKey)
                  if (!yDocument) return
                  setEditingNote(yDocument)
                }}
                onEntityCreateInFolder={(folderKey) => {
                  createNewNote(folderKey)
                  document.activeElement.blur()
                }}
                onFolderDelete={(folderKey) => {
                  const yFolderIndex = yFolders?.toArray().findIndex((i) => i.get('key') === folderKey)
                  if (yFolderIndex === -1) return
                  yDoc.transact(() => {
                    for (const yDocument of yNotes.toArray()) {
                      if (yDocument.get('folder') === folderKey) {
                        yDocument.set('folder', null)
                      }
                    }
                    yFolders.delete(yFolderIndex, 1)
                  })
                  deleteProjectNoteFolderOnServer(teamId, projectId, folderKey)
                }}
                onFolderOpened={(folderKey, opened) => {
                  yFolderOpened.set(folderKey, opened)
                }}
                onFolderNameChange={(folderKey, name) => {
                  const yFolder = yFolders?.toArray().find((i) => i.get('key') === folderKey)
                  if (!yFolder) return
                  yDoc.transact(() => {
                    yFolder.set('name', name)
                  })
                  updateProjectNoteFolderOnServer(teamId, projectId, folderKey, { name })
                }}
                onFolderPosChange={(folderKey, toIndex) => {
                  const yFolder = yFolders.toArray().find((i) => i.get('key') === folderKey)
                  if (!yFolder) return
                  const pos = moveMap(yFolders, yFolder, toIndex)
                  updateProjectNoteFolderPosOnServer(teamId, projectId, folderKey, pos)
                  forceUpdate()
                }}
                onEntityFolderAndPosChange={(entityKey, folderKey, toIndex) => {
                  const yNote = yNotes?.toArray().find((i) => i.get('key') === entityKey)
                  if (!yNote) return
                  if (!folderKey) {
                    let pos
                    yDoc.transact(() => {
                      yNote.set('folder', null)
                      pos = moveMap(yNotes, yNote, toIndex, (i) => !i.get('folder'))
                    })
                    forceUpdate()
                    if (pos) {
                      updateNotePosOnServer(teamId, yNote.get('key'), pos)
                      updateNoteFolderOnServer(teamId, projectId, yNote.get('key'), null)
                    }
                  } else {
                    let pos
                    yDoc.transact(() => {
                      yNote.set('folder', folderKey)
                      pos = moveMap(yNotes, yNote, toIndex, (i) => i.get('folder') === folderKey)
                    })
                    forceUpdate()
                    if (pos) {
                      updateNotePosOnServer(teamId, yNote.get('key'), pos)
                      updateNoteFolderOnServer(teamId, projectId, yNote.get('key'), folderKey)
                    }
                  }
                }}
              />
            </div>
          </div>
          <div className='flex flex-col flex-grow'>
            <Split className='z-0 flex-grow overflow-auto split' sizes={editorViewSize} minSize={[0, 0]} expandToMin={true}>
              <div>
                <GenericErrorBoundary>
                  {editingNote ? (
                    <Editor
                      showCollaborator
                      note={editingNote}
                      onDbNoteUpdate={(note) => {
                        setDbNotes((notes) => notes.map((n) => (n.key === note.key ? note : n)))
                      }}
                    />
                  ) : (
                    <div className='flex items-center justify-center h-full overflow-hidden'>
                      <p className='text-white opacity-50'>Command + K for Quick Actions</p>
                    </div>
                  )}
                </GenericErrorBoundary>
              </div>
              <div className='h-full overflow-auto'>
                <Tabs
                  id='previewTabs'
                  onChange={(tabId) => {
                    setPreviewType(tabId)
                  }}
                  selectedTabId={previewType}
                  className='justify-center px-2'
                >
                  <Tab
                    id='md'
                    title='Markdown'
                    style={{ color: 'white' }}
                    panel={
                      <div className='flex justify-center'>
                        <GenericErrorBoundary>
                          {editingNote && editingNote.get('content') ? (
                            <Note noteContent={editingNote.get('content').toJSON()} variables={editingNoteVariables} />
                          ) : null}
                        </GenericErrorBoundary>
                      </div>
                    }
                  />
                  <Tab
                    id='pdf'
                    title='PDF'
                    style={{ color: 'white' }}
                    panel={
                      <div>
                        {editingNote && (
                          <div key={editingNote.get('key')}>
                            <PDF
                              projectTitle={projectTitle}
                              teamId={teamId}
                              projectId={projectId}
                              noteId={editingNote.get('key')}
                              note={editingNote.get('content')?.toJSON()}
                            />
                          </div>
                        )}
                      </div>
                    }
                  />
                </Tabs>
              </div>
            </Split>
            <div>
              <div
                className='flex flex-row items-center justify-between'
                style={{ backgroundColor: '#2d2d2d', borderTop: '1px solid rgb(59, 59, 59)' }}
              >
                <DocumentRefBar
                  teamId={teamId}
                  selectedDocuments={linkedDocuments}
                  onSelect={async (document) => {
                    if (!editingNote) return
                    await linkNoteDocument.mutateAsync({
                      noteId: editingNote.get('key'),
                      documentIds: [document.key],
                    })
                    await refetchLinkedDocuments()
                  }}
                  onRemove={async (document) => {
                    if (!editingNote) return
                    await unlinkNoteDocument.mutateAsync({
                      noteId: editingNote.get('key'),
                      documentIds: [document.key],
                    })
                    await refetchLinkedDocuments()
                  }}
                />
                <div className='flex flex-row items-center justify-end'>
                  <AuthorBar
                    teamId={teamId}
                    author={editingNote?.get('author')}
                    onTransfer={(member) => {
                      transferNoteAuthor(editingNote, member)
                    }}
                  />
                  <ContributorsBar teamId={teamId} contributors={editingNote?.get('contributors')} />
                </div>
              </div>
              <div className='flex flex-row items-center'>
                <div>
                  <CategoryRefBar
                    teamId={teamId}
                    selectedCategory={selectedCategory}
                    onSelect={async (category) => {
                      if (!editingNote) return
                      await setSelectedCategory.mutateAsync({
                        noteId: editingNote.get('key'),
                        category: category.key,
                      })
                      await refetchSelectedCategory()
                    }}
                    onRemove={async () => {
                      if (!editingNote) return
                      await deleteSelectedCategory.mutateAsync({
                        noteId: editingNote.get('key'),
                      })
                      await refetchSelectedCategory()
                    }}
                  />
                </div>
                <div className='flex-1'>
                  <TagRefBar
                    teamId={teamId}
                    selectedTags={linkedTags}
                    onSelect={async (tag) => {
                      if (!editingNote) return
                      await linkNoteTag.mutateAsync({
                        noteId: editingNote.get('key'),
                        tags: [tag.key],
                      })
                      await refetchLinkedTags()
                    }}
                    onRemove={async (tag) => {
                      if (!editingNote) return
                      await unlinkNoteTag.mutateAsync({
                        noteId: editingNote.get('key'),
                        tags: [tag.key],
                      })
                      await refetchLinkedTags()
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Split>
        {historyNotesDrawerSelectedNote && (
          <HistoryNotes
            isOpen={!!historyNotesDrawerSelectedNote}
            onClose={() => {
              setHistoryNotesDrawerSelectedNote(null)
            }}
            teamId={historyNotesDrawerSelectedNote.team}
            noteId={historyNotesDrawerSelectedNote.key}
            note={historyNotesDrawerSelectedNote}
            onRestored={(content, privateNote) => {
              const i = yNotes.toArray().findIndex((n) => n.get('key') === historyNotesDrawerSelectedNote.key)
              if (i < 0) return
              const yNote = yNotes.get(i)
              yDoc.transact(() => {
                yNote.set('content', new Y.Text(content))
                yNote.set('privateNote', new Y.Text(privateNote))
              })
            }}
          />
        )}
        {deletedNotesDrawerShow && (
          <DeletedNotes
            isOpen={deletedNotesDrawerShow}
            onClose={() => {
              setDeletedNotesDrawerShow(false)
            }}
            teamId={teamId}
            projectId={projectId}
            onRestore={(note) => {
              return createNewNoteFromDeleted(note)
            }}
          />
        )}
        {viewLinkPasswordShow && (<ViewLinkPasswordSetting isOpen={viewLinkPasswordShow} onClose={() => setViewLinkPasswordShow(false)} teamId={teamId} projectId={projectId} />)}
        {openCreateNoteFromTemplateDialog && (
          <CreateNoteFromTemplateDialog
            isOpen={openCreateNoteFromTemplateDialog}
            onClose={(event) => {
              setOpenCreateNoteFromTemplateDialog(false)
            }}
            teamId={teamId}
            projectId={projectId}
            onCreate={(data) => {
              createNewNoteFromTemplate(data)
              setOpenCreateNoteFromTemplateDialog(false)
            }}
          />
        )}
      </KBarX>
    </TeamKBarXSetup>
  )
}

export default function EditPage() {
  const { projectId, teamId } = useParams()
  const docName = `PROJECT_DATA-TEAM:${teamId}::PROJECT:${projectId}`
  const docMeta = {
    type: 'PROJECT_DATA',
    teamId,
    projectId,
  }
  return (
    <DocumentProvider docName={docName} docMeta={docMeta}>
      <EditMain teamId={teamId} projectId={projectId} />
    </DocumentProvider>
  )
}
