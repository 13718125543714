import _ from "lodash";
import toast from "react-hot-toast";
import { DID_SELECT_NON_EMPTY_TEXT_CONTEXT_KEY } from "../../components/yeditor";
import { importScriptModule } from "../../utils/importScriptModule";
import { openai } from "../openai";

export function registerMonacoScriptAction(monaco, editor, actions) {
  for (let index = 0; index < actions.length; index++) {
    const action = actions[index];
    const precondition = (() => {
      switch (action.mode) {
        case "brush": {
          return `${DID_SELECT_NON_EMPTY_TEXT_CONTEXT_KEY}`
        }
        case "complete": {
          return `${DID_SELECT_NON_EMPTY_TEXT_CONTEXT_KEY}`
        }
        default: {
          return null
        }
      }
    })()
    editor.addAction({
      id: `script-action-${action.key}`,
      label: `Script: ${action.label}`,
      contextMenuGroupId: '2b_script',
      contextMenuOrder: index,
      precondition,
      run: async (_editor) => {
        try {
          const mod = await importScriptModule(action.script);
          console.log({mod, action})
          const editor = _editor
          const tools = {
            lodash: _,
            openai: openai,
            toast: toast,
          }
          switch (action.mode) {
            case "brush": {
              const model = editor.getModel()
              const selection = editor.getSelection()
              const selectedText = model.getValueInRange(selection);
              try {
                const processedText = await mod.brush({ editor, tools, selectedText });
                if (!processedText) return;
                editor.executeEdits(null, [{
                  range: selection,
                  text: processedText,
                  forceMoveMarkers: true,
                }])
              } catch (err) {
                toast.error(err.message)
              }
              break;
            }
            case "insert": {
              const selection = editor.getSelection()
              try {
                const processedText = await mod.insert({ editor, tools });
                if (!processedText) return;
                editor.executeEdits(null, [{
                  range: selection,
                  text: processedText,
                  forceMoveMarkers: true,
                }])
              } catch (err) {
                toast.error(err.message)
              }
              break;
            }
            case "complete": {
              const model = editor.getModel()
              const selection = editor.getSelection()
              const selectedText = model.getValueInRange(selection);
              try {
                const processedText = await mod.complete({ editor, tools, selectedText });
                if (!processedText) return;
                editor.executeEdits(null, [{
                  range: {
                    startColumn: selection.endColumn,
                    startLineNumber: selection.endLineNumber,
                    endColumn: selection.endColumn,
                    endLineNumber: selection.endLineNumber,
                  },
                  text: processedText,
                  forceMoveMarkers: true,
                }])
              } catch (err) {
                toast.error(err.message)
              }
              break;
            }
            case "advanced": {
              console.log("mod.execute run")
              await mod.execute({ editor, tools });
              console.log("mod.execute ok")
              break;
            }
            default: {
              return;
            }
          }
        } catch (error) {
          console.error(error)
        }
      },
    })
  }
}
