import { trim } from 'lodash';
import { visit } from 'unist-util-visit';

export const remarkEmbedBlockBlockTypes = [
  'youtube',
  'gist',
  'figma',
  'desmos',
  'observable',
]
export function remarkEmbedBlock(options) {
  return (tree) => {
    visit(tree, 'text', (node, index, parent) => {
      if (!node.value.startsWith('{%')) return;

      let matched = /{%(\w+) (.*)%}/.exec(node.value)
      if (!matched) matched = [];
      const type = trim(matched[1])
      const data = trim(matched[2])

      if (!remarkEmbedBlockBlockTypes.includes(type)) return;

      parent.children[index] = {
        type: getRemarkEmbedBlockNodeType(type),
        data,
        options,
      };
    })
  }
}
export const remarkEmbedBlockHandlers = {
  [getRemarkEmbedBlockNodeType('youtube')]: remarkRehypeEmbedBlockYoutubeHandler,
  [getRemarkEmbedBlockNodeType('gist')]: remarkRehypeEmbedBlockGistHandler,
  [getRemarkEmbedBlockNodeType('figma')]: remarkRehypeEmbedBlockFigmaHandler,
  [getRemarkEmbedBlockNodeType('desmos')]: remarkRehypeEmbedBlockDesmosHandler,
  [getRemarkEmbedBlockNodeType('observable')]: remarkRehypeEmbedBlockObservableHandler,
}

function remarkRehypeEmbedBlockYoutubeHandler(h, node) {
  const [id, width, height] = node.data.split(' ')
  const iframeSrc = `https://www.youtube.com/embed/${id}`
  return autoSizeBox(h, node, [
    node.options?.disableRender
      ? iframePlaceholder(h, node, iframeSrc)
      : h(node, 'iframe', {
        className: 'embed-block-youtube',
        style: "width:100%; height:100%; left: 0px; top: 0px;",
        src: iframeSrc,
        frameBorder: 0,
        allowFullScreen: true,
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      })
  ], { width, height, aspectRatio: 9 / 16 })
}
function remarkRehypeEmbedBlockGistHandler(h, node) {
  const [id, width, height] = node.data.split(' ')
  const iframeSrc = `https://gist.github.com/${id}.pibb`
  return autoSizeBox(h, node, [
    node.options?.disableRender
      ? iframePlaceholder(h, node, iframeSrc)
      : h(node, 'iframe', {
        id,
        className: 'embed-block-gist',
        style: "width:100%; height:100%;",
        src: iframeSrc,
      })
  ], { width, height })
}
function remarkRehypeEmbedBlockFigmaHandler(h, node) {
  const [id, width, height] = node.data.split(' ')
  const iframeSrc = `https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F${encodeURIComponent(id)}`
  return autoSizeBox(h, node, [
    node.options?.disableRender
      ? iframePlaceholder(h, node, iframeSrc)
      : h(node, 'iframe', {
        id,
        className: 'embed-block-figma',
        style: "width:100%; height:100%; left: 0px; top: 0px; border: 1px solid rgba(0, 0, 0, 0.1);",
        src: iframeSrc,
        allowfullscreen: true,
      })
  ], { width, height, aspectRatio: 3 / 4 })
}
function remarkRehypeEmbedBlockDesmosHandler(h, node) {
  const [id, width, height] = node.data.split(' ')
  const iframeSrc = `https://www.desmos.com/calculator/${id}`
  return autoSizeBox(h, node, [
    node.options?.disableRender
      ? iframePlaceholder(h, node, iframeSrc)
      : h(node, 'iframe', {
        id,
        className: 'embed-block-desmos',
        style: "width:100%; height:100%; left: 0px; top: 0px; border: 1px solid rgba(0, 0, 0, 0.1);",
        src: iframeSrc,
        frameborder: 0,
      })
  ], { width, height })
}
function remarkRehypeEmbedBlockObservableHandler(h, node) {
  const [id, cell, width, height] = node.data.split(' ')
  const iframeSrc = `https://observablehq.com/embed/${id}?cells=${cell}`
  return autoSizeBox(h, node, [
    node.options?.disableRender
      ? iframePlaceholder(h, node, iframeSrc)
      : h(node, 'iframe', {
        id,
        className: 'embed-block-observable',
        style: "width:100%; height:100%; left: 0px; top: 0px;",
        src: iframeSrc,
        frameborder: 0,
      })
  ], { width, height })
}


function getRemarkEmbedBlockNodeType(type) {
  return `embed-block-${type}`
}
function autoSizeBox(h, node, children, options) {
  const { width, height, aspectRatio } = options
  return h(node, 'div', {
    className: 'embed-block-observable-wrapper',
    style: `position: relative; ${width ? `width: ${width};` : ''} ${height ? `height: ${height};` : `padding-bottom: ${(aspectRatio || 1) * 100}%;`}`,
  }, [
    h(node, 'div', {
      style: "width:100%; height:100%; position: absolute; left: 0px; top: 0px;",
    }, children)
  ])
}
function iframePlaceholder(h, node, src) {
  return h(node, 'div', {
    className: 'embed-block-youtube-placeholder',
    style: "width:100%; height:100%; padding: 20px; background-color: #ebebeb; display: flex; flex-direction: column; justify-content: center; align-items: center;",
  }, [
    h(node, 'div', {
      style: 'color: #949494; font-size: 20px;',
    }, [
      {
        type: 'text',
        value: `iframe`,
      }
    ]),
    h(node, 'div', {
      style: 'color: #949494; font-size: 14px; word-break: break-all;',
    }, [
      {
        type: 'text',
        value: src,
      }
    ]),
  ])
}