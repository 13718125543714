import * as Tooltip from '@radix-ui/react-tooltip'
import { getDefaultProvider } from 'ethers'
import { Suspense, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { SWRConfig } from 'swr'
import { createClient, WagmiConfig } from 'wagmi'
import './App.css'
import { ReactComponent as Loader } from './loader.svg'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { useProfileInitializer } from './hooks/useProfile'
import { GenericErrorBoundary } from './module/chaos/GenericErrorBoundary'
import DocumentEdit from './pages/document/edit'
import Home from './pages/home'
import NoteTemplateEdit from './pages/noteTemplate/edit'
import ProjectEdit from './pages/project/edit'
import TagIndex from './pages/tag'
import TagRelated from './pages/tag/related'
import TeamDashboard from './pages/team/dashboard'
import Teams from './pages/team/teams'
import ProjectView from './pages/view'

import { QueryClient, QueryClientProvider } from 'react-query'
import { useServiceWorker } from './hooks/swProvider'
import { OnlineStatusProvider } from './hooks/useOnlineStatus'
import { useTeamsInitializer } from './hooks/useTeams'
import { KBarXProvider } from './module/kbar/KBarXProvider'
import TeamSettings from './pages/team/settings/settings'
import { clearYdbBeforeVersion, CURRENT_SERVER_YDB_VERSION } from './utils'
import { Button } from '@blueprintjs/core'

const wagmiClient = createClient({
  autoConnect: true,
  provider: getDefaultProvider(),
})

// Create a client
const queryClient = new QueryClient()

function localStorageProvider() {
  // When initializing, we restore the data from `localStorage` into a map.
  const map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'))

  // Before unloading the app, we write back all the data into `localStorage`.
  window.addEventListener('beforeunload', () => {
    const appCache = JSON.stringify(Array.from(map.entries()))
    localStorage.setItem('app-cache', appCache)
  })

  // We still use the map for write & read for performance.
  return map
}

clearYdbBeforeVersion(CURRENT_SERVER_YDB_VERSION)

function App() {
  useProfileInitializer()
  useTeamsInitializer()
  const { isUpdateAvailable, updateAssets } = useServiceWorker()
  useEffect(() => {
    if (isUpdateAvailable === false) return
    toast((t) => {
      t.duration = 8848
      t.position = 'top-right'
      t.icon = '👏'
      return <span className='font-medium'>
        New version released!
        <Button className='mx-2' intent='primary' onClick={() => {
          toast.dismiss(t.id)
          updateAssets()
        }}>
          Update Now
        </Button>
      </span>
    });
  }, [isUpdateAvailable, updateAssets])

  return (
    <Suspense
      fallback={
        <div className='flex items-center justify-center min-h-screen'>
          <Loader />
        </div>
      }
    >
      <WagmiConfig client={wagmiClient}>
        <OnlineStatusProvider>
          <KBarXProvider>
            <Tooltip.Provider delayDuration={0}>
              <QueryClientProvider client={queryClient}>
                <SWRConfig value={{ provider: localStorageProvider }}>
                  <GenericErrorBoundary>
                    <Router>
                      <Routes>
                        <Route exact path='/' element={<Home />} />
                        <Route exact path='/teams' element={<Teams />} />
                        <Route exact path='/teams/:teamId' element={<TeamDashboard />} />
                        <Route exact path='/teams/:teamId/settings' element={<TeamSettings />} />
                        <Route path='/teams/:teamId/documents/:documentGroupId/edit' element={<DocumentEdit />} />
                        <Route path='/teams/:teamId/noteTemplates/:noteTemplateGroupId/edit' element={<NoteTemplateEdit />} />
                        <Route path='/teams/:teamId/projects/:projectId/edit' element={<ProjectEdit />} />
                        <Route path='/teams/:teamId/tags' element={<TagIndex />} />
                        <Route path='/teams/:teamId/tags/:tag' element={<TagRelated />} />
                        <Route path='/:teamId/:projectId' element={<ProjectView />} />
                      </Routes>
                    </Router>
                  </GenericErrorBoundary>
                  <Toaster position='bottom-center' />
                </SWRConfig>
              </QueryClientProvider>
            </Tooltip.Provider>
          </KBarXProvider>
        </OnlineStatusProvider>
      </WagmiConfig>
    </Suspense>
  )
}

export default App
