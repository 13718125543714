import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { RecoilRoot } from 'recoil'
import '@blueprintjs/core/lib/css/blueprint.css'
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import { ServiceWorkerProvider } from './hooks/swProvider'

ReactDOM.render(
  <React.StrictMode>
    <ServiceWorkerProvider>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </ServiceWorkerProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
