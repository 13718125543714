import { Button, MenuItem } from '@blueprintjs/core'
import { useQuery } from 'react-query'
import { fetcher } from '../../utils'
import { Select2 } from "@blueprintjs/select";
import {matchSorter} from 'match-sorter'
import { BiCategory } from 'react-icons/bi';

export default function CategoryRefBar({ teamId, selectedCategory, onSelect, onRemove }) {
  const { data: allCategories } = useQuery(["categories-list"], async () => {
    return await fetcher(`/teams/${teamId}/categories/list?limit=999`)
  })

  return (
    <div className='flex flex-row gap-1 p-1' style={{ color: '#c9c9c9', backgroundColor: '#2d2d2d', borderTop: '1px solid #3b3b3b' }}>
      <Select2
        items={(allCategories || [])}
        itemListPredicate={(query, items) => {
          if (items.length === 0) return [];
          const result = matchSorter(items, query, { keys: ['key', 'title'] })
          result.unshift({ key: null, name: 'None', setNull: true })
          return result
        }}
        itemRenderer={(category, props) => {
          if (category.setNull) {
            return (
              <MenuItem
                active={props.modifiers.active}
                disabled={props.modifiers.disabled}
                rolestructure="listoption"
                key={category.key}
                onClick={props.handleClick}
                onFocus={props.handleFocus}
                text={<div className='text-gray-400'>{category.name}</div>}
                icon={<BiCategory style={{ opacity: 0 }} />}
              />
            )
          }
          return (
            <MenuItem
              active={props.modifiers.active}
              disabled={props.modifiers.disabled}
              rolestructure="listoption"
              key={category.key}
              onClick={props.handleClick}
              onFocus={props.handleFocus}
              text={category.name}
              icon={<BiCategory />}
            />
          )
        }}
        onItemSelect={(category) => {
          if (category.setNull) {
            onRemove && onRemove()
          } else {
            onSelect && onSelect(category)
          }
        }}
      >
        <Button small icon={<BiCategory className={`${selectedCategory ? undefined : 'text-gray-500'}`} />} rightIcon="double-caret-vertical">
          {selectedCategory ? (
            <div>{selectedCategory.name}</div>
          ) : (
            <div className='text-gray-500'>Category</div>
          )}
        </Button>
      </Select2>
    </div>
  )
}
