import { isEqual, uniqBy } from 'lodash'
import { useEffect, useState } from 'react'
import { useDoc, useProvider } from '../module/y/hook'
import { getContrast } from '../utils'
import TimeAgo from './TimeAgo'
import Title from './Title'

const Collaborators = ({ myId, noteKey, avatarProps }) => {
  const doc = useDoc()
  const [states, setStates] = useState(new Map())
  const provider = useProvider()

  useEffect(() => {
    if (!provider.awareness) return
    const handleChange = () => {
      const currentStates = provider.awareness.getStates()
      if (isEqual(currentStates, states)) return
      setStates(currentStates)
    }
    provider.awareness.on('change', handleChange)
    return () => {
      provider.awareness.off('change', handleChange)
    }
  }, [provider])

  const users = uniqBy(
    Object.entries(Object.fromEntries(states)).filter(
      ([clientID, profile]) => profile.editingNoteKey === noteKey && clientID !== doc.clientID && profile.id !== myId
    ),
    ([, p]) => p.id
  ).map(([_, profile]) => profile)
  return <AvatarList data={users} {...avatarProps} />
}

export const AvatarList = ({ data = [], gap = 4, className = '', style = {}, size = 24 }) => {
  if (!data?.length) return null
  return (
    <div className={`flex ${className} relative z-10`} style={style}>
      {uniqBy(data, (d) => d.username).map(({ username, avatar, color, lastUpdatedAt }, i) => {
        const title = lastUpdatedAt ? (
          <span>
            <span className='mr-2'>{username}</span>
            <TimeAgo date={lastUpdatedAt} />
          </span>
        ) : (
          username
        )
        return (
          <div key={username} className='relative flex items-center cursor-pointer' style={{ zIndex: -i, marginRight: gap }}>
            <Avatar title={title} avatar={avatar} size={size} color={color} username={username} />
          </div>
        )
      })}
    </div>
  )
}

export const Avatar = ({ title, avatar, size, color, username, className = '' }) => {
  return (
    <div className={`inline-flex items-center ${className}`}>
      <Title title={title}>
        {avatar ? (
          <img className='rounded-full' src={avatar} width={size} height={size} />
        ) : (
          <div
            style={{
              width: size,
              height: size,
              background: color,
              color: getContrast(color),
            }}
            className='flex items-center justify-center text-xs rounded-full'
          >
            <span className='font-extrabold scale-50 margin-auto transform-gpu'>{username?.slice(0, 5)}</span>
          </div>
        )}
      </Title>
    </div>
  )
}

export default Collaborators
