import MonacoEditor from '@monaco-editor/react'

export default function YEditorReadonly({ path, content }) {
  return (
    <MonacoEditor
      language='markdown'
      theme='vs-dark'
      height={'100%'}
      width='100%'
      path={path}
      defaultValue={content}
      options={{
        readOnly: true,
        wordWrap: 'on',
        automaticLayout: true,
        scrollBeyondLastLine: true,
        scrollBeyondLastColumn: 0,
        padding: {
          top: 18,
          bottom: 18,
        },
        minimap: {
          enabled: false,
        },
        renderSideBySide: false,
      }}
    />
  )
}
