import { Tab, Tabs } from '@blueprintjs/core'
import { useState } from 'react'
import { CgHomeAlt } from 'react-icons/cg'
import { useParams } from 'react-router-dom'
import Header from '../../../components/header'
import { DocumentProvider, useMap } from '../../../module/y/hook'
import TeamSettingsEditorCompletion from './settingsEditorAutoCompletion'
import TeamSettingsGeneral from './settingsGeneral'
import TeamSettingsMembers, { TeamMemberRole } from './settingsMembers'
import TeamSettingsOgConfig from './settingsOgConfig'
import TeamSettingsPromptTemplate from './settingsPromptTemplates'
import TeamSettingsPdf from './settingsPdf'
import { useTeamPermission } from '../../../hooks/useTeams'
import TeamSettingsEditorScriptCompletion from './settingsEditorScriptActions'
import TeamSettingSearch from './settingsSearch'


function TeamSettingsMain({ teamId }) {
  const yTeamMeta = useMap('META')
  const { TEAM_NAME } = yTeamMeta.state

  const [tabId, setTabId] = useState('general')
  const role = useTeamPermission(teamId)

  return (
    <div
      className='min-h-screen'
      style={{
        backgroundColor: '#333',
      }}
    >
      <Header
        breads={[{ title: <CgHomeAlt />, link: '/teams' }, { title: TEAM_NAME, link: `/teams/${teamId}` }, { title: 'Settings' }]}
        teamId={teamId}
      />
      <div className='container max-w-2xl m-4 mx-auto text-white'>
        <Tabs
          id='TeamSettingsTabs'
          selectedTabId={tabId}
          onChange={(tabId) => {
            setTabId(tabId)
          }}
        >
          <Tab style={{ color: 'white' }} id='general' title='General' panel={<></>} />
          <Tab style={{ color: 'white' }} id='members' title='Members' panel={<></>} />
          <Tab hidden={role == TeamMemberRole.Member} style={{ color: 'white' }} id='OgConfig' title='Report OG' panel={<></>} />
          <Tab style={{ color: 'white' }} id='editorCompletion' title='AutoCompletion' panel={<></>} />
          <Tab style={{ color: 'white' }} id='editorScriptAction' title='ScriptAction' panel={<></>} />
          <Tab style={{ color: 'white' }} id='editorPromptTemplate' title='PromptTemplates' panel={<></>} />
          <Tab hidden={role == TeamMemberRole.Member} style={{ color: 'white' }} id='pdf' title='PDF' panel={<></>} />
          <Tab hidden={role == TeamMemberRole.Member} style={{ color: 'white' }} id='search' title='Search' panel={<></>} />
        </Tabs>
      </div>
      <div className='container mx-auto text-white'>
        {tabId === 'general' && (
          <div className='max-w-2xl mx-auto'>
            <TeamSettingsGeneral teamId={teamId} />
          </div>
        )}
        {tabId === 'members' && (
          <div className='max-w-2xl mx-auto'>
            <TeamSettingsMembers teamId={teamId} />
          </div>
        )}
        {tabId === 'OgConfig' && role > TeamMemberRole.Member && (
          <div className='max-w-2xl mx-auto'>
            <TeamSettingsOgConfig teamId={teamId} />
          </div>
        )}
        {tabId === 'editorCompletion' && (
          <div className='max-w-2xl mx-auto'>
            <TeamSettingsEditorCompletion teamId={teamId} />
          </div>
        )}
        {tabId === 'editorScriptAction' && (
          <div className='max-w-2xl mx-auto'>
            <TeamSettingsEditorScriptCompletion teamId={teamId} />
          </div>
        )}
        {tabId === 'editorPromptTemplate' && (
          <div className='max-w-2xl mx-auto'>
            <TeamSettingsPromptTemplate teamId={teamId} />
          </div>
        )}
        {tabId === 'pdf' && role > TeamMemberRole.Member && (
          <div className='mx-auto max-w-8xl'>
            <TeamSettingsPdf teamId={teamId} />
          </div>
        )}
        {tabId === 'search' && role > TeamMemberRole.Member && (
          <div className='max-w-2xl mx-auto'>
            <TeamSettingSearch teamId={teamId}/>
          </div>
        )}
      </div>
    </div>
  )
}
export default function TeamSettings() {
  const { teamId } = useParams()
  const docName = `TEAM_DATA-TEAM:${teamId}`
  const docMeta = {
    type: 'TEAM_DATA',
    teamId,
  }
  return (
    <DocumentProvider docName={docName} docMeta={docMeta}>
      <TeamSettingsMain teamId={teamId} />
    </DocumentProvider>
  )
}
