import { Button } from '@blueprintjs/core'
import React, { Fragment, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import { AiOutlineTeam } from 'react-icons/ai'
import { BiNetworkChart } from 'react-icons/bi'
import { Link, useNavigate } from 'react-router-dom'
import HTitle from '../components/Title'
import useOnlineStatus from '../hooks/useOnlineStatus'
import { useProfile } from '../hooks/useProfile'
import { ReactComponent as OfflineIcon } from '../offline.svg'
import { getContrast } from '../utils'
import ConnectingDocsDialog from './connectingDocsDialog'
import ProfileDialog from './profileDialog'

export default function Header({ renderLeft, teamId, breads, displayDocConnectStatus }) {
  const navigate = useNavigate()
  const isOnline = useOnlineStatus()

  return (
    <div className='flex items-center justify-center w-full bg-black'>
      {renderLeft ? <div className='absolute top-0 left-0 text-white'>
        {renderLeft()}
      </div> : null}
      <Breadcrumbs items={breads} />
      <div className='absolute top-0 right-0 flex items-center'>
        {!isOnline && <OfflineIcon className='w-4 text-red-600' />}
        {!!teamId ? <Button icon={<AiOutlineTeam className='text-gray-300' width={40} height={40} />}
          minimal
          onClick={() => navigate(`/teams/${teamId}/settings`)}
        ></Button>
          : null}
        <ProfileButton />
        {displayDocConnectStatus ? <ConnectingDocsButton /> : null}
      </div>
    </div>
  )
}
const Breadcrumbs = React.memo(({ items }) => {
  return (
    <div className='flex items-center justify-center text-center text-white' style={{ height: 30, minHeight: 30 }}>
      {items.map(({ title, onChange, link }, i) => {
        const isLast = i === items.length - 1
        return (
          <Fragment key={i}>
            {link ? (
              <Link className='text-white' to={link}>
                <Title title={title} link />
              </Link>
            ) : (
              <Title title={title} onChange={onChange} />
            )}
            {!isLast ? (
              <svg width='7' className='mx-1.5' height='15' viewBox='0 0 7 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M6 1L1 14' stroke='#BCBCBC' />
              </svg>
            ) : null}
          </Fragment>
        )
      })}
    </div>
  )
})
const Title = ({ title, onChange }) => {
  const text = useRef('')
  text.current = title
  const handleChange = (evt) => {
    text.current = evt.currentTarget.textContent
  }
  const handleBlur = () => {
    if (text.current !== title) {
      onChange(text.current)
    }
  }
  const handleKeydown = (evt) => {
    if (evt.keyCode === 13) {
      evt.preventDefault()
      handleBlur()
    }
  }
  return onChange ? (
    <ContentEditable inputMode='text' html={text.current ?? ''} onBlur={handleBlur} onChange={handleChange} onKeyDown={handleKeydown} />
  ) : (
    <span>{title}</span>
  )
}

const ProfileButton = () => {
  const [open, setOpen] = useState(false)
  const { profile } = useProfile()
  if (!profile) return null
  const name = profile.username?.slice(0, 6) ?? ''
  const profileIcon = profile.avatar ? (
    <img alt='profile avatar' className='rounded-full' src={profile.avatar} width={16} height={16} />
  ) : (
    <div style={{ background: profile.color, color: getContrast(profile.color) }} className='flex items-center justify-center w-5 h-5 text-xs rounded-full'>
      <span className='font-extrabold scale-50 margin-auto transform-gpu'>{name}</span>
    </div>
  )
  return <>
    <HTitle title={name}>
      <Button icon={profileIcon} minimal onClick={() => setOpen(true)}></Button>
    </HTitle>
    <ProfileDialog isOpen={open} onClose={() => setOpen(false)} />
  </>
}
const ConnectingDocsButton = () => {
  const [open, setOpen] = useState(false)
  return <>
    <HTitle title='Connecting Docs'>
      <Button icon={<BiNetworkChart color='#ddd' />} minimal onClick={() => setOpen(true)}></Button>
    </HTitle>
    <ConnectingDocsDialog isOpen={open} onClose={() => setOpen(false)} />
  </>
}