import ReactPaginate from 'react-paginate'

function Pagination({ forcePage, limit, size, onChange, ...rpProps }) {
  const pageCount = Math.ceil(size / limit)
  const handlePageClick = (event) => onChange((event.selected * limit) % size)
  return (
    <ReactPaginate
      forcePage={forcePage}
      breakLabel='...'
      onPageChange={handlePageClick}
      pageCount={pageCount}
      nextLabel='>'
      previousLabel='<'
      {...rpProps}
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
    />
  )
}

export default Pagination
