import { Spinner } from '@blueprintjs/core';
import { KBarPortal, KBarSearch, useKBar, useMatches, useRegisterActions } from 'kbar'
import { chain } from 'lodash';
import { useMemo, forwardRef, useState, useContext } from 'react'
import { useQueries } from 'react-query';
import { useDebounce } from 'react-use';
import { KBarAnimator } from './KBarAnimator';
import { KBarPositioner } from './KBarPositioner';
import { KBarResults } from './KBarResults';
import { KBarXContext } from './KBarXProvider';

export function KBarX({ actions, searches, children, disableCloseOnClickAway: _disableCloseOnClickAway }) {
  const context = useContext(KBarXContext)
  const disableCloseOnClickAway = useMemo(() => {
    return context?.disableCloseOnClickAway || _disableCloseOnClickAway
  }, [_disableCloseOnClickAway, context?.disableCloseOnClickAway])

  return (
    <>
      <KBarPortal>
        <KBarPositioner>
          <KBarAnimator style={animatorStyle} disableCloseOnClickAway={disableCloseOnClickAway}>
            <KBarSearch style={searchStyle} />
            <KBarXRenderResults {...{ actions, searches }} />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
      {children}
    </>
  )
}

function KBarXRenderResults({ actions: _actions, searches: _searches }) {
  const context = useContext(KBarXContext)
  const globalActions = context?.actions
  const globalSearches = context?.searches

  const actions = useMemo(() => {
    return [
      ...(_actions || []),
      ...(globalActions || []),
    ]
  }, [_actions, globalActions])

  const searches = useMemo(() => {
    return [
      ...(_searches || []),
      ...(globalSearches || []),
    ]
  }, [_searches, globalSearches])


  useRegisterActions(actions, [actions])
  // local actions
  const matched = useMatches();
  const localActions = useMemo(() => {
    return chain(matched?.results || [])
      .compact()
      // .filter((i) => !i.type || i.type === 'local')
      .map((action) => {
        return {
          ...action,
          type: "local",
        }
      })
      .flatten()
      .value()
  }, [matched]);

  // search actions
  const { searchQuery } = useKBar((state) => ({
    searchQuery: state.searchQuery,
  }));
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  useDebounce(
    () => {
      setDebouncedSearchQuery(searchQuery);
    },
    600,
    [searchQuery]
  );
  const searchQueries = useMemo(() => {
    return chain([
      ...(searches || []),
      ...(globalSearches || []),
    ])
      .uniqBy((i) => i.key)
      .map((search) => {
        return {
          queryKey: [search.key, debouncedSearchQuery],
          queryFn: async () => {
            return await search.onSearch(debouncedSearchQuery);
          },
          enabled: !!debouncedSearchQuery && debouncedSearchQuery.length >= 3,
          refetchOnReconnect: false,
          refetchOnWindowFocus: false,
        }
      })
      .value()
  }, [searches, globalSearches, debouncedSearchQuery]);
  const searchResults = useQueries(searchQueries)
  const searchActions = useMemo(() => {
    return chain(searchResults)
      .map((searchResult, index) => {
        const search = searches[index];
        const searchQuery = searchQueries[index];
        if (searchResult.isFetching) {
          return [{
            id: `search-loading-${search.key}`,
            name: "Search Loading",
            section: search.name,
            perform: search.perform,
            type: "search-loading",
          }]
        } else {
          if (!searchResult.data) return []
          return searchResult.data.map((item) => {
            const action = search.toAction(item)
            return {
              section: search.name,
              ...action,
              type: "search-result",
              search, searchQuery, searchResultItem: item,
            }
          })
        }
      })
      .flatten()
      .value()
  }, [searchResults, searches, searchQueries])

  // search config actions
  // const searchConfigAction = useMemo(() => {
  //   if (searchActions.length === 0 || actions.length === 0) return null
  //   return actions.find(a => a.type === 'search-config')
  // }, [searchActions, actions])

  const allActions = useMemo(() => {
    if (debouncedSearchQuery.length < 3) {
      return localActions
    }

    return chain([
      ...localActions,
      // searchConfigAction,
      ...searchActions,
    ].filter(Boolean))
      .groupBy((i) => i.section)
      .mapValues((items, section) => {
        return [
          ...(section === 'undefined' ? [] : [{ id: `section-${section}`, section, type: 'section' }]),
          ...items,
        ]
      })
      .values()
      .flatten()
      .value()
  }, [debouncedSearchQuery, localActions, searchActions])

  return (
    <KBarResults
      items={allActions}
      onRender={({ item, active }) => {
        if (item.type === 'section') {
          return <KBarXResultSectionItem action={item} />
        } else if (item.type === 'local') {
          return <KBarXResultActionItem action={item} active={active} />
        } else if (item.type === 'search-loading') {
          return <KBarXResultSearchLoadingItem />
        } else if (item.type === 'search-result') {
          return <KBarXResultSearchResultItem action={item} active={active} />
        // } else if (item.type === 'search-config') {
        //   return item.render()
        } else {
          return <div></div>
        }
      }}
    />
  );
}

const KBarXResultSectionItem = forwardRef(({ action }, ref) => {
  return (
    <div ref={ref} style={groupNameStyle}>{action.section}</div>
  )
})

const KBarXResultActionItem = forwardRef(({ action, active }, ref) => {
  return (
    <div
      ref={ref}
      style={{
        padding: "12px 16px",
        background: active ? "var(--a1)" : "var(--background)",
        borderLeft: `2px solid ${
          active ? "var(--foreground)" : "transparent"
        }`,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
    >
      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          {action.icon && action.icon}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{action.name}</span>
            {action.subtitle && (
              <span style={{ fontSize: 12 }}>{action.subtitle}</span>
            )}
          </div>
        </div>
      {action.shortcut?.length ? (
        <div style={{ display: "grid", gridAutoFlow: "column", gap: "4px" }}>
          {action.shortcut.map((sc) => (
            <kbd
              key={sc}
              style={{
                padding: "4px 6px",
                background: "rgba(0 0 0 / .1)",
                borderRadius: "4px",
              }}
            >
              {sc}
            </kbd>
          ))}
        </div>
      ) : null}
    </div>
  );
})

const KBarXResultSearchLoadingItem = forwardRef(({ action }, ref) => {
  return (
    <div
      ref={ref}
      style={{
        padding: "12px 16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
    >
      <div className="flex flex-row justify-between w-full">
        <div>Searching...</div>
        <div>
          <Spinner size={16} />
        </div>
      </div>
    </div>
  );
})

const KBarXResultSearchResultItem = forwardRef(({ action, active }, ref) => {
  return (
    <div
      ref={ref}
    >
      {action.search.onRender && action.search.onRender(action.searchResultItem, active)}
    </div>
  );
})

const searchStyle = {
  padding: "12px 16px",
  fontSize: "16px",
  width: "100%",
  boxSizing: "border-box",
  outline: "none",
  border: "none",
  background: "var(--background)",
  color: "var(--foreground)",
}
const animatorStyle = {
  maxWidth: "500px",
  width: "100%",
  background: "var(--background)",
  color: "var(--foreground)",
  borderRadius: "8px",
  overflow: "hidden",
  boxShadow: "var(--shadow)",
}
const groupNameStyle = {
  padding: "8px 16px",
  fontSize: "10px",
  textTransform: "uppercase",
  opacity: 0.5,
  background: "var(--background)",
};