/* eslint-disable no-template-curly-in-string */
import _ from "lodash";

export function registerMonacoTeamCompletion(monaco, completions) {
  return monaco.languages.registerCompletionItemProvider('markdown', {
    provideCompletionItems: (model, position) => {
      const range = {
        startLineNumber: position.lineNumber,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column
      }
      const text = model.getValueInRange(range).trim();

      const suggestions = []
      for (const completion of completions) {
        if (!completion.label) continue;
        const matchLabelInsideLine = completion.matchLabelOnlyAtStart === undefined ? true : completion.matchLabelOnlyAtStart
        const regex = (() => {
          const possibles = _.range(1, completion.label.length)
            .map((len) => {
              return completion.label.slice(0, len)
            })
            .join('|')
          return new RegExp(`${matchLabelInsideLine ? '^' : ''}(${possibles})$`);
        })()
        const matched = regex.exec(text)
        if (!matched) continue;
        const newRange = Object.assign({}, range)
        newRange.startColumn = matched.index + 1
        suggestions.push({
          kind: monaco.languages.CompletionItemKind.Function,
          label: completion.label,
          detail: completion.detail,
          documentation: completion.documentation,
          insertText: completion.insertText,
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          range: newRange,
        })
      }

      return {
        suggestions,
      }
    },
  });
}

