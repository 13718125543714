import { template } from './module/chaos/markdown'
import { nanoid } from 'nanoid'
import toast from 'react-hot-toast'
export const restApiUrl = process.env.REACT_APP_REST_API_URL || 'https://api.hacknote.co'
export const webrtcUrl = process.env.REACT_APP_WEBRTC_URL || 'wss://api.hacknote.co'
export const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL || 'wss://api.hacknote.co'
export const reportUrl = process.env.REACT_APP_REPORT_URL || 'https://www.hacknote.co'

// TODO: should get from server?
export const CURRENT_SERVER_YDB_VERSION = 'V4'

export const clearYdbBeforeVersion = async (version) => {
  const dbs = await indexedDB.databases()
  return Promise.all(
    dbs
      .map(({ name }) => name)
      .filter((name) => !name.startsWith(version))
      .map(indexedDB.deleteDatabase.bind(indexedDB))
  )
}
let refreshTokenPending = null
const getRefreshTokenPendingCrossTabs = () => localStorage.getItem('refreshTokenPendingCrossTabs') === 'true'
const setRefreshTokenPendingCrossTabs = (v) => localStorage.setItem('refreshTokenPendingCrossTabs', v)
const waitForTokenRefreshByOtherTab = (timeout = 5000, frequency = 200) => {
  return new Promise((resolve, reject) => {
    const checkInterval = setInterval(() => {
      if (!getRefreshTokenPendingCrossTabs()) {
        clearInterval(checkInterval)
        resolve()
      }
    }, frequency)
    // handle timeout eg: when other tab is closed and leave a `refreshTokenPendingCrossTabs` flag in localStorage
    setTimeout(() => {
      clearInterval(checkInterval)
      setRefreshTokenPendingCrossTabs(false)
      reject()
    }, timeout)
  })
}
export const waitForTokenRefresh = async () => {
  if (!refreshTokenPending) {
    if (!getRefreshTokenPendingCrossTabs()) {
      refreshTokenPending = refreshToken()
      setRefreshTokenPendingCrossTabs(true)
    } else {
      refreshTokenPending = waitForTokenRefreshByOtherTab()
    }
  }
  await refreshTokenPending
  refreshTokenPending = null
  setRefreshTokenPendingCrossTabs(false)
}
export const fetcher = async (url) => {
  const token = localStorage.getItem('token')
  const res = await fetch(restApiUrl + url, {
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  })
  if ((`${res.status}`).startsWith('2')) {
    try {
      const data = await res.json()
      return data
    } catch (e) {
      return {}
    }
  }
  const errorMessage = await res.text()
  if (res.status === 403) {
    toast.error('You are not authorized to access this page.')
    throw new Error(errorMessage)
  }
  if (res.status === 404) {
    toast.error('Not found.')
    throw new Error(errorMessage)
  }
  if (res.status !== 401) throw new Error(errorMessage)

  // handle 401
  if (errorMessage !== 'Token expired.') return logout()
  await waitForTokenRefresh()
  return fetcher(url)
}
export const refreshToken = async () => {
  console.log('refreshing Token..')
  const res = await fetcher(`/auth/refresh?refreshToken=${localStorage.refreshToken}`)
  console.log('token refreshed..')
  localStorage.token = res.token
  localStorage.refreshToken = res.refreshToken
}
export const logout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  if (window.location.pathname !== '/') {
    toast.error(
      <div>
        <div>Your token has expired. Please login again.
          <a href="/" className='mx-2 font-bold'>Login</a>
        </div>
      </div>, { id: 'logoutToast' }
    )
  }
}

export const poster = async (url, data, method, suppressNoNetworkError = false) => {
  const token = localStorage.getItem('token')
  if (localStorage.getItem('onlineStatus') === 'false') return !suppressNoNetworkError ? Promise.reject('no network') : Promise.resolve()
  const res = await fetch(restApiUrl + url, {
    method: method || 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
    body: JSON.stringify(data),
  })
  if (`${res.status}`.startsWith('2')) {
    try {
      const data = await res.json()
      return data
    } catch (e) {
      return {}
    }
  }
  // handle error
  const errorMessage = await res.text()
  if (res.status !== 401) throw new Error(errorMessage ?? 'Server Error')
  // handle 401
  if (errorMessage !== 'Token expired.') return logout()
  await waitForTokenRefresh()
  return poster(url, data, method)
}

export const contentToTitle = (content, variables) => {
  return (
    template(
      (content?.split('\n').find((line) => line.trimStart().startsWith('#')) ?? content?.split('\n')[0])?.replace(/^([#]+\s)/g, ''),
      variables
    ) ?? '--Blank Note--'
  )
}

export const generateKey = (ascending = true) => {
  const maxDateNowValue = 8.64e15
  const timestamp = ascending ? Date.now() : maxDateNowValue - Date.now()
  return `${timestamp.toString(16)}${nanoid(5)}`
}

export const getContrast = (hexColor, defaultColor = 'white') => {
  try {
    if (hexColor.startsWith('#')) hexColor = hexColor.slice(1)
    if (hexColor.length === 3) {
      hexColor = hexColor
        .split('')
        .map((hex) => hex + hex)
        .join('')
    }
    const r = parseInt(hexColor.substr(0, 2), 16)
    const g = parseInt(hexColor.substr(2, 2), 16)
    const b = parseInt(hexColor.substr(4, 2), 16)
    const yiqRatio = (r * 299 + g * 587 + b * 114) / 1000
    return yiqRatio >= 128 ? 'black' : 'white'
  } catch (e) {
    return defaultColor
  }
}
