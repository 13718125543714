import { Card, Tag } from "@blueprintjs/core";
import React from "react";
import { HiTag } from "react-icons/hi";
import { useQuery } from "react-query";
import {
  Link,
  useParams
} from "react-router-dom";

import Header from '../../components/header';
import { GenericErrorBoundary } from "../../module/chaos/GenericErrorBoundary";
import { DocumentProvider, useDoc } from "../../module/y/hook";
import { contentToTitle, fetcher } from '../../utils';

function TagRelatedMain({teamId, tag}) {
  const { data: relatedNotes } = useQuery(["relatedNotes", tag], async () => {
    return await fetcher(`/teams/${teamId}/tags/${tag}/notes`)
  })
  const yTeamDoc = useDoc()
  const teamName = yTeamDoc?.getMap('META')?.get('TEAM_NAME') ?? ''

  return (
    <div className="min-h-screen" style={{
      backgroundColor: '#333'
    }}>
      <Header teamId={teamId} breads={[{title: teamName, link: `/teams/${teamId}`},{title: 'Tags', link: `/teams/${teamId}/tags`}, {title: tag} ]} />
      <div className="m-4">
        <div className="text-white text-4xl mb-4 flex flex-row gap-2">
          <div>Tag</div>
          <div>
            <Tag
              large
              key={tag}
              icon={<HiTag />}
            >{tag}</Tag>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <div className="text-white text-2xl mb-4">Notes</div>
            <div className="flex flex-col">
              {(relatedNotes || []).map((note) => {
                return (
                  <Card className="bp4-dark">
                    <div className="flex flex-col gap-2 text-gray-300">
                      <Link
                        key={note.key}
                        to={`/teams/${teamId}/projects/${note.project}/edit`}
                        className="cursor-pointer"
                      >
                        <div className="text-lg">{contentToTitle(note.content)}</div>
                      </Link>
                      <div>{note.content}</div>
                    </div>
                  </Card>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function TagRelated() {
  const { teamId, tag } = useParams()
  const docName = `TEAM_DATA-TEAM:${teamId}`
  const docMeta = {
    type: 'TEAM_DATA',
    teamId
  }
  return (
      <GenericErrorBoundary>
        <DocumentProvider docName={docName} docMeta={docMeta}>
          <TagRelatedMain teamId={teamId} tag={tag} />
        </DocumentProvider>
      </GenericErrorBoundary>
  );
}