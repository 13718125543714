import { debounce } from 'lodash'
import toHtml from '../module/chaos/markdown'
import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import foldLines from '../module/chaos/fold-lines'
import { useThrottleFn } from 'react-use'

export const useMd = (md, variables) => {
  const [__html, setHTML] = useState('')
  const ref = useRef(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sideEffect = useCallback(
    debounce(() => {
      renderMath(ref.current)
      highlight()
      if (!ref.current) return
      const allPres = ref.current.querySelectorAll('pre.line-numbers')
      const lineNumbersContainers = ref.current.querySelectorAll('code .line-numbers-rows')
      for (const [i, pre] of allPres.entries()) {
        const code = pre.firstElementChild
        if (!code || !/code/i.test(code.tagName)) {
          continue
        }
        const highlightRanges = pre.dataset.line
        const offset = +pre.dataset.start - 1
        pre.style.counterReset = 'linenumber ' + offset
        if (!highlightRanges || !lineNumbersContainers[i]) {
          continue
        }
        lineHighlight(pre, highlightRanges, lineNumbersContainers[i], offset)
      }
      foldLines([...document.querySelectorAll('code')])
    }, 500),
    []
  )

  useThrottleFn((md, variables) => {
    toHtml(md, variables).then(setHTML)
  }, 200, [md, variables])

  useLayoutEffect(() => {
    if (!ref.current) return
    sideEffect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [__html])

  return { ref, __html }
}
const renderMath = (dom) => {
  const MathJax = window.MathJax
  if (MathJax) MathJax.Hub.Queue(MathJax.Hub.Typeset(dom))
}
const highlight = () => {
  const Prism = window.Prism
  if (Prism) Prism.highlightAll()
}

function lineHighlight(pre, highlightRanges, lineNumberRowsContainer, offset) {
  const ranges = highlightRanges.split(',').filter((val) => val)
  const preWidth = pre.scrollWidth

  for (const range of ranges) {
    let [start, end] = range.split('-')
    if (!start || !end) {
      start = range
      end = range
    }

    for (let i = +start - offset; i <= +end - offset; i++) {
      const lineNumberSpan = lineNumberRowsContainer.querySelector(`span:nth-child(${i})`)
      if (!lineNumberSpan) continue
      lineNumberSpan.style.setProperty(
        '--highlight-background',
        'linear-gradient(to right, hsla(24, 20%, 50%,.1) 70%, hsla(24, 20%, 50%,0))'
      )
      lineNumberSpan.style.setProperty('--highlight-width', `${preWidth}px`)
    }
  }
}
