import { useCallback, useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'
import { fetcher } from '../utils'
const getLocalProfile = () => {
  try {
    return JSON.parse(localStorage.getItem('profile'))
  } catch (err) {
    return {}
  }
}
const ProfileStore = atom({
  key: 'profile',
  default: getLocalProfile()
})

export function useProfile() {
  const [profile, _setProfile] = useRecoilState(ProfileStore)

  const setProfile = useCallback(
    (data) => {
      if (!data) return
      localStorage.setItem('profile', JSON.stringify(data))
      _setProfile(data)
    },
    [_setProfile]
  )

  return {
    profile,
    setProfile,
  }
}

export function useProfileInitializer() {
  const { setProfile } = useProfile()
  useEffect(() => {
    fetcher('/profile')
      .then((data) => {
        setProfile(data)
      })
      .catch((err) => {
        setProfile({})
      })
  }, [setProfile])
}
