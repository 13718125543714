import { useEffect, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { CgClose } from 'react-icons/cg'
import { useKey } from 'react-use'
import { debounce } from 'lodash'

const SearchBox = ({ onSearch, keyword }) => {
  const debounceSearch = debounce(onSearch, 500)
  const [active, setActive] = useState(false)
  useEffect(() => {
    if (keyword && keyword.length > 0) setActive(true)
  }, [keyword])
  const close = () => {
    onSearch('')
    setActive(false)
  }
  useKey('Escape', close)
  return (
    <div className='relative flex items-center justify-between hover:text-blue-500'>
      {active ? (
        <>
          <input
            defaultValue={keyword}
            onInput={(e) => {
              debounceSearch(e.target.value)
            }}
            autoFocus
            className={`absolute text-blue-500 text-base  font-medium z-10 -right-2 flex-1 py-2 px-2`}
          />
          <CgClose className='absolute right-0 z-20 cursor-pointer' onClick={close} />
        </>
      ) : (
        <BiSearch
          className='absolute right-0 z-20 cursor-pointer'
          onClick={() =>  setActive(true)}
        />
      )}
    </div>
  )
}

export default SearchBox
