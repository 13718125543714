import { Alert, Divider } from '@blueprintjs/core'
import { ContextMenuItem } from '@radix-ui/react-context-menu'
import { differenceWith } from 'lodash'
import { useState } from 'react'
import { CgHomeAlt } from 'react-icons/cg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import useSWR from 'swr'
import * as Y from 'yjs'
import { ContextMenuBox } from '../../../components/contextMenu'
import Header from '../../../components/header'
import ProgressButton from '../../../components/ProgressButton'
import { TeamKBarXSetup } from '../../../components/teamKBarSetup'
import { KBarX } from '../../../module/kbar/KBarX'
import { DocumentProvider, useArray, useDoc, useMap, useWsSynced } from '../../../module/y/hook'
import { useInitYArrPos } from '../../../module/y/migrations'
import { fetcher, generateKey, poster } from '../../../utils'
import ProjectList from './projectList'
function TeamDashboardMain({ teamId }) {
  const navigate = useNavigate()
  const yTeamDoc = useDoc()
  const yDocumentGroups = useArray('DOCUMENT_GROUPS')
  const yNoteTemplateGroups = useArray('NOTE_TEMPLATE_GROUPS')
  useInitYArrPos({ yNoteTemplateGroups })
  useInitYArrPos({ yDocumentGroups })
  const documentGroups = yDocumentGroups.state.sort((a, b) => b.updatedAt - a.updatedAt)
  const noteTemplateGroups = yNoteTemplateGroups.state.sort((a, b) => b.updatedAt - a.updatedAt)
  const yTeamMeta = useMap('META')
  const { TEAM_NAME } = yTeamMeta.state

  useTitle(TEAM_NAME)
  return (
    <TeamKBarXSetup>
      <KBarX>
        <div className='flex flex-col h-screen bg-[#333]'>
          <Header
            displayDocConnectStatus
            breads={[
              { title: <CgHomeAlt />, link: '/teams' },
              {
                title: TEAM_NAME,
              },
            ]}
            teamId={teamId}
          />
          <div className='flex flex-row flex-1 overflow-hidden'>
            <div className='w-1/3'>
              <ProjectList teamId={teamId} />
            </div>
            <Divider style={{ margin: 0, borderColor: '#dedede26' }} />
            <div className='w-1/3'>
              <ul className='w-full text-lg notes-list'>
                <li className='truncate cursor-pointer'>
                  <ProgressButton
                    className='w-full p-4 py-5'
                    onClick={async () => {
                      const key = generateKey()
                      const name = 'Untitled Document Group'
                      try {
                        await poster(`/teams/${teamId}/documentGroups`, {
                          key,
                          name,
                        })
                      } catch (err) {
                        return
                      }
                      const doc = new Y.Map()
                      yTeamDoc.transact(() => {
                        doc.set('key', key)
                        doc.set('team', teamId)
                        doc.set('name', name)
                        doc.set('updatedAt', Date.now())
                        yDocumentGroups.push([doc])
                      })
                      navigate(`/teams/${teamId}/documents/${key}/edit`)
                    }}
                  >
                    <div className='flex items-center w-full h-full text-xl font-bold text-center text-white'>
                      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M0 10H20' stroke='white' />
                        <path d='M10 20L10 -5.96046e-07' stroke='white' />
                      </svg>
                      <span className='ml-3'>New Document Group</span>
                    </div>
                  </ProgressButton>
                </li>
                {(documentGroups || []).map((group) => {
                  return (
                    <li className='truncate cursor-pointer' key={group.key}>
                      <Link className='block w-full p-4 py-5 text-xl font-bold' to={`/teams/${teamId}/documents/${group.key}/edit`}>
                        {group.name}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
            <Divider style={{ margin: 0, borderColor: '#dedede26' }} />
            <div className='w-1/3'>
              <ul className='w-full text-lg notes-list'>
                <li className='truncate cursor-pointer'>
                  <ProgressButton
                    className='w-full p-4 py-5'
                    onClick={async () => {
                      const key = generateKey()
                      const name = 'Untitled Note Template Group'
                      try {
                        await poster(`/teams/${teamId}/noteTemplateGroups`, {
                          key,
                          name,
                        })
                      } catch (err) {
                        return
                      }
                      const doc = new Y.Map()
                      yTeamDoc.transact(() => {
                        doc.set('key', key)
                        doc.set('name', name)
                        doc.set('team', teamId)
                        doc.set('updatedAt', Date.now())
                        yNoteTemplateGroups.push([doc])
                      })
                      navigate(`/teams/${teamId}/noteTemplates/${key}/edit`)
                    }}
                  >
                    <div className='flex items-center w-full h-full text-xl font-bold text-center text-white'>
                      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M0 10H20' stroke='white' />
                        <path d='M10 20L10 -5.96046e-07' stroke='white' />
                      </svg>
                      <span className='ml-3'>New Template Group</span>
                    </div>
                  </ProgressButton>
                </li>
                {(noteTemplateGroups || []).map((group) => {
                  return (
                    <li className='truncate cursor-pointer' key={group.key}>
                      <Link className='block w-full p-4 py-5 text-xl font-bold' to={`/teams/${teamId}/noteTemplates/${group.key}/edit`}>
                        {group.name}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </KBarX>
    </TeamKBarXSetup>
  )
}

export default function TeamDashboard() {
  const { teamId } = useParams()
  const docName = `TEAM_DATA-TEAM:${teamId}`
  const docMeta = {
    type: 'TEAM_DATA',
    teamId,
  }
  return (
    <DocumentProvider docName={docName} docMeta={docMeta}>
      <TeamDashboardMain teamId={teamId} />
    </DocumentProvider>
  )
}

const ProjectSyncContainer = ({ id, local, onSyncProject }) => {
  const { mutate: reload, data, isValidating, error } = useSWR(`/teams/${id}/projects`, fetcher)
  const [deletingProject, setDeletingProject] = useState(null)
  const wsSynced = useWsSynced()
  if (isValidating || error) return null
  const diff = differenceWith(data, local, (p1, p2) => p1.key == p2.key && p1.title == p2.title)
  if (diff.length == 0 || !wsSynced) return null
  return (
    <div className='py-2 text-sm bg-gray-700'>
      <div className='px-4 mb-2'>
        Found <span className='text-red-400'>{diff.length}</span> project{diff.length > 1 && 's'} on server not synced with local
      </div>
      <ul>
        <Alert
          className='bp4-dark bg-[#333]'
          cancel
          cancelButtonText='Cancel'
          confirmButtonText='Delete Project'
          icon='delete'
          intent='danger'
          isOpen={!!deletingProject}
          onClose={() => {
            setDeletingProject(null)
          }}
          onConfirm={async () => {
            if (!deletingProject) return
            await poster(`/teams/${id}/projects/${deletingProject.key}`, {}, 'DELETE')
            await reload()
            setDeletingProject(null)
          }}
        >
          <p>
            <span className='font-bold'>Confirm Delete Project In Database</span>
          </p>
          <p>{deletingProject ? deletingProject.title : null}</p>
        </Alert>
        {diff.map((project) => {
          return (
            <li className='truncate cursor-pointer' key={project.key}>
              <ContextMenuBox
                menu={
                  <>
                    <ContextMenuItem
                      onClick={(e) => {
                        setDeletingProject(project)
                        e.stopPropagation()
                      }}
                    >
                      <div className='px-2 py-1 cursor-pointer'>Delete in db</div>
                    </ContextMenuItem>
                    <ContextMenuItem
                      onClick={() => {
                        onSyncProject(project.key, project.title)
                      }}
                    >
                      <div className='px-2 py-1 cursor-pointer'>Sync to local</div>
                    </ContextMenuItem>
                  </>
                }
              >
                <Link
                  className='flex items-center justify-between w-full px-4 py-2 font-bold text-white underline'
                  to={`/teams/${id}/projects/${project.key}/edit`}
                >
                  {project.title}
                  {project.createdAt}
                </Link>
              </ContextMenuBox>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

