import { Button, Dialog, FormGroup, InputGroup, Radio, RadioGroup, Tag } from '@blueprintjs/core'
import MonacoEditor from '@monaco-editor/react'
import { Formik } from 'formik'
import { isEmpty, pick } from 'lodash'
import { useCallback, useState } from 'react'
import { AiFillCloseCircle, AiFillEdit } from 'react-icons/ai'
import { useQuery } from 'react-query'
import * as Y from 'yjs'
import * as Yup from 'yup'
import { Avatar } from '../../../components/Collaborators'
import { useProfile } from '../../../hooks/useProfile'
import { useTeamPermission } from '../../../hooks/useTeams'
import { useDoc, useMap } from '../../../module/y/hook'
import { fetcher, generateKey } from '../../../utils'
import { importScriptModule } from '../../../utils/importScriptModule'
import { TeamMemberRole } from './settingsMembers'

const EditorScriptActionsSchema = Yup.object().shape({
  label: Yup.string().min(1).required(),
  mode: Yup.string().min(1).required(),
  script: Yup.string().min(1).test("is-valid-script", ({ label }) => {
    return `${label} is not a valid script`
  }, async (value, context) => {
    try {
      const mod = await importScriptModule(value);
      switch (context.parent.mode) {
        case "brush": {
          return !!mod.brush
        }
        case "insert": {
          return !!mod.insert
        }
        case "complete": {
          return !!mod.complete
        }
        case "advanced": {
          return !!mod.execute
        }
        default: {
          return false;
        }
      }
    } catch (error) {
      console.error(error)
      return false;
    }
  }),
})

export default function TeamSettingsEditorScriptActionsMain({ teamId }) {
  const [editingAction, setEditingAction] = useState(null)
  const operatorRole = useTeamPermission(teamId)
  const { profile } = useProfile()

  const {
    data: members,
  } = useQuery(
    ['TeamMember', teamId],
    async () => {
      return await fetcher(`/teams/${teamId}/members`)
    },
    {
      enabled: !!teamId,
      staleTime: 1000 * 60 * 10,
    }
  )

  const yDoc = useDoc()
  const yEditorScriptActionssSettings = useMap('EDITOR_SCRIPT_ACTIONS_SETTINGS')
  const yActions = yEditorScriptActionssSettings.get('actions')
  const actions = (yActions?.toJSON() || []).sort((a, b) => {
    if (a.label < b.label) return -1
    else if (a.label > b.label) return 1
    else return 0
  })
  const createAction = useCallback(
    (values) => {
      if (!yActions) return
      yDoc.transact(() => {
        yActions.insert(0, [
          new Y.Map([
            ['key', generateKey()],
            ['label', values.label],
            ['mode', values.mode],
            ['script', values.script],
            ['createdBy', profile.id],
          ]),
        ])
        yEditorScriptActionssSettings.set('version', generateKey())
      })
    },
    [yActions, yDoc, yEditorScriptActionssSettings]
  )

  const updateAction = useCallback(
    (key, values) => {
      if (!yActions) return
      const index = yActions.toJSON().findIndex((i) => i.key === key)
      if (index === -1) return
      yDoc.transact(() => {
        const yAction = yActions.get(index)
        yAction.set('label', values.label)
        yAction.set('mode', values.mode)
        yAction.set('script', values.script)
        yEditorScriptActionssSettings.set('version', generateKey())
      })
    },
    [yActions, yDoc, yEditorScriptActionssSettings]
  )

  const deleteAction = useCallback(
    (key) => {
      if (!yActions) return
      const index = yActions.toJSON().findIndex((i) => i.key === key)
      if (index === -1) return
      yDoc.transact(() => {
        yActions.delete(index)
        yEditorScriptActionssSettings.set('version', generateKey())
      })
    },
    [yActions, yDoc, yEditorScriptActionssSettings]
  )
  return (
    <div>
      <Formik initialValues={{}} onSubmit={async (values) => {}} enableReinitialize>
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className='flex flex-col'>
                <FormGroup label='Script Actions'>
                  <Button
                    intent='primary'
                    className='w-full mb-2'
                    onClick={() => {
                      setEditingAction({
                        readOnly: false,
                        isNew: true,
                        key: generateKey,
                        mode: 'brush',
                        script: ExampleBrushScriptText,
                      })
                    }}
                  >
                    Create New Action
                  </Button>
                  <div className='flex flex-col gap-2'>
                    {actions &&
                      actions.map((action) => {
                        const creator = members?.find((i) => i.id === action.createdBy)
                        const readOnly = !(operatorRole === TeamMemberRole.Owner || operatorRole === TeamMemberRole.Manager || action.createdBy === profile.id)
                        return (
                          <Tag key={action.key} minimal large>
                            <div className='flex flex-row items-center justify-between gap-1'>
                              <div className='flex flex-col'>
                                <div className='text-white'>{action.label}</div>
                                <div className='text-gray-400'>{action.documentation}</div>
                              </div>
                              <div className="flex flex-row items-center">
                                {creator && (
                                  <Avatar
                                    className='mr-1'
                                    title={creator.username}
                                    avatar={creator.avatar}
                                    size={18}
                                    color={creator.color}
                                    username={creator.username}
                                  />
                                )}
                                <Button
                                  minimal
                                  small
                                  onClick={() => {
                                    setEditingAction({
                                      isNew: false,
                                      readOnly,
                                      ...action,
                                    })
                                  }}
                                >
                                  <AiFillEdit className='text-gray-300' />
                                </Button>
                                {!readOnly ? (
                                  <Button
                                    minimal
                                    small
                                    onClick={() => {
                                      deleteAction(action.key)
                                    }}
                                  >
                                    <AiFillCloseCircle className='text-red-300' />
                                  </Button>
                                ) : null}
                              </div>
                            </div>
                          </Tag>
                        )
                      })}
                  </div>
                </FormGroup>
              </div>
            </form>
          )
        }}
      </Formik>
      {editingAction && (
        <Dialog
          className='bp4-dark'
          style={{ width: 650 }}
          title={editingAction.isNew ? 'Create new action' : 'Edit action'}
          isOpen={true}
          onClose={() => {
            setEditingAction(null)
          }}
          canOutsideClickClose={false}
        >
          <div className='m-4'>
            <TeamSettingsEditorScriptActionsItem
              readOnly={editingAction.readOnly}
              initialValues={editingAction}
              onSubmit={(values, isNew) => {
                console.log({values, isNew})
                if (isNew) {
                  createAction(values)
                } else {
                  updateAction(values.key, values)
                }
                setEditingAction(null)
              }}
            />
          </div>
        </Dialog>
      )}
    </div>
  )
}

function TeamSettingsEditorScriptActionsItem({ initialValues, onSubmit, readOnly }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditorScriptActionsSchema}
      onSubmit={(values) => {
        if (readOnly) return
        onSubmit(pick(values, ['key', 'label', 'mode', 'script']), initialValues.isNew)
      }}
      enableReinitialize
    >
      {({ values, handleChange, dirty, isSubmitting, handleSubmit, errors, setFieldValue, setValues }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col'>
              <FormGroup
                label='Label'
                labelFor='label'
                helperText='The label of this action item. By default this is also the text that is inserted when selecting this action.'
              >
                <InputGroup
                  intent={errors['label'] ? 'danger' : undefined}
                  id='label'
                  name='label'
                  value={values.label || ''}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup
                label='Mode'
                labelFor='mode'
                helperText={{
                  brush: "According to the selected text, process the new text to replace the original content (this kind of action will only be available when text (non-empty) is selected)",
                  insert: "Insert the proccessed text into the selected position (if there is a selected content, the selected content will be replaced)",
                  complete: "Similar to brush mode, but proccessed text will be appended after the selected position instead of replacing",
                  advanced: "Fully customizable, use the editor instance's interface to modify content",
                }[values.mode]}
              >
                <RadioGroup
                  name="mode"
                  selectedValue={values.mode || 'brush'}
                  onChange={(e) => {
                    const newMode = e.target.value;
                    setValues({
                      ...values,
                      mode: newMode,
                      script: {
                        brush: ExampleBrushScriptText,
                        insert: ExampleInsertScriptText,
                        complete: ExampleCompleteScriptText,
                        advanced: ExampleAdvancedScriptText,
                      }[newMode],
                    })
                  }}
                  inline
                >
                  <Radio label="Brush" value="brush" />
                  <Radio label="Insert" value="insert" />
                  <Radio label="Complete" value="complete" />
                  <Radio label="Advanced" value="advanced" />
                </RadioGroup>
              </FormGroup>
              <FormGroup
                label='Script'
                labelFor='script'
                helperText="User defined script of this action item."
              >
                <div className={errors['script'] ? `border border-red-500` : 'border border-transparent'}>
                  <MonacoEditor
                    language='javascript'
                    theme='vs-dark'
                    height={400}
                    width='100%'
                    path={"/script"}
                    value={values.script}
                    options={{
                      wordWrap: 'on',
                      automaticLayout: true,
                      scrollBeyondLastLine: true,
                      scrollBeyondLastColumn: 0,
                      minimap: {
                        enabled: false,
                      },
                      renderSideBySide: false,
                    }}
                    onChange={(content) => {
                      setFieldValue('script', content)
                    }}
                  />
                </div>
              </FormGroup>
              <Button intent='primary' type='submit' disabled={readOnly || !dirty || !isEmpty(errors) || isSubmitting}>
                {initialValues.isNew ? 'Create' : 'Update'}
              </Button>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

const ExampleBrushScriptText = `// interface BrushContext {
//   editor: monaco.editor;
//   tools: {
//     lodash: LoDashStatic;
//     openai: any; // { createCompletions }
//     toast: any; // "react-hot-toast"
//   };
//   selectedText: string;
// }
// function brush(context: BrushContext): Promise<string> | string
/**
 * Here is an example of using openai to prettify code in BRUSH mode.
 */
export async function brush({ tools, selectedText }) {
  const toastId = tools.toast.loading('Prettifying...');
  try {
    const completion = await tools.openai.createCompletion({
      prompt: "Please convert the following code into prettified human readable code: /n" + selectedText,
    });
    if (!completion.choices || completion.choices.length == 0) {
      tools.toast(JSON.stringify(completion))
      return selectedText;
    }
    return completion.choices[0].text;
  } catch (err) {
    tools.toast.error(err.message);
  } finally {
    tools.toast.dismiss(toastId)
  }
}
`
const ExampleInsertScriptText = `// interface InsertContext {
//   editor: monaco.editor;
//   tools: {
//     lodash: LoDashStatic;
//     openai: any; // { createCompletions }
//     toast: any; // "react-hot-toast"
//   };
// }
// function insert(context: InsertContext): Promise<string> | string
/**
 * Here is an example of inserting current date in INSERT mode.
 */
export async function insert() {
  const now = new Date()
  const year = now.getFullYear()
  const month = now.toLocaleString('en-US', { month: 'short' })
  const day = now.getDate()
  const processedText = \`\${month} \${day}, \${year}\`
  return processedText
}
`
const ExampleCompleteScriptText = `// interface CompleteContext {
//   editor: monaco.editor;
//   tools: {
//     lodash: LoDashStatic;
//     openai: any; // { createCompletions }
//     toast: any; // "react-hot-toast"
//   };
//   selectedText: string;
// }
// function complete(context: CompleteContext): Promise<string> | string
/**
 * Here is an example of using openai to answer question in COMPLETE mode.
 */
export async function complete({ tools, selectedText }) {
  const toastId = tools.toast.loading('Thinking...');
  try {
    const completion = await tools.openai.createCompletion({
      prompt: "Please explain the following text: /n" + selectedText,
    });
    if (!completion.choices || completion.choices.length == 0) {
      tools.toast(JSON.stringify(completion))
      return selectedText;
    }
    return completion.choices[0].text;
  } catch (err) {
    tools.toast.error(err.message);
  } finally {
    tools.toast.dismiss(toastId)
  }
}
`
const ExampleAdvancedScriptText = `// interface ExecuteContext {
//   editor: monaco.editor;
//   tools: {
//     lodash: LoDashStatic;
//     openai: any; // { createCompletions }
//     toast: any; // "react-hot-toast"
//   };
// }
// function execute(context: ExecuteContext): Promise<void> | void
/**
 * Here is an example of converting text to uppercase in EXECUTE mode.
 */
export function execute({ editor }) {
  const model = editor.getModel()
  const selection = editor.getSelection()
  const selectedText = model.getValueInRange(selection);
  const processedText = selectedText.toUpperCase()
  editor.executeEdits(null, [{
    range: selection,
    text: processedText,
    forceMoveMarkers: true,
  }])
}
`