import { Dialog } from '@blueprintjs/core'
import { docMap, connectedWsProviders } from '../module/y/hook'
import { AvatarList } from './Collaborators'
import Title from './Title'

export default function ConnectingDocsDialog({ isOpen, onClose }) {
  return (
    <Dialog className='bp4-dark' title='Connecting Docs' isOpen={isOpen} onClose={onClose} canOutsideClickClose={false}>
      <div className='p-4'>
        {[...connectedWsProviders].map(([docName, provider], i) => {
          const isLast = i === connectedWsProviders.size - 1
          const doc = docMap.get(docName)
          const docMeta = doc.getMap('META')?.toJSON() ?? {}
          const isTeamDoc = docMeta.TYPE === 'TEAM_DATA'
          const title = isTeamDoc ? `TEAM / ${docMeta.TEAM_NAME}` : `PROJECT / ${docMeta.TEAM_NAME} / ${docMeta.PROJECT_TITLE}`
          const users = []
          provider.awareness.getStates().forEach((state) => {
            users.push({
              username: state.username,
              color: state.color,
              avatar: state.avatar,
              lastUpdatedAt: state.lastUpdatedAt,
            })
          })
          return (
            <div key={docName} className={`${isLast ? '' : 'mb-4'}`}>
              <div className='flex items-center'>
                <Title title={provider.synced ? 'synced' : 'not synced'}>
                  <div className={`mr-2 w-2 h-2 ${provider.synced ? 'bg-green-600' : 'bg-red-400'} rounded-full`} />
                </Title>
                <Title title={docName}>{title}</Title>
              </div>
              <AvatarList className='mt-2 ml-4' size={18} gap={4} data={users} />
            </div>
          )
        })}
      </div>
    </Dialog>
  )
}
