import MonacoEditor from '@monaco-editor/react'
import Split from 'react-split'
import { GenericErrorBoundary } from '../module/chaos/GenericErrorBoundary'

export default function NoteEditorReadonly({ note }) {
  return (
    <Split className='split h-full flex flex-col' sizes={[70, 30]} direction='vertical'>
      <div className='overflow-hidden w-full'>
        <GenericErrorBoundary>
          <MonacoEditor
            language='markdown'
            theme='vs-dark'
            height={'100%'}
            width='100%'
            path={`${note.key}-content`}
            defaultValue={note.content}
            options={{
              readOnly: true,
              wordWrap: 'on',
              automaticLayout: true,
              scrollBeyondLastLine: true,
              scrollBeyondLastColumn: 0,
              padding: {
                top: 18,
                bottom: 18,
              },
              minimap: {
                enabled: false,
              },
              renderSideBySide: false,
            }}
          />
        </GenericErrorBoundary>
      </div>
      <div className='overflow-hidden w-full'>
        <GenericErrorBoundary>
          <MonacoEditor
            language='markdown'
            theme='vs-dark'
            height='100%'
            width='100%'
            path={`${note.key}-privateNote`}
            defaultValue={note.privateNote}
            options={{
              readOnly: true,
              wordWrap: 'on',
              automaticLayout: true,
              scrollBeyondLastLine: true,
              scrollBeyondLastColumn: 0,
              padding: {
                top: 18,
                bottom: 18,
              },
              minimap: {
                enabled: false,
              },
            }}
          />
        </GenericErrorBoundary>
      </div>
    </Split>
  )
}
