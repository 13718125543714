/* eslint-disable react/jsx-no-target-blank */
import { Button } from '@blueprintjs/core'
import { debounce } from 'lodash'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import Split from 'react-split'
import { useTitle } from 'react-use'
import Header from '../../components/header'
import Note from '../../components/note'
import { TeamKBarXSetup } from '../../components/teamKBarSetup'
import YEditor from '../../components/yeditor'
import { useProfile } from '../../hooks/useProfile'
import { GenericErrorBoundary } from '../../module/chaos/GenericErrorBoundary'
import { KBarX } from '../../module/kbar/KBarX'
import { useRegisterActions } from '../../module/kbar/KBarXProvider'
import { DocumentProvider, getDoc, getSortedArray, insertMap, moveMap, useArray, useDoc, useForceUpdate, useMap } from '../../module/y/hook'
import { contentToTitle, fetcher, generateKey, poster } from '../../utils'
import { AiOutlinePlus } from 'react-icons/ai'
import { FaFolderPlus } from 'react-icons/fa'
import { ContextMenuItem, ContextMenuSeparator } from '../../components/contextMenu'
import { generateNKeysBetween } from '../../module/fractional-indexing'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import TimeAgo from '../../components/TimeAgo'
import * as Y from 'yjs'
import FolderList from '../../components/folder/folderList'


const createNoteTemplateOnServer = (doc) => poster(`/teams/${doc.team}/noteTemplates`, doc)
const updateNoteTemplate = debounce((doc) => poster(`/teams/${doc.teamId}/noteTemplates/${doc.noteTemplateId}`, doc, 'PUT'), 1000)
const updateNoteTemplateGroup = (teamId, groupId, docs) => poster(`/teams/${teamId}/noteTemplateGroups/${groupId}`, docs, 'PUT')
const updateNoteTemplatePosOnServer = (teamId, noteTemplateId, pos) => poster(`/teams/${teamId}/noteTemplates/${noteTemplateId}/pos`, { pos }, 'PUT')
const updateNoteTemplateGroupFolderPosOnServer = (teamId, groupId, folderId, pos) => poster(`/teams/${teamId}/noteTemplateGroups/${groupId}/folders/${folderId}/pos`, { pos }, 'PUT')
const createNoteTemplateGroupFolderOnServer = (teamId, groupId, doc) => poster(`/teams/${teamId}/noteTemplateGroups/${groupId}/folders`, doc, 'POST')
const updateNoteTemplateGroupFolderOnServer = (teamId, groupId, folderId, doc) => poster(`/teams/${teamId}/noteTemplateGroups/${groupId}/folders/${folderId}`, doc, 'PUT')
const deleteNoteTemplateGroupFolderOnServer = (teamId, groupId, folderId) => poster(`/teams/${teamId}/noteTemplateGroups/${groupId}/folders/${folderId}`, {}, 'DELETE')
const updateNoteTemplateFolderOnServer = (teamId, groupId, noteTemplateId, folder) => poster(`/teams/${teamId}/noteTemplateGroups/${groupId}/noteTemplates/${noteTemplateId}/folder`, { folder }, 'PUT')

const createYDocumentFolder = (team, group) => {
  const m = new Y.Map()
  Object.entries({
    key: generateKey(),
    name: 'Untitled Folder',
    team,
    group,
  }).forEach(([key, value]) => {
    m.set(key, value)
  })
  return m
}
const createYNoteTemplate = (team, group) => {
  const m = new Y.Map()
  Object.entries({
    key: generateKey(),
    title: 'Untitled Template',
    content: new Y.Text('# Untitled NoteTemplate'),
    team,
    group,
  }).forEach(([key, value]) => {
    m.set(key, value)
  })
  return m
}
function EditNoteTemplateMain({ teamId, noteTemplateGroupId }) {
  const { profile } = useProfile()
  const userId = JSON.parse(localStorage.profile)?.id
  const [editingNoteTemplate, setEditingNoteTemplate] = useState(null)
  const yDoc = useDoc()
  const yMeta = useMap('META')
  const teamName = yMeta.state.TEAM_NAME ?? '...'
  const noteTemplateGroupName = yMeta.get('NOTE_TEMPLATE_GROUP_TITLE') ?? '...'
  const yFolders = useArray('FOLDERS')
  const yFolderOpened = useMap(`FOLDER_OPENED:${profile.id}`)
  const yNoteTemplates = useArray('NOTE_TEMPLATES')
  const folders = yFolders.toArray().map((i) => i.toJSON())
  const sortedFolders = getSortedArray(yFolders || []).map((yd) => yd.toJSON())
  const lastFolderPos = useMemo(() => sortedFolders[sortedFolders.length - 1]?.pos, [sortedFolders])
  const noteTemplates = yNoteTemplates.toArray().map((i) => i.toJSON())
  const sortedNoteTemplates = getSortedArray(yNoteTemplates || []).map((yd) => yd.toJSON())
  const lastNoteTemplatePos = useMemo(() => sortedNoteTemplates[sortedNoteTemplates.length - 1]?.pos, [sortedNoteTemplates])
  const originalKeys = yNoteTemplates.map((yn) => yn.get('key'))
  useTitle(noteTemplateGroupName ?? 'Untitled')

  const forceUpdate = useForceUpdate()

  const { data: dbNoteTemplates, refetch: refetchDbNoteTemplates } = useQuery(['noteTemplates', teamId, noteTemplateGroupId], async () => {
    return await fetcher(`/teams/${teamId}/noteTemplateGroups/${noteTemplateGroupId}/noteTemplates`)
  })

  const createNewNoteTemplateFolder = () => {
    const newFolder = createYDocumentFolder(teamId, noteTemplateGroupId)
    yDoc.transact(() => {
      newFolder.set('pos', generateNKeysBetween(lastFolderPos ?? null, null, 1))
      insertMap(yFolders, newFolder, yFolders.length)
    })
    createNoteTemplateGroupFolderOnServer(teamId, noteTemplateGroupId, newFolder.toJSON())
  }

  const createNewNoteTemplate = (folderKey) => {
    const newNoteTemplate = createYNoteTemplate(teamId, noteTemplateGroupId)
    if (folderKey) {
      newNoteTemplate.set('folder', folderKey)
    }
    yDoc.transact(() => {
      newNoteTemplate.set('pos', generateNKeysBetween(lastNoteTemplatePos ?? null, null, 1))
      insertMap(yNoteTemplates, newNoteTemplate, yNoteTemplates.length)
    })
    setEditingNoteTemplate(newNoteTemplate)
    createNoteTemplateOnServer(newNoteTemplate.toJSON())
  }

  useRegisterActions([
    {
      id: 'newNoteTemplate',
      name: 'New NoteTemplate',
      shortcut: ['n'],
      keywords: 'new create',
      perform: () => {
        createNewNoteTemplate()
      },
    },
  ])

  const onNoteTemplateGroupNameChange = (name) => {
    getDoc(`TEAM_DATA-TEAM:${teamId}`, { type: 'TEAM_DATA', teamId }, doc => {
      doc.getArray('NOTE_TEMPLATE_GROUPS').toArray().find(d => d.get('key') === noteTemplateGroupId)?.set('name', name)
    })
    yMeta.set('NOTE_TEMPLATE_GROUP_TITLE', name)
    updateNoteTemplateGroup(teamId, noteTemplateGroupId, { name })
  }
  const breads = useMemo(() => [{title: teamName, link: `/teams/${teamId}`}, {title: noteTemplateGroupName, onChange: onNoteTemplateGroupNameChange}], [teamName, noteTemplateGroupName])

  if (!editingNoteTemplate && yNoteTemplates.length > 0) {
    setEditingNoteTemplate(yNoteTemplates.get(0))
  }

  return (
    <TeamKBarXSetup>
      <KBarX>
        <Header teamId={teamId} breads={breads} />
        <Split className='flex-grow overflow-auto split' sizes={[14, 43, 43]} minSize={[100, 300, 300]} expandToMin={true}>
          <div className='flex flex-col h-full overflow-hidden' style={{ width: 175 }}>
            <div className='flex flex-row items-center p-1' style={{ backgroundColor: '#2d2d2d' }}>
              <Button
                minimal
                onClick={() => {
                  createNewNoteTemplate()
                  document.activeElement.blur()
                }}
              >
                <AiOutlinePlus className='text-white' />
              </Button>
              <Button
                minimal
                onClick={() => {
                  createNewNoteTemplateFolder()
                  document.activeElement.blur()
                }}
              >
                <FaFolderPlus className='text-white' />
              </Button>
            </div>
            <div className='flex-1 overflow-y-scroll'>
              <FolderList
                netityName={"Template"}
                activeEntityKey={editingNoteTemplate?.get('key')}
                folders={folders}
                folderOpened={yFolderOpened.state}
                entities={noteTemplates}
                onEntityMenu={(noteTemplate) => {
                  return (
                    <GenericErrorBoundary>
                      <ContextMenuItem
                        onClick={() => {
                          poster(
                            `/teams/${teamId}/noteTemplates/${noteTemplate.key}`,
                            { ...noteTemplate, updatedAt: Date.now() },
                            'PUT'
                          )
                            .then(async (res) => {
                              toast.success(`Success to sync noteTemplate to db!`)
                              refetchDbNoteTemplates()
                            })
                            .catch((err) => toast.error('Fail to sync noteTemplate to db!'))
                        }}
                      >
                        <div>
                          <div>Sync DB</div>
                          <div className='mt-1 text-xs text-gray-600'>
                            last: <TimeAgo date={dbNoteTemplates?.find((n) => n.key === noteTemplate.key)?.updatedAt} />
                          </div>
                        </div>
                      </ContextMenuItem>
                      <ContextMenuSeparator />
                        <ContextMenuItem
                          onClick={() => {
                            poster(`/teams/${teamId}/noteTemplates/${noteTemplate.key}`, {}, 'DELETE')
                            const editingKey = editingNoteTemplate.get('key')
                            yNoteTemplates.delete(originalKeys.indexOf(noteTemplate.key), 1)
                            if (editingKey === noteTemplate.key) {
                              setEditingNoteTemplate(null)
                            }
                          }}
                        >
                          <span className='text-red-800'>Delete</span>
                        </ContextMenuItem>
                    </GenericErrorBoundary>
                  )
                }}
                onEntityRender={(entity) => {
                  return (
                    <div className="flex flex-row items-center w-full">
                      <div className="flex-1 truncate">{entity.title}</div>
                    </div>
                  )
                }}
                onEntityTooltipRender={(entity, index) => {
                  return (
                    <div className="p-2 bg-white rounded" style={{ maxWidth: 260 }}>
                      {entity.title}
                    </div>
                  )
                }}
                onEntityClick={(entityKey) => {
                  const yNoteTEmplate = yNoteTemplates?.toArray().find((i) => i.get('key') === entityKey)
                  if (!yNoteTEmplate) return;
                  setEditingNoteTemplate(yNoteTEmplate)
                }}
                onEntityCreateInFolder={(folderKey) => {
                  createNewNoteTemplate(folderKey)
                  document.activeElement.blur()
                }}
                onFolderDelete={(folderKey) => {
                  const yFolderIndex = yFolders?.toArray().findIndex((i) => i.get('key') === folderKey)
                  if (yFolderIndex === -1) return;
                  yDoc.transact(() => {
                    for (const yNoteTEmplate of yNoteTemplates.toArray()) {
                      if (yNoteTEmplate.get('folder') === folderKey) {
                        yNoteTEmplate.set('folder', null)
                      }
                    }
                    yFolders.delete(yFolderIndex, 1)
                  })
                  deleteNoteTemplateGroupFolderOnServer(teamId, noteTemplateGroupId, folderKey)
                }}
                onFolderOpened={(folderKey, opened) => {
                  yFolderOpened.set(folderKey, opened)
                }}
                onFolderNameChange={(folderKey, name) => {
                  const yFolder = yFolders?.toArray().find((i) => i.get('key') === folderKey)
                  if (!yFolder) return;
                  yDoc.transact(() => {
                    yFolder.set('name', name)
                  })
                  updateNoteTemplateGroupFolderOnServer(teamId, noteTemplateGroupId, folderKey, { name })
                }}
                onFolderPosChange={(folderKey, toIndex) => {
                  const yFolder = yFolders.toArray().find((i) => i.get('key') === folderKey)
                  if (!yFolder) return;
                  const pos = moveMap(yFolders, yFolder, toIndex)
                  updateNoteTemplateGroupFolderPosOnServer(teamId, noteTemplateGroupId, folderKey, pos)
                  forceUpdate()
                }}
                onEntityFolderAndPosChange={(entityKey, folderKey, toIndex) => {
                  const yNoteTEmplate = yNoteTemplates?.toArray().find((i) => i.get('key') === entityKey)
                  if (!yNoteTEmplate) return;
                  if (!folderKey) {
                    yDoc.transact(() => {
                      yNoteTEmplate.set('folder', null)
                      const pos = moveMap(yNoteTemplates, yNoteTEmplate, toIndex, (i) => !i.get('folder'))
                      updateNoteTemplatePosOnServer(teamId, yNoteTEmplate.get('key'), pos)
                      updateNoteTemplateFolderOnServer(teamId, noteTemplateGroupId, yNoteTEmplate.get('key'), null)
                    })
                  } else {
                    yDoc.transact(() => {
                      yNoteTEmplate.set('folder', folderKey)
                      const pos = moveMap(yNoteTemplates, yNoteTEmplate, toIndex, (i) => i.get('folder') === folderKey)
                      updateNoteTemplatePosOnServer(teamId, yNoteTEmplate.get('key'), pos)
                      updateNoteTemplateFolderOnServer(teamId, noteTemplateGroupId, yNoteTEmplate.get('key'), folderKey)
                    })
                  }
                }}
              />
            </div>
          </div>
          <div className='overflow-hidden'>
            <GenericErrorBoundary>
              {editingNoteTemplate ? (
                <YEditor
                  path={`/noteTemplate/${editingNoteTemplate.get('key')}/content`}
                  yText={editingNoteTemplate.get('content')}
                  onChange={(content) => {
                    const title = contentToTitle(content)
                    yDoc.transact(() => {
                      editingNoteTemplate.set('title', title)
                      editingNoteTemplate.set('lastModifiedByUserId', userId)
                      editingNoteTemplate.set('updatedAt', Date.now())
                    })
                    updateNoteTemplate({ teamId, noteTemplateId: editingNoteTemplate.get('key'), content, title, updatedAt: Date.now() })
                  }}
                />
              ) : (
                <div className='flex items-center justify-center h-full'>
                  <p className='text-white opacity-50'>Command + K for Quick Actions</p>
                </div>
              )}
            </GenericErrorBoundary>
          </div>
          <div className='h-full overflow-auto'>
            <div className='flex justify-center '>
              <GenericErrorBoundary>{editingNoteTemplate ? <Note noteContent={editingNoteTemplate.toJSON().content} /> : null}</GenericErrorBoundary>
            </div>
          </div>
        </Split>
      </KBarX>
    </TeamKBarXSetup>
  )
}

export default function EditNoteTemplate() {
  const { teamId, noteTemplateGroupId } = useParams()
  const docName = `NOTE_TEMPLATE_GROUP_DATA-TEAM:${teamId}::GROUP:${noteTemplateGroupId}`
  const docMeta = {
    type: 'NOTE_TEMPLATE_GROUP_DATA',
    teamId,
    noteTemplateGroupId,
  }
  return (
    <DocumentProvider docName={docName} docMeta={docMeta}>
      <EditNoteTemplateMain teamId={teamId} noteTemplateGroupId={noteTemplateGroupId} />
    </DocumentProvider>
  )
}
