/* eslint-disable no-template-curly-in-string */

export function registerMonacoCompletionAlert(monaco) {
  return monaco.languages.registerCompletionItemProvider('markdown', {
    triggerCharacters: [':'],
    provideCompletionItems: (model, position) => {
      const range = {
        startLineNumber: position.lineNumber,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column
      }
      const text = model.getValueInRange(range).trim();

      const suggestions = [{
        label: ':::info',
        kind: monaco.languages.CompletionItemKind.Function,
        detail: 'alert block - info',
        documentation: 'HackNote extended markdown syntax, alert block, info',
        insertText: ':::info\n${1:text}\n:::\n',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
      }, {
        label: ':::success',
        kind: monaco.languages.CompletionItemKind.Function,
        detail: 'alert block - success',
        documentation: 'HackNote extended markdown syntax, alert block, success',
        insertText: ':::success\n${1:text}\n:::\n',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
      }, {
        label: ':::warning',
        kind: monaco.languages.CompletionItemKind.Function,
        detail: 'alert block - warning',
        documentation: 'HackNote extended markdown syntax, alert block, warning',
        insertText: ':::warning\n${1:text}\n:::\n',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
      }, {
        label: ':::danger',
        kind: monaco.languages.CompletionItemKind.Function,
        detail: 'alert block - danger',
        documentation: 'HackNote extended markdown syntax, alert block, danger',
        insertText: ':::danger\n${1:text}\n:::\n',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
      }]

      const prefixes = [
        ":::",
        ":::info",
        ":::success",
        ":::warning",
        ":::danger",
      ]
      const matchedPrefixIndex = prefixes.findIndex((prefix) => prefix.startsWith(text.toLowerCase()))

      if (matchedPrefixIndex === -1) return { suggestions: [] }
      if (matchedPrefixIndex === 0) return { suggestions }
      return {
        suggestions: [suggestions[matchedPrefixIndex-1]]
      }
    },
  });
}